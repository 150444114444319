<div class="title">
    <span>{{'main.reminder.title' | translate}}</span>
</div>
<div class="content">
    <p>{{'main.reminder.description' | translate}}</p>
    <p>{{'main.reminder.description-2' | translate}}</p>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit($event)">
        <mat-form-field>
            <mat-label>{{'create.reminder.email' | translate}}*</mat-label>
            <input matInput [placeholder]="'create.reminder.email' | translate"
                   formControlName="email"
                   autocomplete="off">
        </mat-form-field>
    </form>
</div>
<div class="actions">
    <button mat-raised-button color="accent" type="submit" (click)="onSubmit($event)" [attr.aria-label]="'main.reminder.remind-me' | translate">
        <span>{{'main.reminder.remind-me' | translate}}</span>
    </button>
    <button mat-button (click)="close($event)" [attr.aria-label]="'common.close' | translate">
        <span>{{'common.close' | translate}}</span>
    </button>
</div>
