import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {GASettings} from '../../../models/ga-settings.model';
import {TranslateService} from '@ngx-translate/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

export interface FAQItem {
    id: number;
    response: string;
    question: string;
}

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    faqList: FAQItem[];
    languageSubscription: Subscription;
    searchTerm: string;

    @Input()
    createMode: boolean;

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.setFAQ();
        this.languageSubscription = this.translate.onLangChange.subscribe(() => {
            this.setFAQ();
        });
    }

    private setFAQ() {
        this.faqList = [];
        for (let i = 1; i <= 11; i++) {
            this.faqList.push({
                id: i,
                question: this.translate.instant('legal.faq.list.question-' + i),
                response: this.translate.instant('legal.faq.list.response-' + i)
            });
        }
    }
}
