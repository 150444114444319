<div class="process-overview">

    <img [src]="image" alt="Process when ordering on Kid of the World"/>

    <div class="actions">
        <button mat-raised-button (click)="close()"
                [attr.aria-label]="'common.ok' | translate">{{'common.ok' | translate}}</button>
    </div>

</div>
