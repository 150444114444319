import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {first, switchMap, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactService} from '../../services/contact.service';
import {TranslateService} from '@ngx-translate/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import * as moment from 'moment';
import {GASettings} from '../../models/ga-settings.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, OnDestroy {

    @ViewChild('myForm') myForm: NgForm;

    unsubscribeForm: FormGroup;
    unsubscribeFormSubmitted = false;
    unsubscribeSent = false;
    newsletterStatus: string;

    private routeSubscription: Subscription;
    private newsletterSubscription: Subscription;

    constructor(private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private contactService: ContactService,
                private translate: TranslateService,
                private gaService: GoogleAnalyticsService,
                private router: Router,
                private recaptchaV3Service: ReCaptchaV3Service,) {
        this.unsubscribeForm = new FormGroup({
            email: new FormControl('email',
                [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        });

        this.unsubscribeForm.reset();
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.queryParams
            .pipe(
                tap((params) => {
                    this.newsletterStatus = params.status;
                }),
            ).subscribe();
    }

    onSubmit(event) {
        event.preventDefault();
        this.unsubscribeFormSubmitted = true;
        this.unsubscribeSent = false;

        if (this.unsubscribeForm.valid && (this.unsubscribeForm.touched || this.unsubscribeForm.dirty)) {
            this.newsletterSubscription?.unsubscribe();
            this.newsletterSubscription = this.recaptchaV3Service.execute('unSubscribeNewsletter')
                .pipe(
                    first(),
                    switchMap(token => this.contactService.unSubscribeNewsletter(this.unsubscribeForm.get('email').value, 'test', token))
                ).subscribe((result: any) => {
                    this.unsubscribeFormSubmitted = false;
                    if (result.status === 'OK') {
                        this.initForm();
                        this.snackBar.open(this.translate.instant('newsletter.unsubscribed-ok'),
                            this.translate.instant('common.close'), {
                                duration: 10000, horizontalPosition: 'center',
                                verticalPosition: 'top'
                            });
                        this.unsubscribeSent = true;
                    } else {
                        this.snackBar.open(this.translate.instant('main.contact.unable-send-message'),
                            this.translate.instant('common.close'), {
                                duration: 10000, horizontalPosition: 'center',
                                verticalPosition: 'top'
                            });
                        this.unsubscribeSent = false;
                    }
                });
        } else {
            this.unsubscribeFormSubmitted = false;
            this.unsubscribeSent = false;
            this.snackBar.open(this.translate.instant('main.contact.form-invalid'),
                this.translate.instant('common.close'), {
                    duration: 10000, horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
        }
    }

    initForm() {
        this.unsubscribeForm.reset();
        this.myForm.resetForm();
    }

    ngOnDestroy() {
        this.routeSubscription?.unsubscribe();
        this.newsletterSubscription?.unsubscribe();
    }

    public goToCreate() {
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'create_from_newsletter');
        this.router.navigate(['/create']);
    }

}
