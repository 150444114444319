import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslationValidationService} from '../../services/translation-validation.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {first, switchMap, tap} from 'rxjs/operators';
import {ReviewTranslationValidation} from '../../models/translation-validation.model';
import {GASettings} from '../../models/ga-settings.model';
import * as cloneDeep from 'lodash/cloneDeep';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-translate-validation',
    templateUrl: './translate-validation.component.html',
    styleUrls: ['./translate-validation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('appear', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
            ])
        ]),
    ]
})
export class TranslateValidationComponent implements OnInit, OnDestroy {

    @ViewChild('myForm') myForm: NgForm;

    timeOnComponent: moment.Moment;
    private subscriptionReceive: Subscription;
    private subscriptionSend: Subscription;
    private translateValidationSubscription: Subscription;
    targetLang: string;
    sourceLang: string;
    themes: string[];
    reviewTranslationsValidation: ReviewTranslationValidation;
    originalTranslations: ReviewTranslationValidation;
    validationForm: FormGroup;
    validationSent = false;
    loadingImages = false;
    loadingForm = false;
    currentLanguage: string;
    languagesFound = true;
    myCorrections: string;

    constructor(private route: ActivatedRoute,
                private translationValidationService: TranslationValidationService,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private gaService: GoogleAnalyticsService,) {
        this.currentLanguage = this.translate.currentLang;
        this.setValidationForm();
    }

    setValidationForm() {
        this.validationForm = new FormGroup({
            name: new FormControl('name', [Validators.required]),
            nativeName: new FormControl('nativeName', [Validators.required]),
            message: new FormControl('message'),
            anecdote: new FormControl('anecdote'),
            thankYou: new FormControl('thankYou')
        });
        this.validationForm.reset();
    }

    ngOnInit(): void {
        this.loadingImages = true;
        this.timeOnComponent = moment();
        this.subscriptionReceive = this.route.queryParams
            .pipe(
                tap((params) => {
                    this.targetLang = params.target;
                    this.sourceLang = params.source;
                    this.themes = params.themes.split(',');
                }),
                switchMap(() => {
                    return this.translationValidationService.getTranslations(this.themes, this.sourceLang, this.targetLang);
                })
            )
            .subscribe((reviewTranslationValidation: ReviewTranslationValidation[]) => {
                console.log('reviewTranslationValidation 1', reviewTranslationValidation);
                this.loadingImages = false;
                this.reviewTranslationsValidation = reviewTranslationValidation[0];
                if (!this.reviewTranslationsValidation?.translations?.length) {
                    this.languagesFound = false;
                } else {
                    console.log('reviewTranslationValidation 2', reviewTranslationValidation);
                    this.originalTranslations = cloneDeep(this.reviewTranslationsValidation);
                }
            });
    }

    private isNotEmpty(str: string) {
        return !!str && str.trim().length > 0;
    }

    onSubmit(event) {
        event.preventDefault();

        const fixed = {
            theme: this.reviewTranslationsValidation.theme,
            source: this.reviewTranslationsValidation.source,
            target: this.reviewTranslationsValidation.target,
            translations: []
        } as ReviewTranslationValidation;

        for (const translation of this.reviewTranslationsValidation.translations) {
            const foundLang = this.originalTranslations.translations.find(tr => translation.key === tr.key);
            if (foundLang.target !== translation.target) {
                fixed.translations.push({
                    key: translation.key,
                    source: translation.source,
                    target: translation.target,
                    locale: translation.locale
                });
            }
        }

        this.myCorrections = JSON.stringify(fixed);
        this.validationSent = false;
        this.loadingForm = true;

        if (this.validationForm.valid && (this.validationForm.touched || this.validationForm.dirty)) {

            const messages = ['Native name: ' + this.validationForm.get('nativeName').value,
                'Anecdote: ' + this.validationForm.get('anecdote').value,
                'Thank you: ' + this.validationForm.get('thankYou').value,
                'Additional comment:' + this.validationForm.get('message').value];

            this.translateValidationSubscription?.unsubscribe();
            this.translateValidationSubscription = this.translationValidationService
                .confirmTranslations(this.validationForm.get('name').value, messages, fixed)
                .subscribe(((result: any) => {
                    if (result?.status === 'OK') {
                        this.validationSent = true;
                        this.loadingForm = false;
                        this.validationForm.reset();
                        this.myForm.resetForm();
                        this.gaService.event(GASettings.ACTIONS.VALIDATE_TRANSLATION,
                            GASettings.CATEGORIES.ENGAGEMENT, 'validate_translation');
                    } else {
                        this.validationSent = true;
                        this.loadingForm = false;
                        this.validationForm.reset();
                        this.myForm.resetForm();
                        this.snackBar.open(this.translate.instant('translate.errors.error'),
                            this.translate.instant('common.close'), {
                                duration: 10000, horizontalPosition: 'center',
                                verticalPosition: 'top'
                            });
                    }
                }));
        } else {
            this.validationSent = false;
            this.loadingForm = false;
            this.snackBar.open(this.translate.instant('main.contact.form-invalid'),
                this.translate.instant('common.close'), {
                    duration: 10000, horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
        }
    }

    ngOnDestroy()
        :
        void {
        this.subscriptionReceive?.unsubscribe();
        this.subscriptionSend?.unsubscribe();
        this.translateValidationSubscription?.unsubscribe();
    }
}
