import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionDataServiceDirective} from '../../../services/session-data-service.directive';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogResult} from '../../../services/dialog.result';
import {TranslateService} from '@ngx-translate/core';
import {Country} from '../../../models/country.model';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {take, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-shipping-selector',
    templateUrl: './shipping-selector.component.html',
    styleUrls: ['./shipping-selector.component.scss']
})
export class ShippingSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
    
    public allCountries: Country[];
    public countryCtrl: FormControl = new FormControl();
    public countryFilterCtrl: FormControl = new FormControl();
    public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
    protected _onDestroy = new Subject<void>();
    
    @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;
    
    constructor(public dialogRef: MatDialogRef<ShippingSelectorComponent>,
                private session: SessionDataServiceDirective,) {
    }
    
    ngOnInit() {
        this.allCountries = this.session.getAllCountries();
        if (this.session.getShippingCountry()) {
            this.countryCtrl.setValue(this.session.getShippingCountry());
        }
        if (this.allCountries?.length) {
            this.filteredCountries.next(this.allCountries.slice());
            this.countryFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterCountries();
                });
        }
    }
    
    ngAfterViewInit() {
        this.setInitialValue();
    }
    
    protected setInitialValue() {
        this.filteredCountries
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredCountries are loaded initially
                // and after the mat-option elements are available
                this.singleSelect.compareWith = (a: Country, b: Country) => a && b && a.code === b.code;
            });
    }
    
    protected filterCountries() {
        if (!this.allCountries) {
            return;
        }
        // get the search keyword
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.allCountries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredCountries.next(
            this.allCountries.filter(country => country.name.toLowerCase().indexOf(search) > -1)
        );
    }
    
    
    save() {
        const selectedCountry = this.countryCtrl.value;
        if (!!selectedCountry && selectedCountry.code && selectedCountry.name) {
            this.session.setShippingCountry(selectedCountry);
            this.dialogRef.close(DialogResult.YES);
        }
    }
    
    cancel() {
        this.session.setShippingCountry(null);
        this.dialogRef.close(DialogResult.NO);
    }
    
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
