<div class="footer">
    <p>{{'footer.copyright' | translate: {value: year} }}</p>
    <p class="legal">
        <a class="link" [routerLink]="['/terms-and-conditions']">{{'common.terms-and-conditions' | translate}}</a>
        <a class="link" [routerLink]="['/privacy-policy']">{{'common.privacy-policy' | translate}}</a>
        <a class="link" [routerLink]="['/refund-policy']">{{'common.refund-policy' | translate}}</a>
    </p>
    <div class="cards">
        <img src="../../../../assets/icons/mastercard-logo.png"/>
        <img src="../../../../assets/icons/visa-logo.png"/>
    </div>
    <div *ngIf="fakeCards" class="test-cards"><span *ngFor="let card of fakeCards">{{card}}</span></div>
</div>
