<div class="greetings-editor">

    <div class="title">
        <mat-icon>create</mat-icon>
        <div>{{'order.order-details.greetings.title' | translate}}</div>
    </div>
    <div class="subtitle">{{'order.order-details.greetings.subtitle' | translate}}</div>

    <mat-form-field>
        <textarea matInput
                  cols="10"
                  [(ngModel)]="greetings"
                  [style.fontSize]="fontSize"
                  rows="5"
                  (input)="limitLines($event)"
                  [placeholder]="'order.order-details.greetings.placeholder' | translate"
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="5"></textarea>
        <mat-hint>{{'order.order-details.greetings.hint' | translate}}</mat-hint>
    </mat-form-field>

    <div class="actions">
        <button mat-raised-button color="accent" (click)="ok()" [attr.aria-label]="'common.ok' | translate">{{'common.ok' | translate}}</button>
        <button mat-button (click)="clean()" [attr.aria-label]="'common.clear' | translate">{{'common.clear' | translate}}</button>
        <button mat-button (click)="ok()" [attr.aria-label]="'common.close' | translate">{{'common.close' | translate}}</button>
    </div>

</div>
