import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LanguageHelper} from '../../../helpers/language.helper';

export interface WrapPreviewDialogParams {
    locales: string[];
}

export interface LocaleRowConfig {
    name: string;
    color: string;
    paddingLeft: string;
}

export interface LocaleConfig {
    rows: LocaleRowConfig[];
    marginLeft: string;
}

@Component({
    selector: 'app-wrap-preview',
    templateUrl: './wrap-preview.component.html',
    styleUrls: ['./wrap-preview.component.scss']
})
export class WrapPreviewComponent implements OnInit {

    locales: string[];
    previewLines: LocaleConfig[];

    constructor(@Inject(MAT_DIALOG_DATA) public params: WrapPreviewDialogParams,
                public dialogRef: MatDialogRef<WrapPreviewComponent>) {
    }

    ngOnInit(): void {
        this.locales = this.params.locales.map(l => LanguageHelper.getNativeName(l));
        const lengthLocales = this.locales.length;
        const previewLines = [];

        for (let i = 0; i < 10; i++) {
            const previewColumns: LocaleRowConfig[] = [];
            for (let j = 0; j < 10; j++) {
                previewColumns.push({
                    name: this.locales[Math.floor(Math.random() * lengthLocales)],
                    color: 'color-' + (Math.floor(Math.random() * 5)  + 1),
                    paddingLeft: (Math.floor(Math.random() * 10)  + 20) + 'px'
                });
            }
            previewLines.push({
                rows: previewColumns,
                marginLeft: (Math.floor(Math.random() * 60) * -1 + 10) + 'px'
            });
        }

        this.previewLines = previewLines;
    }

    ok() {
        this.dialogRef.close(true);
    }

    no() {
        this.dialogRef.close(false);
    }

}
