import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderConfirmation, SessionDataServiceDirective} from '../../../services/session-data-service.directive';
import {Router} from '@angular/router';
import {Shipping} from '../../../models/shipping.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GASettings} from '../../../models/ga-settings.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ItemOrder} from '../../../models/item-order.model';
import {PixelService} from 'ngx-pixel';
import {Order} from '../../../models/order.model';
import {ContentOrder} from '../../../models/content-order.model';
import {DetailedOrderCalculationModel} from '../../../models/detailed-order-calculation.model';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    animations: [
        trigger('appear', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
            ])
        ]),
    ]
})
export class ConfirmationComponent implements OnInit, OnDestroy {

    translateSubscription: Subscription;
    orderConfirmation: OrderConfirmation;
    shippingAddress: Shipping;
    shareSentence: string;
    book: ItemOrder;
    hearAboutUsGiven: boolean;

    constructor(private sessionDataService: SessionDataServiceDirective,
                private router: Router,
                private gaService: GoogleAnalyticsService,
                private pixel: PixelService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.setShareSentence();

        this.orderConfirmation = this.sessionDataService.orderConfirmation;
        if (!this.orderConfirmation?.order  && environment.production) {
            this.home();
        }

        this.sessionDataService.currentOder = {
            content: {
                items: [],
                coupon: null,
                isGift: false,
                greetings: ''
            } as ContentOrder,
            shipping: {
                contactAddress: {
                    country: null
                }
            }
        } as Order;
        this.sessionDataService.itemsChanged$.next();
        this.sessionDataService.priceChanged$.next({
            totalOrderPrice: {
                total: 0,
                totalWithoutPromotion: 0,
            }
        } as DetailedOrderCalculationModel);

        this.translateSubscription = this.translate.onLangChange
            .subscribe(() => {
                this.setShareSentence();
            });
    }

    setShareSentence() {
        const rand = Math.floor(Math.random() * 3);
        this.shareSentence = this.translate.instant('confirmation.share-content.share-' + rand);
    }

    share() {
        this.gaService.event(GASettings.ACTIONS.SHARE, GASettings.CATEGORIES.ENGAGEMENT, this.shareSentence);
    }

    home() {
        this.router.navigate(['/']);
    }

    setHearAboutUs(value: string) {
        this.hearAboutUsGiven = true;
        this.gaService.event(GASettings.ACTIONS.FEEDBACK, GASettings.CATEGORIES.FEEDBACK, 'acquisition-' + value);
    }

    ngOnDestroy(): void {
        this.translateSubscription?.unsubscribe();
    }
}
