import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CreateComponent} from './components/create/create.component';
import {OrderComponent} from './components/order/order.component';
import {MainComponent} from './components/main/main.component';
import {TranslateValidationComponent} from './components/translate-validation/translate-validation.component';
import {NewsletterComponent} from './components/newsletter/newsletter.component';

const routes: Routes = [
    {path: '', component: MainComponent},
    {path: 'create', component: CreateComponent},
    {path: 'order', component: OrderComponent},
    {path: 'newsletter', component: NewsletterComponent},
    {path: 'translate', component: TranslateValidationComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
