import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Router} from '@angular/router';
import {GASettings} from '../../../models/ga-settings.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {PixelService} from 'ngx-pixel';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {

    timeOnComponent: moment.Moment;

    constructor(private router: Router,
                private pixel: PixelService,
                private gaService: GoogleAnalyticsService) {
    }

    ngOnInit() {
        this.timeOnComponent = moment();
    }

    public goToCreate() {
        const period = moment().diff(this.timeOnComponent, 'seconds');
        this.pixel.track('ViewContent');
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'create_from_welcome', period);
        this.router.navigate(['/create']);
    }
}
