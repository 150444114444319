<div class="terms-and-conditions">

    <!-- TODO component that randomize clouds on the page -->
    <div class="city">
        <img id="city" src="../../../../assets/images/city.png"/>
    </div>
    <div class="cloud4">
        <img id="cloud-1" src="../../../../assets/images/cloud4.png"/>
    </div>
    <div class="cloud6">
        <img id="cloud-2" src="../../../../assets/images/cloud6.png"/>
    </div>
    <div class="cloud5">
        <img id="cloud-4" src="../../../../assets/images/cloud5.png"/>
    </div>

    <div class="terms-and-conditions-wrapper" [@appear]>
        <div class="title">{{'common.terms-and-conditions' | translate}}</div>
        <div class="content">
            <app-tos-shared></app-tos-shared>
        </div>
    </div>
</div>
