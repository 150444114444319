<div class="book-slider" (click)="goToCreate()">
    <div class="book-top"></div><!-- here appears the logo -->
    <div class="book-bottom">
        <div class="bear"></div><!-- here appears the bear -->
        <div class="text">
            <div class="languages" *ngFor="let lang of languages" [@fadeInOut]>
                {{lang}}
            </div>
        </div>
    </div>
</div>

