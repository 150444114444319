import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-tos',
    templateUrl: './tos-dialog.component.html',
    styleUrls: ['./tos-dialog.component.scss']
})
export class TosDialogComponent implements OnInit {
    
    constructor(public dialogRef: MatDialogRef<TosDialogComponent>) {
    }
    
    ngOnInit() {
    }
    
    close() {
        this.dialogRef.close(false);
    }
    
    agree() {
        this.dialogRef.close(true);
    }
}
