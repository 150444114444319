<div class="newsletter">

    <!-- TODO component that randomize clouds on the page -->
    <div class="city">
        <img id="city" src="../../../../assets/images/city.png"/>
    </div>
    <div class="cloud4">
        <img id="cloud-1" src="../../../../assets/images/cloud4.png"/>
    </div>
    <div class="cloud6">
        <img id="cloud-2" src="../../../../assets/images/cloud6.png"/>
    </div>
    <div class="cloud5">
        <img id="cloud-4" src="../../../../assets/images/cloud5.png"/>
    </div>

    <div class="newsletter-wrapper" *ngIf="newsletterStatus === 'unsubscribe'">
        <div class="title">{{'newsletter.unsubscribe' | translate}}</div>
        <div class="subtitle">{{'newsletter.unsubscribe-hint' | translate}}</div>
        <div class="content" >
            <form [formGroup]="unsubscribeForm" (ngSubmit)="onSubmit($event)" #myForm="ngForm">
                <mat-form-field>
                    <mat-label>{{'common.email' | translate}}*</mat-label>
                    <input matInput [placeholder]="'common.email' | translate" type="email" formControlName="email">
                </mat-form-field>
                <div class="actions">
                    <button *ngIf="!unsubscribeFormSubmitted" mat-raised-button color="primary" type="submit"
                            [attr.aria-label]="'common.send' | translate">
                        <span>{{'common.send' | translate}}</span>
                    </button>
                </div>
                <mat-spinner *ngIf="unsubscribeFormSubmitted"></mat-spinner>
            </form>
            <div class="content-hint" *ngIf="unsubscribeSent">{{'newsletter.unsubscribed-ok' | translate}}</div>
        </div>
    </div>

    <div class="newsletter-wrapper" *ngIf="newsletterStatus === 'not-subscribed'">
        <div class="title">{{'newsletter.not-subscribed' | translate}}</div>
        <div class="subtitle">{{'newsletter.not-subscribed-hint' | translate}}</div>
    </div>

    <div class="newsletter-wrapper" *ngIf="newsletterStatus === 'subscribed'">
        <div class="title">{{'newsletter.subscribed' | translate}}</div>
        <div class="subtitle">{{'newsletter.subscribed-hint' | translate}}</div>
        <div class="actions">
            <button mat-raised-button color="accent" (click)="goToCreate()" [attr.aria-label]="'common.create-book' | translate">
                {{'common.create-book' | translate}}
            </button>
        </div>
    </div>
</div>
