<div class="promotion" *ngIf="hasCoupon && isRouteForCoupon">
    <span>{{couponMessage}}</span>
    <span class="coupon">{{couponCode}}</span>
</div>
<div class="header" [class.fixed]="navFixed">
    <div class="menu">
        <button mat-icon-button [matMenuTriggerFor]="mainMenu" [attr.aria-label]="'common.home' | translate">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #mainMenu="matMenu">
            <a [routerLink]="['/']" mat-menu-item>
                <mat-icon>home</mat-icon>
                {{'common.home' | translate}}</a>
            <a (click)="goToCreate()" mat-menu-item>
                <mat-icon>edit</mat-icon>
                {{'common.create-book' | translate}}</a>
            <a [routerLink]="['/about-us']" mat-menu-item>
                <mat-icon>menu_book</mat-icon>
                {{'common.about-us' | translate}}</a>
            <a [routerLink]="['/faq']" mat-menu-item>
                <mat-icon>help</mat-icon>
                {{'legal.faq.title' | translate}}</a>
            <a mat-menu-item href="https://www.facebook.com/kidoftheworldbooks" target="_blank">
                <mat-icon>facebook</mat-icon>
                Facebook
            </a>
            <a mat-menu-item href="https://www.instagram.com/kidoftheworldbooks" target="_blank">
                <mat-icon>local_see</mat-icon>
                Instagram
            </a>
            <a (click)="openContactForm()" mat-menu-item>
                <mat-icon>email</mat-icon>
                {{'common.contact-us' | translate}}</a>
        </mat-menu>
    </div>
    <button class="visible-lg" mat-button [routerLink]="['/']" *ngIf="isRouteForHomeButton" [attr.aria-label]="'common.home' | translate">
        <span>{{'common.home' | translate}}</span>
    </button>
    <button mat-button [matMenuTriggerFor]="langMenu" [attr.aria-label]="'languages.' + currentLang | translate">
        <mat-icon class="visible-sm">language</mat-icon>
        <span class="visible-lg">{{'languages.' + currentLang | translate}}</span>
    </button>
    <button class="visible-lg" mat-button [routerLink]="['/faq']" [attr.aria-label]="'legal.faq.title' | translate">
        <span>{{'legal.faq.title' | translate}}</span>
    </button>
    <mat-menu #langMenu="matMenu">
        <button mat-menu-item *ngFor="let lang of websiteSupportedLanguages | keyvalue"
                [attr.aria-label]="'languages.' + lang.key | translate"
                (click)="changeLang(lang.key)">
            <span>{{lang.value}}</span>
        </button>
    </mat-menu>
    <button mat-button [matMenuTriggerFor]="currencyMenu" [attr.aria-label]="refCurrency">
        <span>{{allCurrencySymbols.get(refCurrency)}} {{refCurrency}}</span>
    </button>
    <mat-menu #currencyMenu="matMenu">
        <button mat-menu-item *ngFor="let curr of settings?.supportedCurrencyList" (click)="setCurrency(curr)" [attr.aria-label]="curr">
            {{allCurrencySymbols.get(curr)}} {{curr}}
        </button>
    </mat-menu>
    <div class="separator"></div>
    <div class="basket-button" *ngIf="isRouteForBasketButton" (click)="showBasket()">
        <div class="basket-button-price" *ngIf="totalPrice?.price !== ''">
            <span class="basket-button-price-amount">
                <span class="basket-button-price-number">{{totalPrice.price}}</span>
                <span class="basket-button-price-cents">.{{totalPrice.cents}}</span>
                <span class="basket-button-price-currency">{{refCurrency}}</span>
            </span>
            <span class="basket-shipping" *ngIf="showShippingMissing"><mat-icon [inline]="true">add</mat-icon><mat-icon [inline]="true">local_shipping</mat-icon></span>
        </div>
        <button mat-mini-fab color="accent" [attr.aria-label]="'create.show-basket' | translate"
                [matTooltip]="'create.show-basket' | translate">
            <mat-icon>shopping_cart</mat-icon>
        </button>
        <div class="hint">{{nbSavedItems}}</div>
    </div>
</div>
