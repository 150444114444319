<div class="wrap-preview">
    <div class="title">
        <mat-icon>home</mat-icon>
        <div>{{'order.order-details.wrap.wrap-title' | translate}}</div>
    </div>

    <div class="block-title">{{'order.order-details.wrap.wrap-subtitle' | translate}}</div>

    <div class="preview">
        <div class="preview-row" *ngFor="let row of previewLines">
            <div class="preview-column" [ngStyle]="{'margin-left': row.marginLeft}">
                <div *ngFor="let col of row.rows" [ngClass]="col.color" [ngStyle]="{'margin-left': col.paddingLeft}">{{col.name}}</div>
            </div>
        </div>
    </div>

    <div class="actions">
        <button mat-raised-button color="accent" (click)="ok()" [attr.aria-label]="'common.ok' | translate">{{'common.ok' | translate}}</button>
    </div>
</div>
