export const environment = {
    production: true,
    apiUrl: 'https://kotw-backend-dot-kidoftheword.uc.r.appspot.com/rest',
    domain: 'kidoftheworld.com',
    stripe: 'pk_live_l6kGmEDCCT0gkaD8IKMinhir',
    fakeCards: null,
    ga: 'G-LZYX9Q1MWQ',
    recaptcha: '6LfT0ZMaAAAAADwlFBJoBfP2qT9ZYT_GuIUrZxEj',
    features: {
        SHOW_COUPON: true
    }
};
