import {NgModule} from '@angular/core';
import {BaseModule} from '../../base.module';
import {RouterModule} from '@angular/router';
import {NewsletterComponent} from './newsletter.component';

@NgModule({
    declarations: [
        NewsletterComponent
    ],
    imports: [
        BaseModule,
        RouterModule.forChild([]),
    ],
    providers: []
})


export class NewsletterModule {
}
