<div class="privacy-policy">

    <!-- TODO component that randomize clouds on the page -->
    <div class="city">
        <img id="city" src="../../../../assets/images/city.png"/>
    </div>
    <div class="cloud4">
        <img id="cloud-1" src="../../../../assets/images/cloud4.png"/>
    </div>
    <div class="cloud6">
        <img id="cloud-2" src="../../../../assets/images/cloud6.png"/>
    </div>
    <div class="cloud5">
        <img id="cloud-4" src="../../../../assets/images/cloud5.png"/>
    </div>

    <div class="privacy-policy-wrapper" [@appear]>
        <div class="title">{{'common.privacy-policy' | translate}}</div>
        <div class="content">
            <div class="warning" *ngIf="!supportedLanguages.includes(currentLang)">
                {{'legal.privacy-policy-english-only' | translate}}
            </div>
            <div class="block" *ngIf="currentLang !== 'fr'">
                <p>
                    This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from
                    https://www.kidoftheworld.com (the “Site”).
                </p>
                <div class="heading">PERSONAL INFORMATION WE COLLECT</div>
                <p>
                    When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP
                    address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information
                    about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how
                    you interact with the Site. We refer to this automatically-collected information as “Device Information.”
                </p>
                <p>
                    We collect Device Information using the following technologies:
                    <br>
                    - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information
                    about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                    - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider,
                    referring/exit pages, and date/time stamps.
                    - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
                </p>
                <p>
                    Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including
                    your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number. We refer
                    to this information as “Order Information.”
                </p>
                <p>
                    When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
                </p>
                <div class="heading">HOW DO WE USE YOUR PERSONAL INFORMATION?</div>
                <p>
                    We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment
                    information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order
                    Information to:<br>
                    Communicate with you;
                    <br>Screen our orders for potential risk or fraud; and
                    <br>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
                    <br>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more
                    generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site,
                    and to assess the success of our marketing and advertising campaigns).
                </p>
                <div class="heading">SHARING YOUR PERSONAL INFORMATION</div>
                <p>
                    We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use
                    Stripe to validate and proceed to the payment once your order: https://stripe.com/en-ch/privacy. We also use Google Analytics to help us
                    understand how our customers use the Site--you can read more about how Google uses your Personal Information here:
                    https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
                </p>
                <p>
                    Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search
                    warrant or other lawful request for information we receive, or to otherwise protect our rights.
                </p>
                <div class="heading">BEHAVIOURAL ADVERTISING</div>
                <p>
                    As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may
                    be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”)
                    educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                </p>
                <p>
                    You can opt out of targeted advertising by visiting the web pages hereafter:<br>
                    FACEBOOK - https://www.facebook.com/settings/?tab=ads<br>
                    GOOGLE - https://www.google.com/settings/ads/anonymous<br>
                    BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads<br>
                </p>
                <p>
                    Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:<br>
                    http://optout.aboutads.info/.
                </p>

                <div class="heading">DO NOT TRACK</div>
                <p>
                    Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
                </p>
                <div class="heading">YOUR RIGHTS</div>
                <p>
                    If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal
                    information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information
                    below.
                </p>
                <p>
                    Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have
                    with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.
                    Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
                </p>
                <div class="heading">DATA RETENTION</div>
                <p>
                    When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this
                    information.
                </p>
                <div class="heading">MINORS</div>
                <p>
                    The Site is not intended for individuals under the age of 18.
                </p>
                <div class="heading">CHANGES</div>
                <p>
                    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational,
                    legal or regulatory reasons.
                </p>
                <div class="heading">CONTACT US</div>
                <p>
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by
                    e-mail at hello@kidoftheworld.com or by mail using the details provided below:
                </p>
                <p>
                    Kid of the World
                    Chemin du Moulin-de-Vert, 10, Aire-la-Ville, GE, 1288, Switzerland
                </p>
            </div>
            <div class="block" *ngIf="currentLang === 'fr'">
                <div class="heading">RENSEIGNEMENTS PERSONNELS RECUEILLIS</div>
                <p>
                    Lorsque vous effectuez un achat sur notre boutique, dans le cadre de notre processus d’achat et de vente, nous recueillons les
                    renseignements personnels que vous nous fournissez, tels que votre nom, votre adresse et votre adresse e-mail.
                </p>
                <p>
                    Lorsque vous naviguez sur notre boutique, nous recevons également automatiquement l’adresse de protocole Internet (adresse IP) de votre
                    ordinateur, qui nous permet d’obtenir plus de détails au sujet du navigateur et du système d’exploitation que vous utilisez.
                </p>
                <p>
                    Marketing par e-mail (le cas échéant): Avec votre permission, nous pourrions vous envoyer des e-mails au sujet de notre boutique, de
                    nouveaux produits et d’autres mises à jour.
                </p>
                <div class="heading">CONSENTEMENT</div>
                <p>
                    Comment obtenez-vous mon consentement?
                </p>
                <p>
                    Lorsque vous nous fournissez vos renseignements personnels pour conclure une transaction, vérifier votre carte de crédit, passer une
                    commande, planifier une livraison ou retourner un achat, nous présumons que vous consentez à ce que nous recueillions vos renseignements et
                    à ce que nous les utilisions à cette fin uniquement.
                </p>
                <p>
                    Si nous vous demandons de nous fournir vos renseignements personnels pour une autre raison, à des fins de marketing par exemple, nous vous
                    demanderons directement votre consentement explicite, ou nous vous donnerons la possibilité de refuser.
                </p>
                <p>
                    Comment puis-je retirer mon consentement?
                </p>
                <p>
                    Si après nous avoir donné votre consentement, vous changez d’avis et ne consentez plus à ce que nous puissions vous contacter, recueillir
                    vos renseignements ou les divulguer, vous pouvez nous en aviser en nous contactant à hello@kidoftheworld.com ou par courrier à: Kid of the
                    World, Chemin du Moulin-de-Vert, 10, Aire-la-Ville, GE, 1288, Switzerland
                </p>
                <div class="heading">DIVULGATION</div>
                <p>
                    Nous pouvons divulguer vos renseignements personnels si la loi nous oblige à le faire ou si vous violez nos Conditions Générales de Vente et
                    d’Utilisation.
                </p>
                <div class="heading">STRIPE</div>
                <p>
                    Notre boutique utilise le système de paiement offert par l'entreprise Stripe.
                </p>
                <p>
                    Vos données sont stockées dans le système de stockage de données et les bases de données de Stripe. Vos données sont conservées sur un
                    serveur sécurisé protégé par un pare-feu.
                </p>
                <div class="heading">SERVICES FOURNIS PAR DES TIERS</div>
                <p>
                    De manière générale, les fournisseurs tiers que nous utilisons vont uniquement recueillir, utiliser et divulguer vos renseignements dans la
                    mesure du nécessaire pour pouvoir réaliser les services qu’ils nous fournissent.
                </p>
                <p>
                    Cependant, certains tiers fournisseurs de services, comme les passerelles de paiement et autres processeurs de transactions de paiement,
                    possèdent leurs propres politiques de confidentialité quant aux renseignements que nous sommes tenus de leur fournir pour vos transactions
                    d’achat.
                </p>
                <p>
                    En ce qui concerne ces fournisseurs, nous vous recommandons de lire attentivement leurs politiques de confidentialité pour que vous puissiez
                    comprendre la manière dont ils traiteront vos renseignements personnels.
                </p>
                <p>
                    Il ne faut pas oublier que certains fournisseurs peuvent être situés ou avoir des installations situées dans une juridiction différente de
                    la vôtre ou de la nôtre. Donc si vous décidez de poursuivre une transaction qui requiert les services d’un fournisseur tiers, vos
                    renseignements pourraient alors être régis par les lois de la juridiction dans laquelle ce fournisseur se situe ou celles de la juridiction
                    dans laquelle ses installations sont situées.
                </p>
                <p>
                    À titre d’exemple, si vous êtes situé au Canada et que votre transaction est traitée par une passerelle de paiement située aux États-Unis,
                    les renseignements vous appartenant qui ont été utilisés pour conclure la transaction pourraient être divulgués en vertu de la législation
                    des États-Unis, y compris le Patriot Act.
                </p>
                <p>
                    Une fois que vous quittez le site de notre boutique ou que vous êtes redirigé vers le site web ou l’application d’un tiers, vous n’êtes plus
                    régi par la présente Politique de Confidentialité ni par les Conditions Générales de Vente et d’Utilisation de notre site web.
                </p>
                <p>

                    Liens</p>
                <p>
                    Vous pourriez être amené à quitter notre site web en cliquant sur certains liens présents sur notre site. Nous n’assumons aucune
                    responsabilité quant aux pratiques de confidentialité exercées par ces autres sites et vous recommandons de lire attentivement leurs
                    politiques de confidentialité.
                </p>

                <div class="heading">SÉCURITÉ</div>
                <p>
                    Pour protéger vos données personnelles, nous prenons des précautions raisonnables et suivons les meilleures pratiques de l’industrie pour
                    nous assurer qu’elles ne soient pas perdues, détournées, consultées, divulguées, modifiées ou détruites de manière inappropriée.
                </p>
                <p>
                    Si vous nous fournissez vos informations de carte de crédit, elles seront chiffrées par le biais de l’utilisation du protocole de
                    sécurisation SSL et conservées avec un chiffrement de type AES-256. Bien qu’aucune méthode de transmission sur Internet ou de stockage
                    électronique ne soit sûre à 100 %, nous suivons toutes les exigences de la norme PCI-DSS et mettons en œuvre des normes supplémentaires
                    généralement reconnues par l’industrie.
                    FICHIERS TÉMOINS (COOKIES)
                </p>

                <div class="heading">ÂGE DE CONSENTEMENT</div>
                <p>
                    En utilisant ce site, vous déclarez que vous avez au moins l’âge de la majorité dans votre État ou province de résidence, et que vous nous
                    avez donné votre consentement pour permettre à toute personne d’âge mineur à votre charge d’utiliser ce site web.
                </p>

                <div class="heading">MODIFICATIONS APPORTÉES À LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ</div>
                <p>
                    Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment, donc veuillez s’il vous plait la consulter
                    fréquemment. Les changements et les clarifications prendront effet immédiatement après leur publication sur le site web. Si nous apportons
                    des changements au contenu de cette politique, nous vous aviserons ici qu’elle a été mise à jour, pour que vous sachiez quels renseignements
                    nous recueillons, la manière dont nous les utilisons, et dans quelles circonstances nous les divulguons, s’il y a lieu de le faire.
                </p>
                <p>
                    Si notre boutique fait l’objet d’une acquisition par ou d’une fusion avec une autre entreprise, vos renseignements pourraient être
                    transférés aux nouveaux propriétaires pour que nous puissions continuer à vous vendre des produits.
                </p>
                <div class="heading">QUESTIONS ET COORDONNÉES</div>
                <p>
                    Si vous souhaitez: accéder à, corriger, modifier ou supprimer toute information personnelle que nous avons à votre sujet, déposer une
                    plainte, ou si vous souhaitez simplement avoir plus d’informations, contactez notre agent responsable des normes de confidentialité à
                    hello@kidoftheworld.com ou par courrier à Kid of the World
                </p>
                <p>
                    Kid of the World
                    Chemin du Moulin-de-Vert, 10, Aire-la-Ville, GE, 1288, Suisse
                </p>
            </div>
        </div>
    </div>
</div>
