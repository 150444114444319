import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-refund-policy',
    templateUrl: './refund-policy.component.html',
    styleUrls: ['./refund-policy.component.scss'],
    animations: [
        trigger('appear', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateX(100%)'}))
            ])
        ]),
    ]
})
export class RefundPolicyComponent implements OnInit, OnDestroy {
    
    languageSubscription: Subscription;
    currentLang: string;
    supportedLanguages = ['en', 'fr'];
    
    constructor(private translate: TranslateService) {
    }
    
    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
    
        this.languageSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLang = this.translate.currentLang;
        });
    }
    
    ngOnDestroy(): void {
        this.languageSubscription?.unsubscribe();
    }
    
}
