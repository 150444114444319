import ISO6391 from 'iso-639-1';

export class LanguageHelper {

    public static getNativeName(lang: string, locales?: string[]) {
        if (lang === 'zh-TW') {
            return '漢語';
        } else if (lang === 'zh-Latn') {
            return 'Pīnyīn';
        } else if (lang === 'zh-HK') {
            return '廣東話';
        } else if (lang === 'ja') {
            return 'にほんご';
        } else if (lang.indexOf('en_') > -1) {
            if (this.hasMoreThanOneLanguage('en_', locales)) {
                if (lang === 'en_US') {
                    return 'English (US)';
                } else {
                    return 'English (GB)';
                }
            } else {
                return 'English';
            }
        } else if (lang === 'lo') {
            return 'ພາສາລາວ';
        } else if (lang === 'gcf') {
            return 'Kréol Gwadloup';
        } else if (lang === 'gcf-ma') {
            return 'Kréyol Mat\'nik';
        } else if (lang.startsWith('pt')) {
            if (this.hasMoreThanOneLanguage('pt', locales)) {
                if (lang === 'pt') {
                    return 'Português (Europe)';
                } else {
                    return 'Português (Brazil)';
                }
            } else {
                return 'Português';
            }
        } else if (lang.startsWith('ti')) {
            return 'ትግርኛ';
        } else if (lang === 'bn-trans-en') {
            return 'Bangla';
        } else if (lang === 'hi-trans-en') {
            return 'Hindee';
        } else if (lang === 'fa-ira') {
            return 'آزرگی';
        } else if (lang === 'fa-prs') {
            return 'دری';
        } else if (lang.startsWith('ku')) {
            if (lang === 'ku') {
                return 'Kurdî Kurmancî';
            } else {
                return 'سۆرانی';
            }
        } else if (lang.startsWith('vi')) {
            if (this.hasMoreThanOneLanguage('vi', locales)) {
                if (lang === 'vi') {
                    return 'Tiếng Việt';
                } else {
                    return 'Tiếng Việt miền Bắc';
                }
            } else {
                return 'Tiếng Việt';
            }
        } else if (lang === 'ta-trans-en') {
            return 'Tamil';
        } else if (lang === 'tl-ceb') {
            return 'Binisaya';
        } else {
            return ISO6391.getNativeName(lang);
        }
    }

    public static hasMoreThanOneLanguage(ref: string, locales: string[]) {
        return locales?.filter(locale => locale.indexOf('en_') > -1).length > 1;
    }
}
