import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {ReviewTranslationValidation} from '../models/translation-validation.model';
import {map} from 'rxjs/operators';
import {ItemOrder} from '../models/item-order.model';

@Injectable({
    providedIn: 'root'
})
export class TranslationValidationService {

    private translationUri = `${environment.apiUrl}/translations`;

    constructor(private http: HttpClient) {
    }

    getTranslations(themes: string[], source: string, target: string): Observable<ReviewTranslationValidation[]> {
        const themesStr = themes.join(',');
        const uri = `${this.translationUri}?themes=${themesStr}&source=${source}&target=${target}`;
        return this.http.get(uri).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    translate(itemOrder: ItemOrder): Observable<any> {
        if (!itemOrder?.locales?.length) {
            return of(null);
        }

        let params = new HttpParams();
        for (const locale of itemOrder.locales) {
            params = params.append('languages', locale);
        }

        return this.http.get(`${this.translationUri}/theme/preview/${itemOrder.theme.id}`, {params}).pipe(
            map((data: any) => {
                return data?.data;
            })
        );
    }

    confirmTranslations(name: string, messages: string[], reviewTranslations: ReviewTranslationValidation): Observable<any> {
        return this.http.post<any>(`${this.translationUri}/confirm`, {
            name,
            messages,
            reviewTranslations
        });
    }
}
