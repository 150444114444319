import {AfterViewInit, Component, ElementRef, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import * as cloneDeep from 'lodash/cloneDeep';
import {ItemOrder} from '../../../models/item-order.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {LanguageHelper} from '../../../helpers/language.helper';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GASettings} from '../../../models/ga-settings.model';
import {first} from 'rxjs/operators';
import {DialogService} from '../../../services/dialog.service';

export interface LanguageEditDialogParams {
    item: ItemOrder;
    availableLanguages: string[];
    maxLanguages: number;
}

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {

    searchText;
    maxLanguages: number;
    selectedLanguages: string[];
    availableLanguages: { key: string, name: string, nativeName: string }[];

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public params: LanguageEditDialogParams,
                public dialogRef: MatDialogRef<LanguageSelectorComponent>,
                private dialogService: DialogService,
                private translate: TranslateService,
                private gaService: GoogleAnalyticsService,) {
    }

    ngOnInit() {
        this.selectedLanguages = cloneDeep(this.params.item.locales);
        this.maxLanguages = this.params.maxLanguages;

        this.availableLanguages = this.params.availableLanguages.map(lang => {
            return {
                key: lang,
                name: this.translate.instant('languages.' + lang),
                nativeName: LanguageHelper.getNativeName(lang)
            };
        }).sort((a, b) => a.name > b.name ? 1 : -1);
    }

    toggleLanguage(key: string) {
        this.searchText = '';
        if (this.selectedLanguages.indexOf(key) !== -1) {
            if (this.selectedLanguages.length > 1) {
                this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'language_remove_' + key);
                this.selectedLanguages.splice(this.selectedLanguages.indexOf(key, 0), 1);
            }
        } else {
            if (this.selectedLanguages.length < this.maxLanguages) {
                this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'language_add_' + key);
                this.selectedLanguages.push(key);
            }
        }
    }

    removeLanguage(key: string) {
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'language_remove_' + key);
        this.selectedLanguages.splice(this.selectedLanguages.indexOf(key, 0), 1);
    }

    ok() {
        if (this.selectedLanguages?.length) {
            this.dialogRef.close(this.selectedLanguages);
        }
    }

    close() {
        this.dialogRef.close(this.params.item.locales);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedLanguages, event.previousIndex, event.currentIndex);
    }

    openContactForm() {
        this.dialogService.openContactFormDialog().afterClosed().pipe(first()).subscribe();
    }
}
