import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MetaGeneratorService {

    public static getTags(): { name: string, content: string }[] {
        const seo = {
            name: 'Kid of the World',
            title: 'Kid of the World | First personalized picture books with your children\'s very own languages',
            description: 'Kid of the World books are the first personalized picture books that feature your children’s very own languages and that can be opened by anyone in the family.',
            keywords: 'personalized multilingual books, multilingual books, personalized books, children books, citizen of the world, vocabulary books, vocabulary picture books, multilingual kids, multicultural children, bilingual kids,bilingual children, children literature',
            image: 'https://www.kidoftheworld.com/assets/images/kid-of-the-world-cover.png',
            site: 'https://www.kidoftheworld.com',
            siteShort: 'kidoftheworld.com',
            twitter: '@KOTW'
        };

        return [
            {name: 'description', content: seo.description},
            {name: 'keywords', content: seo.keywords},

            // {name: 'twitter:card', content: 'summary'},
            // {name: 'twitter:title', content: seo.title},
            // {name: 'twitter:description', content: seo.description},
            // {name: 'twitter:image', content: seo.image},
            // {name: 'twitter:creator', content: seo.twitter},

            {name: 'og:url', content: seo.site},
            {name: 'og:title', content: seo.title},
            {name: 'og:description', content: seo.description},
            {name: 'og:image', content: seo.image},
            {name: 'og:type', content: 'website'},
            {name: 'og:site_name', content: seo.name},
            {name: 'fb:app_id', content: '758785791342427'}
        ];
    }
}
