import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {animate, style, transition, trigger} from '@angular/animations';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GASettings} from '../../../models/ga-settings.model';
import {PixelService} from 'ngx-pixel';

@Component({
    selector: 'app-book-slider',
    templateUrl: './book-slider.component.html',
    styleUrls: ['./book-slider.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('.2s ease-in', style({opacity: '1'})),
            ]),
            transition(':leave', [
                style({opacity: '1'}),
                animate('.1s ease-out', style({opacity: '0'})),
            ])
        ]),
    ]
})
export class BookSliderComponent implements OnInit, OnDestroy {

    private timeOnComponent: moment.Moment;
    private WELCOME_ILLUSTRATION_LANGUAGES = [
        'ar',
        'am',
        'af',
        'zh',
        'da',
        'nl',
        'en',
        'fr',
        'co',
        'de',
        'hi',
        'it',
        'ja',
        'no',
        'pt',
        'ru',
        'es',
        'sv',
        'et',
        'fi',
        'tr',
        'yo',
        'es'
    ];

    languages = [];
    private subscription: Subscription;
    private langSubscription: Subscription;
    private currentLang;

    static shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    constructor(private translate: TranslateService,
                private router: Router,
                private pixel: PixelService,
                private gaService: GoogleAnalyticsService) {
    }

    ngOnInit() {
        this.timeOnComponent = moment();
        this.languages = BookSliderComponent.shuffleArray(['English', 'French', 'Spanish', 'Chinese']);

        this.subscription = interval(2400).subscribe(() => {
            this.currentLang = this.translate.currentLang;
            this.setLanguages();
        });

        this.langSubscription = this.translate.onLangChange.subscribe( (currentLang) =>  {
           this.currentLang = currentLang;
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
        this.langSubscription?.unsubscribe();
    }

    private setLanguages() {
        const langs = [];
        const minWordsOnSlider = 3;

        // Math.random() generates a number less than 0.5 the result will be 0 otherwise it should be 1.
        // We generate a random number between 0 and 1 and add 3, which give a random number between 3 and 4.
        // These are the number of languages we always want to see on the slider.
        // The current language should always appear.
        const randomLengthArray = (Math.random() > 0.5) ? 1 + minWordsOnSlider : minWordsOnSlider;
        langs.push(this.translate.instant('languages.' + this.currentLang));

        while (langs.length < randomLengthArray) {
            const rand = this.WELCOME_ILLUSTRATION_LANGUAGES[Math.floor(Math.random() * this.WELCOME_ILLUSTRATION_LANGUAGES.length)];
            const name = this.translate.instant('languages.' + rand).replace(/ *\([^)]*\) */g, '');
            if (langs.indexOf(name) === -1 && rand !== this.currentLang) {
                langs.push(name);
            }
        }

        // We shuffle the array to the current language doesn't always appear in the first place
        BookSliderComponent.shuffleArray(langs);
        this.languages = [];
        setTimeout(() => {
            this.languages = langs;
        }, 500);
    }

    public goToCreate() {
        const period = moment().diff(this.timeOnComponent, 'seconds');
        this.pixel.track('ViewContent');
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'create_from_slider', period);
        this.router.navigate(['/create']);
    }
}
