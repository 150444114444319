<div class="translations">
    <div class="translation" *ngFor="let translation of review.translations" [ngClass]="{'is-modified': translation.target !== originalTranslations.get(translation.source)}">
        <img class="left image" [src]="'data:image/png;base64,' + translation.image">
        <div class="right">
            <span class="source">{{translation.source}}</span>
            <mat-form-field class="target">
                <input matInput maxlength="50" [(ngModel)]="translation.target"/>
            </mat-form-field>
        </div>
    </div>
</div>
