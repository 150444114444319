import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {SessionDataServiceDirective} from './session-data-service.directive';
import {ReviewLanguageItem} from '../models/review-language.model';
import {map} from 'rxjs/operators';
import {Theme} from '../models/theme.model';
import {ItemOrder} from '../models/item-order.model';

/**
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 * https://fr.wikipedia.org/wiki/Liste_de_langues_par_nombre_total_de_locuteurs
 */
@Injectable({
    providedIn: 'root'
})
export class CreateService {
    
    private newLanguagesUri = `${environment.apiUrl}/create/new-languages`;
    
    private languagesStatistics = {
        en: 1268,
        zh: 1120,
        hi: 637.3,
        es: 537.9,
        fr: 276.6,
        ar: 274,
        bn: 265.2,
        ru: 258,
        pt: 252.2,
        id: 199,
        de: 161.6,
        ja: 126.4,
        sw: 98.5,
        mr: 95.3,
        te: 93,
        tu: 85.2
    };
    
    constructor(private http: HttpClient) {
    }
    
    getNewLanguages(itemOrder: ItemOrder): Observable<ReviewLanguageItem> {
        return this.http.post<ReviewLanguageItem>(`${this.newLanguagesUri}`, {
            theme: itemOrder.theme.id,
            locales: itemOrder.locales,
        }).pipe(
            map((data: any) => {
                return {
                    theme: itemOrder.theme,
                    languages: data
                };
            })
        );
    }
    
    // TODO présenter la donnée de manière plus intéressante et motivante
    // Ex votre enfant pourra parler à 2 milliars, votre enfant sera chez lui dans ces pays etc...
    // Faire rêver...
    // Ne montrer les stats que si qqun bloque sur la page de create
    checkKidOfTheWorldPercentage(locales: string[]) {
        const nbCitizensInWorld = 7700;
        const sum = locales.reduce((acc, locale) => {
            return acc + (this.languagesStatistics[locale] ? this.languagesStatistics[locale] : 0);
        }, 0);
        console.log('sum ' + sum);
        console.log(' Math.floor(sum / nbCitizensInWorld) ' + sum / nbCitizensInWorld);
        return Math.floor(sum / nbCitizensInWorld);
    }
    
}
