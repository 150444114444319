import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GASettings} from '../../../models/ga-settings.model';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {first, switchMap} from 'rxjs/operators';
import {SessionDataServiceDirective} from '../../../services/session-data-service.directive';
import {Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
    selector: 'app-reminder',
    templateUrl: './reminder.component.html',
    styleUrls: ['./reminder.component.scss']
})
export class ReminderComponent implements OnInit, OnDestroy {

    private reminderUri = `${environment.apiUrl}/reminder`;
    private languageSubscription: Subscription;
    private reminderSubscription: Subscription;
    private locale = 'en';
    private reminderMonths = 1;

    public reminderForm: FormGroup;
    public formSubmitted = false;

    constructor(private snackBar: MatSnackBar,
                private translate: TranslateService,
                private gaService: GoogleAnalyticsService,
                private session: SessionDataServiceDirective,
                private recaptchaV3Service: ReCaptchaV3Service,
                private http: HttpClient,) {
        this.reminderForm = new FormGroup({
            firstName: new FormControl('', [Validators.required]),
            email: new FormControl('',
                [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        });
    }

    ngOnInit(): void {
        this.languageSubscription = this.translate.onLangChange.subscribe(() => {
            this.locale = this.translate.currentLang;
        });
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.isFormValid()) {
            this.snackBar.open(this.translate.instant('create.reminder.form-not-valid'),
                this.translate.instant('common.close'),
                {
                    duration: 5000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
            return;
        }

        const firstName = this.reminderForm.get('firstName').value;
        const email = this.reminderForm.get('email').value;
        const reminderDate = moment().add(this.reminderMonths, 'months').format('YYYY-MM-DD');

        this.gaService.event(GASettings.ACTIONS.REMINDER, GASettings.CATEGORIES.ENGAGEMENT, 'set_reminder');
        this.reminderSubscription = this.recaptchaV3Service.execute('saveReminder')
            .pipe(
                first(),
                switchMap(token => this.http.post(`${this.reminderUri}`, {token, firstName, email, reminderDate, locale: this.locale}))
            ).subscribe((result: any) => {
                this.formSubmitted = true;
            });
    }

    isFormValid() {
        return this.reminderForm.valid && (this.reminderForm.touched || this.reminderForm.dirty);
    }

    ngOnDestroy() {
        this.languageSubscription?.unsubscribe();
        this.reminderSubscription?.unsubscribe();
    }

    setReminderMonths(reminderMonths: number) {
        console.log('this.remind', reminderMonths);
        this.reminderMonths = reminderMonths;
    }
}
