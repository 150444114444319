import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ContactService} from '../../../services/contact.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {first, switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-newsletter-form',
    templateUrl: './newsletter-form.component.html',
    styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent implements OnInit, OnDestroy {

    private newsletterSubscription: Subscription;

    @ViewChild('myForm') myForm: NgForm;

    public newsletterForm: FormGroup;
    public formSubmitted = false;
    public newsletterSent = false;

    @Input()
    title: string;

    @Input()
    subtitle: string;

    @Input()
    showCaptchaMessage = false;

    constructor(private contactService: ContactService,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private recaptchaV3Service: ReCaptchaV3Service) {
        this.newsletterForm = new FormGroup({
            email: new FormControl('email',
                [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        });

        this.newsletterForm.reset();
    }

    ngOnInit(): void {
    }

    onSubmit(event) {
        event.preventDefault();

        this.formSubmitted = true;
        this.newsletterSent = false;

        if (this.newsletterForm.valid && (this.newsletterForm.touched || this.newsletterForm.dirty)) {
            this.newsletterSubscription?.unsubscribe();
            this.newsletterSubscription = this.recaptchaV3Service.execute('subscribeNewsletter').pipe(
                first(),
                switchMap(token => this.contactService.subscribeNewsletter(this.newsletterForm.get('email').value,
                    this.translate.currentLang, token))
            ).subscribe((result => {
                this.formSubmitted = false;
                if (result.status === 'OK') {
                    this.initForm();
                    this.snackBar.open(this.translate.instant('main.newsletter.confirmation-received'),
                        this.translate.instant('common.close'), {
                            duration: 20000, horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });
                    this.newsletterSent = true;
                } else {
                    this.snackBar.open(this.translate.instant('main.newsletter.confirmation-not-received'),
                        this.translate.instant('common.close'), {
                            duration: 20000, horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });
                    this.newsletterSent = false;
                }
            }));
        } else {
            this.formSubmitted = false;
            this.newsletterSent = false;
            this.snackBar.open(this.translate.instant('main.contact.form-invalid'),
                this.translate.instant('common.close'), {
                    duration: 10000, horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
        }
    }

    initForm() {
        this.newsletterForm.reset();
        this.myForm.resetForm();
    }

    ngOnDestroy() {
        this.newsletterSubscription?.unsubscribe();
    }

}
