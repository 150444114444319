import {Component, OnDestroy, OnInit} from '@angular/core';
import {Images} from 'angular-responsive-carousel';
import * as moment from 'moment';
import {GASettings} from '../../models/ga-settings.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Router} from '@angular/router';
import {SessionDataServiceDirective} from '../../services/session-data-service.directive';
import {TranslateService} from '@ngx-translate/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {PixelService} from 'ngx-pixel';
import {DialogService} from '../../services/dialog.service';
import {ItemOrder} from '../../models/item-order.model';

export class AvailablePreviewBook {
    image: string;
    theme: string;
}

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('1s ease-in', style({opacity: '1'})),
            ]),
            transition(':leave', [
                style({opacity: '1'}),
                animate('1s ease-out', style({opacity: '0'})),
            ])
        ]),
    ]
})
export class MainComponent implements OnInit, OnDestroy {

    private colors = ['#ceeaf4', '#f4ddc9', '#f4e9ba', '#edf3cf', '#f4e1e3'];
    private availableBookSubscription: Subscription;
    private openBasketSubscription: Subscription;
    private basketDialogSubscription: Subscription;

    public previewBooks: AvailablePreviewBook[];
    public currentLang: string;
    public images: Images;
    public books: Images;
    public orderCards: any;

    constructor(private gaService: GoogleAnalyticsService,
                private router: Router,
                private translate: TranslateService,
                private pixel: PixelService,
                private dialogService: DialogService,
                private sessionDataService: SessionDataServiceDirective) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.sessionDataService.initialTheme = 'animals';
        this.setPreviewBooks();

        const images = [];
        for (let i = 1; i < 7; i++) {
            images.push({
                path: '../../../assets/images/kotw-illustration-' + i + '.png',
            });
        }
        this.images = images;

        const books = [];
        for (let i = 1; i < 7; i++) {
            books.push({
                path: '../../../assets/images/kotw-book-' + i + '.png',
                width: 500,
                height: 250
            });
        }
        this.books = books;

        this.orderCards = this.sessionDataService.lastOrders.map((langArray) => {
            return {
                languages: langArray,
                color: this.colors[Math.floor(Math.random() * 5)] // 5 = colors.length
            };
        });

        // Subscribe to open and close actions
        this.openBasketSubscription = this.sessionDataService.openBasket$.subscribe((id) => {
            this.showBasket();
        });
    }

    private showBasket() {
        this.basketDialogSubscription?.unsubscribe();
        this.basketDialogSubscription = this.dialogService.openBasketDialog().afterClosed()
            .pipe(first())
            .subscribe(result => {
                if (!result.close) {
                    if (result.checkout) {
                        this.goToOrder();
                    } else {
                        if (result.item) {
                            // We want to edit the item
                            this.editExitingBookFromOrder(result.item);
                        } else {
                            // We want to create another book
                            this.goToCreate();
                        }
                    }
                }
            });
    }

    private setPreviewBooks() {
        this.availableBookSubscription = this.sessionDataService.availableBooks$
            .pipe(
                filter(availableBooks => !!availableBooks?.length)
            ).subscribe(availableBooks => {
                const previewBooks = availableBooks.map(book => {
                    return {
                        theme: book.theme.id,
                        image: book.illustrations[0].id
                    }
                });
                if (previewBooks?.length) {
                    // TODO think of a better way to handle ordering of themes
                    this.previewBooks = [previewBooks[2], previewBooks[0], previewBooks[1]];
                }
            });
    }

    private editExitingBookFromOrder(item: ItemOrder) {
        this.sessionDataService.editingItem = item;
        this.goToCreate();
    }

    public goToCreate() {
        this.pixel.track('ViewContent');
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'create_from_home_basket');
        this.router.navigate(['/create']);
    }

    public goToOrder() {
        this.router.navigate(['/order']);
    }

    ngOnDestroy(): void {
        this.availableBookSubscription?.unsubscribe();
        this.openBasketSubscription?.unsubscribe();
        this.basketDialogSubscription?.unsubscribe();
    }

    trackShare() {
        this.gaService.event(GASettings.ACTIONS.SHARE, GASettings.CATEGORIES.ENGAGEMENT, 'share_from_footer');
    }
}
