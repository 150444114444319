<div class="newsletter">

    <div class="title">{{title}}</div>
    <div class="subtitle" [innerHTML]="subtitle"></div>
    <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit($event)" #myForm="ngForm">
        <mat-form-field>
            <mat-label>{{'common.email' | translate}}*</mat-label>
            <input matInput [placeholder]="'common.email' | translate" type="email" formControlName="email">
        </mat-form-field>
        <div class="actions">
            <button *ngIf="!formSubmitted" mat-raised-button color="accent" type="submit" [attr.aria-label]="'main.newsletter.action' | translate">
                <span>{{'main.newsletter.action' | translate}}</span>
            </button>
        </div>
        <mat-spinner *ngIf="formSubmitted"></mat-spinner>
    </form>
    <div class="info" *ngIf="newsletterSent">{{'main.newsletter.confirmation-received' | translate}}</div>
    <div class="captcha" *ngIf="showCaptchaMessage">
        This site is protected by reCAPTCHA <br>and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
</div>
