<div class="calendar">
    <mat-button-toggle-group name="Reminder" aria-label="Reminder frequency">
        <mat-button-toggle [checked]="true" (click)="setReminderMonths(1)">{{'create.reminder.one-month' | translate}}</mat-button-toggle>
        <mat-button-toggle (click)="setReminderMonths(2)">{{'create.reminder.two-months' | translate}}</mat-button-toggle>
        <mat-button-toggle (click)="setReminderMonths(3)">{{'create.reminder.three-months' | translate}}</mat-button-toggle>
        <mat-button-toggle (click)="setReminderMonths(6)">{{'create.reminder.six-months' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>
</div>
<div class="info">
    <form [formGroup]="reminderForm" (ngSubmit)="onSubmit($event)" *ngIf="!formSubmitted else isFormSubmitted">
        <mat-form-field>
            <mat-label>{{'order.form.first-name' | translate}}*</mat-label>
            <input matInput [placeholder]="'create.reminder.first-name' | translate"
                   formControlName="firstName"
                   autocomplete="off">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'create.reminder.email' | translate}}*</mat-label>
            <input matInput [placeholder]="'create.reminder.email' | translate"
                   formControlName="email"
                   autocomplete="off">
        </mat-form-field>
        <button mat-raised-button color="accent" type="submit"
                [attr.aria-label]="'create.reminder.remind-me' | translate">
            <mat-icon>send</mat-icon>
            {{'create.reminder.remind-me' | translate}}
        </button>
    </form>
    <ng-template #isFormSubmitted>
        <div class="form-submitted">{{'create.reminder.thank-you' | translate}}</div>
    </ng-template>
</div>
