import {NgModule} from '@angular/core';
import {BaseModule} from '../../base.module';
import {RouterModule} from '@angular/router';
import {TranslateValidationComponent} from './translate-validation.component';
import { ReviewThemeTranslationComponent } from './review-theme-translation/review-theme-translation.component';

@NgModule({
    declarations: [
        TranslateValidationComponent,
        ReviewThemeTranslationComponent,
    ],
    imports: [
        BaseModule,
        RouterModule.forChild([]),
    ],
    exports: [
        TranslateValidationComponent
    ],
    providers: []
})


export class TranslateValidationModule {
}
