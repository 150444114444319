import {Component, Input, OnInit} from '@angular/core';
import {ReviewTranslationValidation} from '../../../models/translation-validation.model';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'app-review-theme-translation',
    templateUrl: './review-theme-translation.component.html',
    styleUrls: ['./review-theme-translation.component.scss']
})
export class ReviewThemeTranslationComponent implements OnInit {

    public originalTranslations: Map<string, string> = new Map<string, string>();

    @Input()
    review: ReviewTranslationValidation;

    constructor() {
    }

    ngOnInit(): void {
        if (this.review && this.review.translations) {
            this.originalTranslations = new Map(cloneDeep(this.review.translations).map(i => [i.source, i.target]));
        }
    }

}
