import {NgModule} from '@angular/core';
import {BaseModule} from '../../base.module';
import {RouterModule} from '@angular/router';
import {AboutUsComponent} from './about-us.component';
import {AboutUsRoutingModule} from './about-us-routing.module';

@NgModule({
    declarations: [
        AboutUsComponent
    ],
    imports: [
        BaseModule,
        AboutUsRoutingModule,
        RouterModule.forChild([]),
    ],
    exports: [
        AboutUsComponent
    ],
    providers: []
})


export class AboutUsModule {
}
