import {Injectable} from '@angular/core';

export class ItemCalculation {
  constructor(
    public id: string,
    public theme: string,
    public totalItemsPrice: number,
    public discountedItemsPrice: number,
  ) {

  }
}

@Injectable({
  providedIn: 'root',
})
export class ItemCalculationAdapter {

  constructor() {
  }

  adapt(item: any): ItemCalculation {
    return new ItemCalculation(
      item.id,
      item.theme,
      item.totalItemsPrice,
      item.discountedItemsPrice,
    );
  }
}
