export class CurrencyUtils {

    public static getSymbols(): Map<string, string> {
        const currencies = new Map<string, string>();
        currencies.set('AUD', 'A$');
        currencies.set('CHF', '');
        currencies.set('EUR', '€');
        currencies.set('GBP', '£');
        currencies.set('USD', '$');
        return currencies;
    }
}
