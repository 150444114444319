<div class="refund-policy">

    <!-- TODO component that randomize clouds on the page -->
    <div class="city">
        <img id="city" src="../../../../assets/images/city.png"/>
    </div>
    <div class="cloud4">
        <img id="cloud-1" src="../../../../assets/images/cloud4.png"/>
    </div>
    <div class="cloud6">
        <img id="cloud-2" src="../../../../assets/images/cloud6.png"/>
    </div>
    <div class="cloud5">
        <img id="cloud-4" src="../../../../assets/images/cloud5.png"/>
    </div>

    <div class="refund-policy-wrapper" [@appear]>
        <div class="title">{{'common.refund-policy' | translate}}</div>
        <div class="content">
            <div class="warning" *ngIf="!supportedLanguages.includes(currentLang)">
                {{'legal.refund-policy-english-only' | translate}}
            </div>
            <div class="policy" *ngIf="currentLang !== 'fr'">
                <div class="heading">Returns</div>
                <p>
                    Our policy lasts 30 days. If 30 days have gone by since the date of delivery, unfortunately we can’t offer you a refund or exchange.
                </p>
                <p>
                    To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original
                    packaging.
                </p>
                <p>
                    To complete your return, we require a receipt or proof of purchase, such as a print of the email you received when you ordered on our
                    website.
                </p>
                <p>
                    There are certain situations where only partial refunds are granted: (if applicable)<br>
                    * Book with obvious signs of use<br>
                    * Any item not in its original condition, is damaged or missing parts for reasons not due to our error.<br>
                    * Any item that is returned more than 30 days after delivery<br>
                </p>
                <div class="heading">Refunds</div>
                <p>
                    Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also
                    notify you of the approval or rejection of your refund.
                    If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method
                    of payment, within a certain amount of days.
                </p>
                <div class="heading">Late or missing refunds</div>
                <p>
                    If you haven’t received a refund yet, first check your bank account again.<br>
                    Then contact your credit card company, it may take some time before your refund is officially posted.<br>
                    Next contact your bank. There is often some processing time before a refund is posted.<br>
                    If you’ve done all of this and you still have not received your refund yet, please contact us at hello@kidoftheworld.com.<br>
                </p>
                <div class="heading">Sale items</div>
                <p>
                    Only regular priced items may be refunded, unfortunately sale items cannot be refunded.
                </p>
                <div class="heading">Exchanges</div>
                <p>
                    We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at
                    hello@kidoftheworld.com.
                </p>
                <div class="heading">Shipping</div>
                <p>
                    To return your product, send us an email at hello@kidoftheworld.com.
                </p>
                <p>
                    You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a
                    refund, the cost of return shipping will be deducted from your refund.
                </p>
                <p>
                    Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
                </p>
                <p>
                    If you are shipping an item over $75, you should consider using a trackable shipping service or purchasing shipping insurance. We don’t
                    guarantee that we will receive your returned item.
                </p>
            </div>
            <div *ngIf="currentLang === 'fr'">
                <div class="heading">Retours</div>
                <p>
                    Notre politique dure 30 jours. Si 30 jours se sont écoulés depuis la date de réception de votre colis, nous ne pouvons malheureusement
                    pas vous offrir un remboursement ou un échange.
                </p>
                <p>
                    Pour pouvoir bénéficier d’un retour, votre article doit être inutilisé et dans le même état où vous l’avez reçu. Il doit être également
                    dans l’emballage d’origine.
                </p>
                <p>
                    Pour effectuer un retour, vous devez nous présenter un reçu ou une preuve d’achat, tel que l'e-mail que vous recevez quand vous procédez
                    à un achat sur notre site internet.
                </p>
                <p>
                    Il y a certaines situations où seul un remboursement partiel est accordé:<br>
                    * Livres avec des signes évidents d’utilisation.<br>
                    * Tout article qui n’est pas dans son état d’origine, qui est endommagé ou qui présente certaines pièces manquantes pour des raisons qui
                    ne sont pas dues à une erreur de notre part.<br>
                    * Tout article qui est retourné plus de 30 jours après la livraison.
                </p>
                <div class="heading">Remboursements</div>
                <p>
                    Une fois que nous aurons reçu et inspecté l’article retourné, nous vous enverrons un e-mail pour vous confirmer que nous l’avons bien
                    reçu. Nous vous informerons également de notre décision quant à l’approbation ou au rejet de votre demande de remboursement.
                    Si votre demande est approuvée, alors votre remboursement sera traité, et un crédit sera automatiquement appliqué à votre carte de
                    crédit ou à votre méthode originale de paiement, dans un délai d’un certain nombre de jours.
                </p>
                <div class="heading">Remboursements en retard ou manquants</div>
                <p>
                    Si vous n’avez pas encore reçu votre remboursement, veuillez d’abord consulter votre compte bancaire à nouveau.<br>
                    Ensuite, contactez l’entité émettrice de votre carte de crédit, car il pourrait y avoir un délai avant que votre remboursement ne soit
                    officiellement affiché.<br>
                    Ensuite, contactez votre banque. Il y a souvent un délai de traitement nécessaire avant qu’un remboursement ne soit affiché.<br>
                    Si après avoir effectué toutes ces étapes, vous n’avez toujours pas reçu votre remboursement, veuillez s’il vous plait nous contacter à
                    hello@kidoftheworld.com.<br>
                </p>
                <div class="heading">Articles soldés</div>
                <p>
                    Seuls les articles à prix courant peuvent être remboursés. Malheureusement, les articles soldés ne sont pas remboursables.
                </p>
                <div class="heading">Échanges</div>
                <p>
                    Nous remplaçons un article seulement s’il est défectueux ou endommagé. Si dans ce cas vous souhaitez l’échanger contre le même article,
                    envoyez-nous un e-mail à hello@kidoftheworld.com.
                </p>
                <div class="heading">Expédition</div>
                <p>
                    Pour retourner un produit, merci de nous écrire à hello@kidoftheworld.com.
                </p>
                <p>
                    Il vous incombera de payer vos propres frais d’expédition pour retourner votre article. Les coûts d’expédition ne sont pas
                    remboursables. Si vous recevez un remboursement, les frais de retour seront déduits de celui-ci.
                </p>
                <p>
                    En fonction de l’endroit où vous vivez, le délai nécessaire pour recevoir votre produit échangé peut varier.
                </p>
                <p>
                    Si vous expédiez un article d’une valeur supérieure à $75, vous devriez envisager d’utiliser un service de livraison qui vous permet de
                    suivre l’envoi ou de souscrire à une assurance de livraison. Nous ne garantissons pas que nous recevrons l’article que vous nous
                    retournez.
                </p>
            </div>
        </div>
    </div>
</div>
