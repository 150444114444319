<div class="order">

    <div class="city">
        <img id="city" src="../../../../assets/images/city.png"/>
    </div>
    <div class="cloud4">
        <img id="cloud-1" src="../../../../assets/images/cloud4.png"/>
    </div>
    <div class="cloud6">
        <img id="cloud-2" src="../../../../assets/images/cloud6.png"/>
    </div>
    <div class="cloud5">
        <img id="cloud-4" src="../../../../assets/images/cloud5.png"/>
    </div>

    <div class="order-wrapper" [@appear]>
        <div class="title">{{'order.title' | translate}}</div>
        <div class="subtitle">{{'order.hint' | translate}}</div>

        <app-order-details *ngIf="items && shippingCountry" [items]="items"
                           [shippingCountry]="shippingCountry"
                           (calculationDone)="setCalculation($event)"></app-order-details>

        <form [formGroup]="orderForm" (ngSubmit)="onSubmit($event)">

            <!-- Shipping details -->
            <div class="order-block">
                <div class="order-block-title">
                    <mat-icon>home</mat-icon>
                    <div>{{'order.form.shipping-address' | translate}}</div>
                </div>
                <div class="cols cols-33">
                    <mat-form-field>
                        <mat-label>{{'order.form.first-name' | translate}}*</mat-label>
                        <input (focusin)="setFocusIn('first-name-shipping')"
                            matInput [placeholder]="'order.form.first-name' | translate"
                            formControlName="shippingFirstName"
                            autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.last-name' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.last-name' | translate"
                               formControlName="shippingLastName"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.company' | translate}}</mat-label>
                        <input matInput [placeholder]="'order.form.company' | translate"
                               formControlName="shippingCompanyName"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.address' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.address' | translate"
                               formControlName="shippingAddress"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.complimentary-address' | translate}}</mat-label>
                        <input matInput [placeholder]="'order.form.complimentary-address' | translate"
                               formControlName="shippingComplimentaryAddress" autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.phone' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.phone' | translate" formControlName="shippingPhone"
                               autocomplete="off">
                        <mat-hint align="start">{{'order.form.phone-hint' | translate }}</mat-hint>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.city' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.city' | translate" formControlName="shippingCity"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.postal-code' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.postal-code' | translate"
                               formControlName="shippingPostalCode"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.region' | translate}}</mat-label>
                        <input matInput [placeholder]="'order.form.region' | translate" formControlName="shippingRegion"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.select-country' | translate}}</mat-label>
                        <mat-select [(value)]="shippingCountry">
                            <mat-option *ngFor="let country of allCountries" [value]="country">{{country.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- Contact details -->

            <div class="order-block">
                <div class="order-block-title">
                    <mat-icon>person</mat-icon>
                    <div>{{'order.form.contact-address' | translate}}</div>
                </div>
                <div class="cols cols-33 same-as-shipping">
                    <mat-checkbox formControlName="sameAsShipping">{{'order.form.same-as-shipping' | translate}}
                    </mat-checkbox>
                </div>
                <div class="cols cols-33" *ngIf="!orderForm.value.sameAsShipping">
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.first-name' | translate}}*</mat-label>
                        <input matInput formControlName="contactFirstName" autocomplete="off"
                               [placeholder]="'order.form.first-name' | translate">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.last-name' | translate}}*</mat-label>
                        <input matInput formControlName="contactLastName" autocomplete="off"
                               [placeholder]="'order.form.last-name' | translate">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.company' | translate}}</mat-label>
                        <input matInput formControlName="contactCompanyName" autocomplete="off"
                               [placeholder]="'order.form.company' | translate">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.address' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.address' | translate"
                               formControlName="contactAddress"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.complimentary-address' | translate}}</mat-label>
                        <input matInput [placeholder]="'order.form.complimentary-address' | translate"
                               formControlName="contactComplimentaryAddress"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.city' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.city' | translate" formControlName="contactCity"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.postal-code' | translate}}*</mat-label>
                        <input matInput [placeholder]="'order.form.postal-code' | translate"
                               formControlName="contactPostalCode"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.region' | translate}}</mat-label>
                        <input matInput [placeholder]="'order.form.region' | translate" formControlName="contactRegion"
                               autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.select-country' | translate}}</mat-label>
                        <mat-select #contactCountrySelector [(value)]="contactCountry">
                            <mat-option *ngFor="let country of allCountries" [value]="country">{{country.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- Payment details -->

            <div class="order-block">
                <div class="order-block-title">
                    <mat-icon>payment</mat-icon>
                    <div>{{'order.form.payment-details' | translate}}</div>
                </div>
                <div class="cols cols-50">
                    <mat-form-field appearance="standard">
                        <mat-label>{{'order.form.email' | translate}}*</mat-label>
                        <input (focusin)="setFocusIn('email')"
                               matInput [placeholder]="'order.form.email' | translate" formControlName="contactEmail"
                               type="email"
                               email="true">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'order.form.full-name' | translate}}*</mat-label>
                        <input (focusin)="setFocusIn('full-name')" matInput [placeholder]="'order.form.full-name' | translate"
                               formControlName="paymentFullName"
                               [ngClass]="{'form-submitted': formSubmitted}">
                        <mat-hint align="start">{{'order.form.full-name-hint' | translate }}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="payment-block">
                    <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"
                                     containerClass="kotw-stripe-container"></ngx-stripe-card>
                    <div class="fix-border"></div>
                </div>
            </div>

            <!-- Terms and Agreements here -->

            <div class="tos-block" *ngIf="!formSubmitted">
                <mat-checkbox formControlName="agreeToTerms"></mat-checkbox>
                <div class="tos">
                    <span>{{'order.form.tos-text' | translate}}</span> <span class="tos-link" (click)="openTos()">{{'order.form.tos-linked' | translate}}
                    *</span>
                </div>
            </div>

            <div class="submit">
                <button mat-raised-button color="accent" type="submit" *ngIf="!formSubmitted; else loader"
                        [attr.aria-label]="'order.form.action' | translate"
                        [disabled]="!foundStripe || !items || items.length === 0">
                    <mat-icon>send</mat-icon>
                    {{'order.form.action' | translate}}
                </button>
                <ng-template #loader>
                    <div class="loading">
                        <div class="loading-message">{{loadingMessage | translate}}</div>
                        <mat-spinner></mat-spinner>
                    </div>
                </ng-template>
            </div>
            <div class="error" *ngIf="!foundStripe">
                <div class="error">{{'order.errors.payment-connection-error' | translate}}</div>
            </div>
        </form>
    </div>
</div>
