<div class="create">

    <div class="create-wrapper" [@appear]>

        <div class="title">
            <div>{{'create.title' | translate}}</div>
        </div>

        <div class="subtitle" [innerHTML]="'create.hint' | translate"></div>

        <div class="first-book-preview">
            <carousel [loop]="true" [margin]="12" [borderRadius]="5" [height]="200" [cellWidth]="250">
                <div class="carousel-cell">
                    <picture>
                        <source srcset="../../../../assets/images/kotw-book-trans-1.webp" type="image/webp">
                        <source srcset="../../../../assets/images/kotw-book-trans-1.png" type="image/png">
                        <img src="../../../../assets/images/kotw-book-trans-1.png" alt="Picture book">
                    </picture>
                </div>
                <div class="carousel-cell">
                    <picture>
                        <source srcset="../../../../assets/images/kotw-book-trans-2.webp" type="image/webp">
                        <source srcset="../../../../assets/images/kotw-book-trans-2.png" type="image/png">
                        <img src="../../../../assets/images/kotw-book-trans-2.png" alt="Picture book">
                    </picture>
                </div>
                <div class="carousel-cell">
                    <picture>
                        <source srcset="../../../../assets/images/kotw-book-trans-3.webp" type="image/webp">
                        <source srcset="../../../../assets/images/kotw-book-trans-3.png" type="image/png">
                        <img src="../../../../assets/images/kotw-book-trans-3.png" alt="Picture book">
                    </picture>
                </div>
                <div class="carousel-cell">
                    <picture>
                        <source srcset="../../../../assets/images/kotw-book-trans-4.webp" type="image/webp">
                        <source srcset="../../../../assets/images/kotw-book-trans-4.png" type="image/png">
                        <img src="../../../../assets/images/kotw-book-trans-4.png" alt="Picture book">
                    </picture>
                </div>
                <div class="carousel-cell">
                    <picture>
                        <source srcset="../../../../assets/images/kotw-book-trans-5.webp" type="image/webp">
                        <source srcset="../../../../assets/images/kotw-book-trans-5.png" type="image/png">
                        <img src="../../../../assets/images/kotw-book-trans-5.png" alt="Picture book">
                    </picture>
                </div>
                <div class="carousel-cell">
                    <picture>
                        <source srcset="../../../../assets/images/kotw-book-trans-6.webp" type="image/webp">
                        <source srcset="../../../../assets/images/kotw-book-trans-6.png" type="image/png">
                        <img src="../../../../assets/images/kotw-book-trans-6.png" alt="Picture book">
                    </picture>
                </div>
            </carousel>
        </div>

        <div class="choose-cover" *ngIf="prices">
            <div class="cover-titles">
                <div>{{'common.HARD_COVER' | translate}}</div>
                <div>{{'common.SOFT_COVER' | translate}}</div>
            </div>
            <mat-button-toggle-group [attr.aria-label]="'create.choose-cover' | translate">
                <mat-button-toggle value="italic" (click)="setCoverType('hard')"
                                   [checked]="currentBook?.coverPaperCategory === 'HARD_COVER'">
                    <div class="create-amount">
                        <span class="number"><b>{{prices.hard.price}}</b></span>
                        <span class="cents"><b>.{{prices.hard.cents}}</b></span>
                        <span class="currency"><b>{{settings?.refCurrency}}</b></span>
                    </div>
                </mat-button-toggle>
                <mat-button-toggle value="bold" (click)="setCoverType('soft')"
                                   [checked]="currentBook?.coverPaperCategory === 'SOFT_COVER'">
                    <div class="create-amount">
                        <span class="number"><b>{{prices.soft.price}}</b></span>
                        <span class="cents"><b>.{{prices.soft.cents}}</b></span>
                        <span class="currency"><b>{{settings?.refCurrency}}</b></span>
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="create-preview">
            <div class="loading-all-books" *ngIf="loadingAvailableBooks">
                <div class="hint">{{'create.preview.loading-books' | translate}}</div>
                <mat-spinner></mat-spinner>
            </div>
            <div class="create-preview-menu" *ngIf="!loadingAvailableBooks">
                <div class="create-preview-menu-title hideMobile">{{'common.customize' | translate}}</div>
                <div class="create-preview-menu-title mobileOnly">{{'create.customize-and-preview' | translate}}</div>
                <div class="create-preview-menu-title-hint" *ngIf="isEditingBookFromOrder">{{'create.edit-book-in-your-cart-hint' | translate}}</div>
                <div class="create-preview-menu-box">
                    <div class="create-preview-menu-subtitle">{{'create.select-languages' | translate}}</div>
                    <mat-chip-list class="mat-chip-list-stacked select-languages"
                                   aria-orientation="vertical"
                                   cdkDropList
                                   cdkDropListOrientation="horizontal"
                                   (cdkDropListDropped)="drop($event)">
                        <mat-chip cdkDrag color="primary" selected="true" *ngFor="let selectedLanguage of currentBook?.locales; let j = index;">
                            <span (click)="editLanguages()">{{'languages.' + selectedLanguage | translate}}</span>
                            <mat-icon (click)="removeLanguage(j)"
                                      [matTooltip]="'create.remove-language' | translate"
                                      matChipRemove>cancel
                            </mat-icon>
                        </mat-chip>
                        <mat-chip color="accent" selected="true" (click)="editLanguages()" *ngIf="currentBook?.locales?.length < 4">
                            <div class="lang-chip">
                                <mat-icon>add</mat-icon>
                                {{'common.add' | translate}}
                            </div>
                        </mat-chip>
                        <mat-chip color="accent" selected="true" (click)="editLanguages()" *ngIf="currentBook?.locales?.length === 4">
                            <div class="lang-chip">
                                <mat-icon>edit</mat-icon>
                                {{'common.edit' | translate}}
                            </div>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div class="create-preview-menu-box">
                    <div class="create-preview-menu-subtitle">{{'create.select-theme' | translate}}</div>
                    <div class="select-themes" *ngIf="currentBook">
                        <mat-form-field class="full-width">
                            <mat-select [compareWith]="compareWithTheme"
                                        [(ngModel)]="currentBook.theme"
                                        (valueChange)="changeTheme($event)">
                                <mat-option *ngFor="let theme of allThemes;" [value]="theme">
                                    <div class="name">{{theme.translations[currentLanguage]}}</div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="create-preview-menu-box">
                    <div class="create-preview-menu-subtitle no-padding hideMobile">{{'create.custom-message' | translate}}</div>
                    <div class="add-dedication">
                        <mat-form-field class="full-width" *ngIf="currentBook" appearance="outline">
                            <mat-label>{{'create.add-custom-message' | translate}}</mat-label>
                            <textarea matInput
                                      cols="10"
                                      [(ngModel)]="currentBook.kidsName"
                                      [style.fontSize]="'16px'"
                                      (keyup)="limitLines($event)"
                                      (focus)="enterDedication()"
                                      (focusout)="leaveDedication()"
                                      [placeholder]="'create.dedication-example' | translate"
                                      cdkAutosizeMinRows="2"
                                      cdkAutosizeMaxRows="2"></textarea>
                            <mat-hint>{{'create.dedication-hint' | translate}}</mat-hint>
                        </mat-form-field>
                        <div *ngIf="dedicationError" class="dedication-warning">{{'create.dedication-too-long' | translate}}</div>
                    </div>
                </div>
                <div class="create-preview-menu-box preview-actions hide-mobile">
                    <button mat-raised-button color="accent"
                            [disabled]="!currentBook?.locales?.length" *ngIf="!isEditingBookFromOrder"
                            (click)="addToCart()" [attr.aria-label]="'create.add-to-order' | translate">
                        <mat-icon>add_shopping_cart</mat-icon>
                        {{'create.add-to-order' | translate}}
                    </button>
                    <button mat-raised-button color="accent"
                            [disabled]="!currentBook?.locales?.length" *ngIf="isEditingBookFromOrder"
                            (click)="addNewBook()" [attr.aria-label]="'create.create-new' | translate">
                        <mat-icon>add_circle_outline</mat-icon>
                        {{'create.create-new' | translate}}
                    </button>
                    <button mat-raised-button color="default" class="checkout-button"
                            *ngIf="nbSavedItems !== 0" (click)="order()" [attr.aria-label]="'common.checkout' | translate">
                        <mat-icon>shopping_cart</mat-icon>
                        <span>{{'common.checkout' | translate}}</span> <span *ngIf="nbSavedItems !== 0"> ({{nbSavedItems}} x
                        <span *ngIf="nbSavedItems === 1">{{'common.book' | translate}}</span>
                        <span *ngIf="nbSavedItems > 1">{{'common.books' | translate}}</span>)</span>
                    </button>
                </div>
                <div class="create-discount-message hideMobile" *ngIf="prices">
                    <div *ngIf="multiBookOfferActive">
                        <span>
                            <span class="old">{{prices[selectedCoverType].value}}</span>
                            <span>{{'create.preview.offer-multi-books' | translate: {new: prices[selectedCoverType].discount} }}</span>
                        </span>
                    </div>
                    <div>
                        <mat-icon>card_giftcard</mat-icon>
                        {{'create.preview.offer-shipping' | translate}}
                    </div>
                </div>
            </div>
            <div class="create-preview-book" *ngIf="!loadingAvailableBooks">
                <div class="create-preview-book-title hideMobile">{{'common.preview' | translate}}</div>
                <app-create-preview [currentLanguage]="currentLanguage"
                                    [supportedLanguageList]="supportedLanguageList"
                                    [selectedBook]="currentBook"
                                    [showDedication]="showDedication"
                                    (addLanguageChange)="editLanguages()"></app-create-preview>
                <div class="create-preview-hint" *ngIf="currentBookHasNewLang">{{'create.preview-hint' | translate}}</div>
            </div>
            <div class="preview-actions actions-mobile" *ngIf="!loadingAvailableBooks">
                <button mat-raised-button color="accent"
                        [disabled]="!currentBook?.locales?.length"
                        *ngIf="!isEditingBookFromOrder" (click)="addToCart()" [attr.aria-label]="'create.add-to-order' | translate">
                    <mat-icon>add_shopping_cart</mat-icon>
                    {{'create.add-to-order' | translate}}
                </button>
                <button mat-raised-button color="accent"
                        [disabled]="!currentBook?.locales?.length" *ngIf="isEditingBookFromOrder"
                        (click)="addNewBook()" [attr.aria-label]="'create.create-new' | translate">
                    <mat-icon>add_shopping_cart</mat-icon>
                    {{'create.create-new' | translate}}
                </button>
                <button mat-raised-button color="default" class="checkout-button"
                        *ngIf="nbSavedItems !== 0" (click)="order()" [attr.aria-label]="'common.checkout' | translate">
                    <mat-icon>shopping_cart</mat-icon>
                    <span>{{'common.checkout' | translate}}</span> <span *ngIf="nbSavedItems !== 0"> ({{nbSavedItems}} x
                        <span *ngIf="nbSavedItems === 1">{{'common.book' | translate}}</span>
                        <span *ngIf="nbSavedItems > 1">{{'common.books' | translate}}</span>)</span>
                </button>
                <div class="create-discount-message">
                    <div *ngIf="multiBookOfferActive">
                        <span>
                            <span class="old">{{prices[selectedCoverType].value}}</span>
                            <span>{{'create.preview.offer-multi-books' | translate: {new: prices[selectedCoverType].discount} }}</span>
                        </span>
                    </div>
                    <div>
                        <mat-icon>card_giftcard</mat-icon>
                        {{'create.preview.offer-shipping' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="wrapper book-pictures-wrapper">
        <div class="book-pictures">
            <h2>{{'create.book-preview' | translate }}</h2>
            <carousel [loop]="true" [images]="imageBooks" [margin]="36" [borderRadius]="5" [height]="333" [cellWidth]="500"></carousel>
            <p class="book-pictures-hint" [innerHTML]="'create.quality' | translate"></p>
        </div>
    </div>

    <div class="reminder-wrapper">
        <div class="reminder">
            <h2>{{'create.reminder.title' | translate}}</h2>
            <div class="subtitle">{{'create.reminder.subtitle' | translate}}</div>
            <div class="reminder-form">
                <app-reminder></app-reminder>
            </div>
        </div>
    </div>

    <div class="wrapper how-to-wrapper">
        <div class="how-to">
            <h2>{{'create.how-to.title' | translate }}</h2>
            <div class="subtitle">{{'create.how-to.subtitle' | translate }}</div>
            <div class="how-to-video">
                <video controls (click)="watchVideo()">
                    <source src="https://storage.googleapis.com/kidoftheword.appspot.com/videos/kidoftheworld-games.mp4" type="video/mp4">
                    Your browser does not support videos
                </video>
            </div>
            <div class="how-to-hints">
                <p><b>{{'create.how-to.hints.title-1' | translate}}</b>: {{'create.how-to.hints.hint-1' | translate}}</p>
                <p><b>{{'create.how-to.hints.title-2' | translate}}</b>: {{'create.how-to.hints.hint-2' | translate}}</p>
                <p><b>{{'create.how-to.hints.title-3' | translate}}</b>: {{'create.how-to.hints.hint-3' | translate}}</p>
                <p><b>{{'create.how-to.hints.title-4' | translate}}</b>: {{'create.how-to.hints.hint-4' | translate}}</p>
                <p>
                    {{'create.how-to.your-tip' | translate}}
                </p>
            </div>

            <div class="social-networks-icons">
                <a href="https://www.instagram.com/kidoftheworldbooks/" target="_blank">
                    <img src="../../../../assets/images/5296765_camera_instagram_instagram logo_icon.png" alt="Instagram">
                </a>
                <a href="https://www.facebook.com/kidoftheworldbooks" target="_blank">
                    <img src="../../../../assets/images/5365678_fb_facebook_facebook logo_icon.png" alt="Facebook">
                </a>
            </div>
        </div>
    </div>

    <div class="wrapper last-orders-wrapper">
        <div class="last-orders">
            <h2>{{'main.last-orders.title' | translate }}</h2>
            <carousel [margin]="24" [height]="210" [cellWidth]="148" [borderRadius]="5"
                      [cellsToScroll]="2" *ngIf="orderCards">
                <div class="carousel-cell last-order" *ngFor="let orderCard of orderCards"
                     [ngStyle]="{'background': orderCard.color}">
                    <div class="language" *ngFor="let lang of orderCard.languages">
                        {{'languages.' + lang | translate}}
                    </div>
                </div>
            </carousel>
        </div>
    </div>

    <!--    <div class="wrapper process-overview-wrapper">-->
    <!--        <div class="process-overview">-->
    <!--            <h2>{{'create.process-overview' | translate }}</h2>-->
    <!--            <div class="subtitle">{{'create.process-overview-subtitle' | translate}}</div>-->
    <!--            <button mat-raised-button color="accent" (click)="openProcessOverview()" [attr.aria-label]="'common.order' | translate">-->
    <!--                <span>{{'common.discover' | translate}}</span>-->
    <!--            </button>-->
    <!--        </div>-->
    <!--    </div>-->

    <div class="faq-wrapper">
        <app-faq [createMode]="true"></app-faq>
    </div>

    <div class="wrapper book-pictures-wrapper">
        <div class="book-pictures">
            <h2>{{'main.quote.open-again' | translate }}</h2>
            <carousel [loop]="true" [images]="lastImageBooks" [margin]="24" [borderRadius]="5" [height]="400" [cellWidth]="300"></carousel>
        </div>
    </div>
</div>
