import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ItemOrder} from '../../../models/item-order.model';
import {Country} from '../../../models/country.model';
import {SessionDataServiceDirective} from '../../../services/session-data-service.directive';
import * as moment from 'moment';
import {Subject, Subscription} from 'rxjs';
import {DetailedOrderCalculationModel} from '../../../models/detailed-order-calculation.model';
import {ItemCalculation} from '../../../models/item-calculation.model';
import {PaperCategory, ShippingMethod} from '../../order/order.helper';
import {first, switchMap, tap} from 'rxjs/operators';
import {OrderService} from '../../../services/order.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {PixelService} from 'ngx-pixel';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogService} from '../../../services/dialog.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {DialogResult} from '../../../services/dialog.result';
import {ItemsHelper} from '../../../helpers/items.helper';
import {GASettings} from '../../../models/ga-settings.model';

export class BasketDialogParams {
    item?: ItemOrder;
}

@Component({
    selector: 'app-basket-dialog',
    templateUrl: './basket-dialog.component.html',
    styleUrls: ['./basket-dialog.component.scss']
})
export class BasketDialogComponent implements OnInit, OnDestroy {
    items: ItemOrder[] = [];
    shippingCountry: Country;

    private languageSubscription: Subscription;
    private couponSubscription: Subscription;
    private dialogShippingSubscription: Subscription;
    private settingsSubscription: Subscription;
    private dialogSubscription: Subscription;
    private currentLanguage: string;
    private currencyChanged$ = new Subject<string>();
    private currencySubscription: Subscription;
    private item: ItemOrder;

    public coupon = '';
    public loading = false;
    public detailedOrderCalculation: DetailedOrderCalculationModel;
    public itemsCalculations: Map<string, ItemCalculation> = new Map<string, ItemCalculation>();
    public currency: string;
    public totalPromotion = 0;
    public isGift = false;
    public supportedCurrencyList: string[];
    public nbBooksInOrder: number;
    public giftPrice: number;

    @Output()
    shippingMethod: EventEmitter<ShippingMethod> = new EventEmitter<ShippingMethod>();

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public params: BasketDialogParams,
                public sessionDataService: SessionDataServiceDirective,
                public dialogRef: MatDialogRef<BasketDialogComponent>,
                private orderService: OrderService,
                private translate: TranslateService,
                private router: Router,
                private pixel: PixelService,
                private snackBar: MatSnackBar,
                private dialogService: DialogService,
                private gaService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
        this.shippingCountry = this.sessionDataService.getShippingCountry();
        this.items = this.sessionDataService.currentOder.content.items;
        this.isGift = this.sessionDataService.currentOder.content.isGift;
        this.item = this.params.item;

        this.currency = this.sessionDataService.refCurrency;
        this.currentLanguage = this.translate.currentLang;

        this.settingsSubscription = this.sessionDataService.settingsSubject$.subscribe(() => {
            this.supportedCurrencyList = this.sessionDataService.settings?.supportedCurrencyList;
            this.currency = this.sessionDataService.refCurrency;
            this.giftPrice = this.sessionDataService.settings.giftPrice;
            this.calculatePrice();
        });

        this.currencySubscription = this.currencyChanged$.pipe(
            switchMap(currency => this.sessionDataService.loadSettings(currency)),
        ).subscribe(() => {
            this.currency = this.sessionDataService.refCurrency;
        });

        this.languageSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLanguage = this.translate.currentLang;
        });
    }

    setCurrency(curr: string) {
        this.currencyChanged$.next(curr);
    }

    chooseShippingCountry() {
        this.dialogShippingSubscription?.unsubscribe();
        this.dialogShippingSubscription = this.dialogService.openShippingSelector().afterClosed().subscribe((result) => {
            if (result === DialogResult.YES) {
                this.shippingCountry = this.sessionDataService.getShippingCountry();
                this.calculatePrice();
            }
        });
    }

    calculatePrice() {
        this.loading = true;
        this.orderService.calculatePrice(this.currency)
            .pipe(
                first(),
                tap(() => this.loading = true),
            ).subscribe((data) => {
            // Fill in the itemsCalculations which is used to easily access prices from the html
            data.itemsCalculationPriceOrderList.forEach(i => {
                this.itemsCalculations.set(i.id, new ItemCalculation(i.id, i.theme, i.totalItemsPrice, i.discountedItemsPrice));
            });
            // Set the detailedOrderCalculation used to display prices in the html
            this.detailedOrderCalculation = data;
            this.totalPromotion = data.totalOrderPrice.coupons.reduce((sum, coupon) => {
                return (coupon.applied ? coupon.percentOff : 0) + sum;
            }, 0);
            this.nbBooksInOrder = ItemsHelper.getNbDuplicates(this.items);
            this.loading = false;
        }, (err) => {
            this.loading = false;
        });
    }

    addDuplicate(item: ItemOrder) {
        item.duplicates = item.duplicates + 1;
        this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, 'add_duplicate', item.duplicates);
        this.sessionDataService.itemsChanged$.next();
        this.calculatePrice();
    }

    removeDuplicate(item: ItemOrder) {
        item.duplicates = item.duplicates - 1;
        this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, 'remove_duplicate', item.duplicates);
        this.calculatePrice();

        if (item.duplicates === 0) {
            this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'remove_book');
            this.pixel.track('CustomizeProduct');
            const indexToRemove = this.sessionDataService.currentOder.content.items.findIndex(i => i.id === item.id);
            this.sessionDataService.currentOder.content.items.splice(indexToRemove, 1);
        }

        this.sessionDataService.itemsChanged$.next();
    }

    getBookName(item: ItemOrder) {
        return this.items.find(b => b.theme.id === item.theme.id).theme.translations[this.currentLanguage];
    }

    setCoverType(item: ItemOrder) {
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT,
            'set_' + item.coverPaperCategory.valueOf().toLowerCase());

        this.calculatePrice();
        this.sessionDataService.itemsChanged$.next();
    }

    getLanguages(locales: string[]): string {
        return locales.map(locale => this.translate.instant('languages.' + locale)).join(', ');
    }

    order() {
        if (!this.sessionDataService.currentOder.content.items?.length) {
            return;
        }

        if (this.sessionDataService.getShippingCountry()) {
            this.saveBooksForOrder();
        } else {
            this.dialogShippingSubscription?.unsubscribe();
            this.dialogShippingSubscription = this.dialogService.openShippingSelector().afterClosed().subscribe((result) => {
                if (result === DialogResult.YES) {
                    this.saveBooksForOrder();
                }
            });
        }
    }

    saveBooksForOrder() {
        this.sessionDataService.saveItems(this.items);
        this.dialogRef.close({checkout: true});
    }

    close() {
        this.dialogRef.close({close: true});
    }

    setGift() {
        this.sessionDataService.currentOder.content.isGift = this.isGift;
        this.calculatePrice();
        this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, this.isGift ? 'setting_gift' : 'removing_gift');
    }

    createAnotherBook() {
        this.dialogRef.close({checkout: false, close: false});
    }

    editBook(index: number) {
        const item = this.sessionDataService.currentOder.content.items[index];
        this.dialogRef.close({item, checkout: false});
    }

    getExpectedDate(leadTime: number): string {
        const today = moment();
        const deliveryDate = today.add(leadTime, 'days');
        return deliveryDate.format('Do MMMM, YYYY');
    }

    ngOnDestroy(): void {
        this.languageSubscription?.unsubscribe();
        this.settingsSubscription?.unsubscribe();
        this.dialogSubscription?.unsubscribe();
        this.couponSubscription?.unsubscribe();
        this.dialogShippingSubscription?.unsubscribe();
        this.currencySubscription?.unsubscribe();
    }
}
