import {NgModule} from '@angular/core';
import {BaseModule} from '../../base.module';
import {HeaderComponent} from './header.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    declarations: [
        HeaderComponent,
    ],
    imports: [
        BaseModule,
        SharedModule,
        RouterModule.forChild([]),
    ],
    exports: [
        HeaderComponent,
    ],
    providers: []
})


export class HeaderModule {
}
