<div class="confirmation">
    <div class="city">
        <img id="city" src="../../../../assets/images/city.png"/>
    </div>
    <div class="cloud4">
        <img id="cloud-1" src="../../../../assets/images/cloud4.png"/>
    </div>
    <div class="cloud6">
        <img id="cloud-2" src="../../../../assets/images/cloud6.png"/>
    </div>
    <div class="cloud5">
        <img id="cloud-4" src="../../../../assets/images/cloud5.png"/>
    </div>
    <div class="confirmation-wrapper" [@appear]>
        <div class="message-wrapper">
            <div class="main-title">{{'confirmation.thank-you' | translate}}</div>
            <div class="subtitle-warn">{{'confirmation.spam' | translate: {value: orderConfirmation?.order?.contact?.email} }}</div>
            <div class="message">
                <div class="title">
                    <mat-icon>confirmation_number</mat-icon>
                    <div>{{'confirmation.order-number' | translate}}</div>
                </div>
                <div class="message-text order-number" *ngIf="orderConfirmation?.ref">{{orderConfirmation.ref}}</div>
            </div>
            <div class="message">
                <div class="title">
                    <mat-icon>map</mat-icon>
                    <div>{{'confirmation.books-shipped-to' | translate}}</div>
                </div>
                <div class="message-text" *ngIf="orderConfirmation?.order?.shipping">
                    <div>{{orderConfirmation.order.shipping.firstName}} {{orderConfirmation.order.shipping.lastName}}</div>
                    <div>{{orderConfirmation.order.shipping.contactAddress.phone}}</div>
                    <div *ngIf="orderConfirmation.order.shipping.companyName">{{orderConfirmation.order.shipping.companyName}}</div>
                    <div>{{orderConfirmation.order.shipping.contactAddress.address}}</div>
                    <div *ngIf="orderConfirmation.order.shipping.contactAddress.complimentary">{{orderConfirmation.order.shipping.contactAddress.complimentary}}</div>
                    <div>{{orderConfirmation.order.shipping.contactAddress.city}} <span
                        *ngIf="orderConfirmation.order.shipping.contactAddress.region">/ {{orderConfirmation.order.shipping.contactAddress.region}}</span></div>
                    <div>{{orderConfirmation.order.shipping.contactAddress.country.name}}</div>
                    <div class="hint">{{'confirmation.change-order' | translate}}</div>
                </div>
            </div>
            <div class="message">
                <div class="title">
                    <mat-icon>help_outline</mat-icon>
                    <div>{{'confirmation.hear-about-us.title' | translate}}</div>
                </div>
                <div class="message-text">
                    <mat-form-field>
                        <mat-label>{{'confirmation.hear-about-us.label' | translate}}</mat-label>
                        <mat-select (valueChange)="setHearAboutUs($event)" [disabled]="hearAboutUsGiven">
                            <mat-option value="word-of-mouth">{{'confirmation.hear-about-us.word-of-mouth' | translate}}</mat-option>
                            <mat-option value="at-school">{{'confirmation.hear-about-us.at-school' | translate}}</mat-option>
                            <mat-option value="social-media">{{'confirmation.hear-about-us.social-media' | translate}}</mat-option>
                            <mat-option value="ads-social-media">{{'confirmation.hear-about-us.ads-social-media' | translate}}</mat-option>
                            <mat-option value="classlist">{{'confirmation.hear-about-us.classlist' | translate}}</mat-option>
                            <mat-option value="google">{{'confirmation.hear-about-us.google' | translate}}</mat-option>
                            <mat-option value="blog">{{'confirmation.hear-about-us.blog' | translate}}</mat-option>
                            <mat-option value="other">{{'confirmation.hear-about-us.other' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <p *ngIf="hearAboutUsGiven" class="is-highlighted">{{'confirmation.hear-about-us.feedback-received' | translate}}</p>
                </div>
            </div>
            <div class="message">
                <div class="title">
                    <mat-icon>favorite</mat-icon>
                    <div>{{'confirmation.share-title' | translate}}</div>
                </div>
                <div class="message-text">{{shareSentence}}</div>
                <share-buttons (click)="share()" class="share" [theme]="'circles-dark'"
                               [include]="['facebook','messenger','whatsapp']"
                               [show]="3"
                               [url]="'https://www.kidoftheworld.com/'"
                               [title]="'commont.title' | translate"
                               [description]="'main.welcome.subtitle' | translate"
                               [autoSetMeta]="false"
                ></share-buttons>
            </div>
        </div>
    </div>

</div>
