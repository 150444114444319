import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LanguageEditDialogParams, LanguageSelectorComponent} from '../components/create/language-selector/language-selector.component';
import {TosDialogComponent} from '../components/order/tos-dialog/tos-dialog.component';
import {ShippingSelectorComponent} from '../components/create/shipping-selector/shipping-selector.component';
import {ComponentType} from '@angular/cdk/portal';
import {ContactFormComponent} from '../components/main/contact-form/contact-form.component';
import {PaperCategory} from '../components/order/order.helper';
import {CoverEditDialogParams, CoverSelectorComponent} from '../components/shared/cover-selector/cover-selector.component';
import {BasketDialogComponent} from '../components/create/basket-dialog/basket-dialog.component';
import {ItemOrder} from '../models/item-order.model';
import {SuperheroNotificationComponent} from '../components/create/superhero-notification/superhero-notification.component';
import {SelectedReviewLanguage} from '../models/review-language.model';
import {AmbassadorDialogComponent} from '../components/order/ambassador-dialog/ambassador-dialog.component';
import {GreetingsComponent} from '../components/order/greetings/greetings.component';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {ProcessOverviewComponent} from '../components/shared/process-overview/process-overview.component';
import {WrapPreviewComponent} from '../components/order/wrap-preview/wrap-preview.component';
import {DiscountNotificationComponent} from '../components/shared/discount-notification/discount-notification.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
    widthDialog = '500px';

    constructor(private breakpointObserver: BreakpointObserver, private dialog: MatDialog) {
    }

    openDialog<T, D>(dialog: ComponentType<T>, size: string, element?: D): MatDialogRef<T, any> {
        return this.dialog.open(dialog, {
            disableClose: true,
            width: size,
            data: element
        });
    }

    openEditLanguagesDialog(item: ItemOrder, availableLanguages: string[], maxLanguages: number) {
        const config = {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '100vw',
            marginLeft: '10px',
            panelClass: 'full-screen-modal',
            data: {
                item,
                    availableLanguages,
                    maxLanguages,
            }
        };

        return this.dialog.open(LanguageSelectorComponent, config);
    }

    openSuperHeroNotification(item: ItemOrder, newLanguages: string[]) {
        return this.dialog.open(SuperheroNotificationComponent, {
            disableClose: false,
            width: this.widthDialog,
            data: {
                item, newLanguages
            }
        });
    }

    openTos() {
        const config = {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '100vw',
            marginLeft: '10px',
            panelClass: 'full-screen-modal',
        };

        return this.dialog.open(TosDialogComponent, config);
    }

    openProcessOverview() {
        const config = {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '100vw',
            marginLeft: '10px',
            panelClass: 'full-screen-modal',
            autoFocus: false
        };

        return this.dialog.open(ProcessOverviewComponent, config);
    }

    openShippingSelector() {
        return this.openDialog(ShippingSelectorComponent, this.widthDialog);
    }

    openContactFormDialog() {
        return this.dialog.open(ContactFormComponent, {
            disableClose: false,
            width: this.widthDialog
        });
    }

    openAmbassadorDialog(selectedReviewLanguage: SelectedReviewLanguage) {
        return this.dialog.open(AmbassadorDialogComponent, {
            data: {
                selectedReviewLanguage
            },
            width: '800px'
        });
    }

    openBasketDialog(item?: ItemOrder) {
        return this.dialog.open(BasketDialogComponent, {
            data: {
                item
            },
            panelClass: 'full-screen-modal-mobile',
            disableClose: true,
            width: '600px',
            maxWidth: '100vw',
            maxHeight: '100vh',
        });
    }

    openGreetingsEditor(greetings: string) {
        const config = {
            maxWidth: '100vw',
            maxHeight: '100vh',
            width: this.widthDialog,
            panelClass: 'full-screen-modal-mobile',
            data: {
                greetings
            }
        };

        return this.dialog.open(GreetingsComponent, config);
    }

    openWrapPreview(locales: string[]) {
        return this.dialog.open(WrapPreviewComponent, {
            data: {
                locales
            },
            disableClose: false,
        });
    }

    openDiscountNotification() {
        return this.dialog.open(DiscountNotificationComponent, {
            width: '500px'
        });
    }

}
