import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    
    fakeCards: string[];
    year: number;
    
    constructor() {
    }
    
    ngOnInit() {
        this.year = moment().year();
        this.fakeCards = environment.fakeCards;
    }
    
}
