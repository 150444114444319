import {ItemOrder} from '../models/item-order.model';
import {flatten} from 'lodash';

export class ItemsHelper {

    public static getNbDuplicates(items: ItemOrder[]) {
        return items.reduce((sum, item) => sum + item.duplicates, 0);
    }

    public static getNbLanguages(items: ItemOrder[]) {
        return items.reduce((sum, item) => sum + item.locales?.length ?? 0, 0);
    }

    public static getAllLanguages(items: ItemOrder[]): string[] {
        const allLocales = new Set(flatten(items.map(item => item.locales)));
        const result = [];
        allLocales.forEach(l => result.push(l));
        return result;
    }

    public static hasItemWithFewLanguages(items: ItemOrder[]) {
        return items.some(item => !item.locales?.length || item.locales.length < 2);
    }
}
