import {Directive, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {sortBy} from 'lodash';
import * as uuid from 'uuid';
import {Settings} from '../models/settings.model';
import {map, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Illustration} from '../models/illustration.model';
import {environment} from '../../environments/environment';
import ISO6391 from 'iso-639-1';
import * as i18nIsoCountries from 'i18n-iso-countries';
import {Country} from '../models/country.model';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {NotificationDefinitions} from '../models/coupon-definition.model';
import {PaperCategory} from '../components/order/order.helper';
import {ItemOrder} from '../models/item-order.model';
import {ReviewLanguageItem, SelectedReviewLanguage} from '../models/review-language.model';
import {LanguageHelper} from '../helpers/language.helper';
import {Order} from '../models/order.model';
import {DetailedOrderCalculationModel} from '../models/detailed-order-calculation.model';
import {ContentOrder} from '../models/content-order.model';

declare const require;

export interface OrderConfirmation {
    ref: string;
    order: Order;
}

export enum ABTestFeatureName {
    AMBASSADOR = 'AMBASSADOR', // AMBASSADOR allows the user to validate its own languages for a discount
}

export interface ABTestFeature {
    name: ABTestFeatureName;
    active: boolean;
}

@Directive()
@Injectable({
    providedIn: 'root'
})
export class SessionDataServiceDirective {

    private settingsUri = `${environment.apiUrl}/settings`;
    private notificationsUri = `${environment.apiUrl}/notifications`;
    private availableBooksUri = `${environment.apiUrl}/available-books`;

    private settingsMap = new Map<string, Settings>();

    public itemsChanged$: Subject<void> = new Subject<void>();
    public priceChanged$: Subject<DetailedOrderCalculationModel> = new Subject<DetailedOrderCalculationModel>();
    public openBasket$: Subject<string> = new Subject<string>();
    public confettiCreated$: Subject<void> = new Subject<void>();

    public orderConfirmation: OrderConfirmation;
    public availableBooks$ = new BehaviorSubject<ItemOrder[]>([]);
    public settingsSubject$ = new BehaviorSubject<Settings>(null);
    public editingItem: ItemOrder;
    public settings: Settings;
    public refCurrency = 'EUR';
    public useCouponSubject$: Subject<string> = new Subject();
    public superHeroNotified = false;
    public abTestFeatures: ABTestFeature[] = [];
    public initialTheme: string;

    public currentOder: Order = {
        content: {
            items: [],
            coupon: null,
            isGift: false,
            greetings: ''
        } as ContentOrder,
        shipping: {
            contactAddress: {
                country: null
            }
        }
    } as Order;

    public lastOrders = [
        ['es', 'en', 'de', 'sq'],
        ['en', 'fr', 'tr'],
        ['en', 'zh-TW', 'zh-Latn', 'zh-HK'],
        ['en', 'pt', 'ur', 'fr'],
        ['en', 'fr', 'ga'],
        ['is', 'en'],
        ['fr', 'ro', 'hu', 'de'],
        ['fr', 'it', 'ti-new'],
        ['hu', 'en', 'sv'],
        ['fi', 'nl', 'no', 'en'],
        ['sq', 'fr', 'de', 'en'],
        ['en', 'ga', 'fr'],
        ['en', 'nl', 'mt', 'fr'],
        ['fr', 'es', 'ar', 'en'],
        ['fr', 'lo', 'en', 'es'],
        ['hy', 'sv'],
        ['en', 'zh-Latn', 'es', 'fr'],
        ['en', 'hr', 'sk'],
        ['en', 'fr', 'es', 'el'],
        ['en', 'hi', 'zh', 'fr'],
        ['fr', 'mg', 'en'],
        ['de', 'en', 'sk', 'fr'],
        ['en', 'fr', 'no'],
        ['en', 'de', 'ja'],
        ['de', 'en', 'it', 'tl'],
        ['en', 'nl', 'el'],
        ['en', 'de', 'is', 'ga'],
        ['de', 'ca', 'fa'],
        ['fr', 'en', 'ar', 'id'],
        ['en', 'sv', 'he'],
        ['ar', 'fr', 'en', 'es'],
        ['fr', 'ru', 'de'],
        ['en', 'ar', 'fr'],
        ['en', 'sv', 'fr', 'ru'],
        ['de', 'tr', 'en'],
        ['en', 'fr', 'zh-TW'],
        ['es', 'de', 'en'],
        ['fr', 'ro', 'en'],
        ['en', 'es', 'fr', 'de'],
        ['pl', 'de', 'en'],
        ['fr', 'nl', 'ar'],
        ['zh-TW', 'zh', 'en', 'fr'],
        ['en', 'fr', 'yo'],
        ['en', 'sv', 'lt', 'ar'],
        ['en', 'ru', 'nl', 'zh'],
        ['fr', 'sr', 'en'],
        ['en', 'sq'],
        ['en', 'ru', 'nl', 'zh'],
        ['fr', 'ar', 'en', 'de'],
        ['fr', 'ar', 'en'],
        ['en', 'ja', 'es', 'fr'],
        ['de', 'fr', 'en', 'sm'],
        ['en', 'fr', 'sm']
    ];

    public static buildPagesForBook(illustrations: Illustration[]) {
        const pages = [];

        let pageCounter = 0;
        const illustrationLength = Math.ceil(illustrations.length);

        pages.push({
            id: pageCounter,
            zIndex: illustrationLength - pageCounter,
            className: '',
            pageNum: 0,
            illustrationTop: null,
            illustrationBottom: illustrations[0]
        });
        pageCounter++;

        // Add one blank page and one page containing the custom message with the languages
        pages.push({
            id: pageCounter,
            zIndex: 0,
            className: '',
            pageNum: 0,
        });
        pageCounter++;
        pages.push({
            id: pageCounter,
            zIndex: illustrationLength - pageCounter,
            className: '',
            pageNum: 0,
        });
        pageCounter++;

        for (let i = 1; i < illustrations.length; i = i + 2) {
            const illustrationTop = illustrations[i];
            const illustrationBottom = illustrations[i + 1];
            let zIndex = 0;
            if (pageCounter % 2 === 0) {
                zIndex = illustrationLength - pageCounter;
            }
            pages.push({
                id: pageCounter,
                zIndex,
                className: '',
                pageNum: 0,
                illustrationTop,
                illustrationBottom
            });
            pageCounter++;
        }

        return pages;
    }

    constructor(private http: HttpClient,
                private translate: TranslateService) {
    }

    private initialABTesting() {
        this.abTestFeatures = Object.values(ABTestFeatureName).map(feature => {
            return {
                name: feature,
                active: false // TODO Math.random() < 0.5
            };
        });
    }

    public initialize() {
        this.setMomentLocales();
        i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
        i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
        this.loadInitialSettings();
        this.initialABTesting();
    }

    public getAllCountries() {
        if (!!i18nIsoCountries) {
            return sortBy(Object.entries(i18nIsoCountries.getNames(this.translate.currentLang)).map(([key, value]) => {
                const countryName = (typeof value === 'string') ? value : value[0];
                return {code: key, name: countryName};
            }), 'name');
        } else {
            return [];
        }
    }

    private setMomentLocales() {
        moment.locale('fr', {
            months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
            weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
            relativeTime: {
                future: 'dans %s',
                past: 'il y a %s',
                s: 'quelques secondes',
                m: 'une minute',
                mm: '%d minutes',
                h: 'une heure',
                hh: '%d heures',
                d: 'un jour',
                dd: '%d jours',
                M: 'un mois',
                MM: '%d mois',
                y: 'un an',
                yy: '%d ans'
            }
        });
    }

    public loadNotifications(): Observable<NotificationDefinitions> {
        return this.http.get<NotificationDefinitions>(`${this.notificationsUri}`);
    }

    public loadSettings(currency: string): Observable<Settings> {
        if (this.settingsMap.has(currency)) {
            this.settings = this.settingsMap.get(currency);
            this.refCurrency = currency;
            this.settingsSubject$.next(this.settings);
            return of(this.settingsMap.get(currency));
        }
        return this.http.get(`${this.settingsUri}/${currency}`).pipe(
            map((data: any) => {
                return {
                    unitPrice: data.unitPrice,
                    unitPriceTwoBooks: data.unitPriceTwoBooks,
                    maxLanguages: data.maxLanguages,
                    refCurrency: data.refCurrency,
                    hardCoverPrice: data.hardCoverPrice,
                    giftPrice: data.giftPrice,
                    multiBookOfferActive: data.multiBookOfferActive,
                    localDate: data.localDate,
                    supportedCountryList: data.supportedCountryList,
                    supportedThemeList: data.supportedThemeList,
                    supportedCurrencyList: data.supportedCurrencyList,
                    supportedLanguageList: new Map(data.supportedLanguageList.map(lang =>
                        [lang, {name: ISO6391.getName(lang), nativeName: LanguageHelper.getNativeName(lang)}])),
                    supportedCoverPaperCategories: data.supportedCoverPaperCategories,
                    supportedShippingMethods: data.supportedShippingMethods,
                    ambassadorPromotion: data.ambassadorPromotion
                } as Settings;
            }),
            tap(settings => {
                this.settingsMap.set(currency, settings);
                this.settings = settings;
                this.refCurrency = currency;
                this.settingsSubject$.next(settings);
            })
        );
    }

    public getShippingCountry() {
        return this.currentOder.shipping?.contactAddress?.country;
    }

    public setShippingCountry(country: Country) {
        return this.currentOder.shipping.contactAddress.country = country;
    }

    public saveItems(items: ItemOrder[]) {
        this.currentOder.content.items = items;
    }

    public getSavedItems() {
        return this.currentOder.content.items;
    }

    public addSavedItem(item: ItemOrder) {
        this.currentOder.content.items.push(item);
    }

    public removeSavedItem(item: ItemOrder) {
        const indexToRemove = this.currentOder.content.items.findIndex(i => i.id === item.id);
        this.currentOder.content.items.splice(indexToRemove, 1);
    }

    private adaptBooks(availableBooks: any[]): ItemOrder[] {
        return availableBooks.map(availableBook => {
            const availableTheme = availableBook.availableTheme;
            const theme = {id: availableTheme.id, translations: availableTheme.translations};
            const illustrations: Illustration[] = availableBook.availableIllustrations.map(i => {
                return {
                    id: i.id,
                    image: i.image,
                    translations: []
                };
            });
            const lang = this.getCurrentLangForBooks();
            return {
                id: uuid.v4(),
                duplicates: 1,
                theme,
                locales: [lang],
                coverPaperCategory: PaperCategory.SOFT_COVER,
                kidsName: '',
                pages: [],
                loading: false,
                illustrations,
            } as ItemOrder;
        });
    }

    public getCurrentLangForBooks() {
        const currentLang = this.translate.currentLang;
        return currentLang ? (currentLang === 'en' ? 'en_GB' : this.translate.currentLang) : 'en_GB';
    }

    // Loads available books in the system, along with the user's country of visit
    private loadInitialSettings() {
        const availableBooksCall$: Observable<any> = this.http.get(`${this.availableBooksUri}`);

        this.loadSettings(this.refCurrency).pipe(
            switchMap(() => availableBooksCall$)
        ).subscribe(result => {
            const availableBooks = result && result.books?.length ? result.books : [];
            this.availableBooks$.next(this.adaptBooks(availableBooks));
        });
    }

    public isABTestFeatureActive(feature: string) {
        return this.abTestFeatures.find(f => f.name === feature)?.active;
    }
}
