import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ItemOrder} from '../../../models/item-order.model';
import {SessionDataServiceDirective} from '../../../services/session-data-service.directive';
import {OrderService} from '../../../services/order.service';
import {ItemCalculation} from '../../../models/item-calculation.model';
import {DetailedOrderCalculationModel} from '../../../models/detailed-order-calculation.model';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ShippingMethod} from '../order.helper';
import {first, switchMap, tap} from 'rxjs/operators';
import {Country} from '../../../models/country.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GASettings} from '../../../models/ga-settings.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {DialogService} from '../../../services/dialog.service';
import {SelectedReviewLanguage} from '../../../models/review-language.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {ItemsHelper} from '../../../helpers/items.helper';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('.5s ease-out', style({opacity: '1'})),
            ]),
            transition(':leave', [
                style({opacity: '1'}),
                animate('.5s ease-out', style({opacity: '0'})),
            ])
        ]),
    ]
})
export class OrderDetailsComponent implements OnInit, OnDestroy {

    private _shippingCountry: Country;
    private languageSubscription: Subscription;
    private couponSubscription: Subscription;
    private settingsSubscription: Subscription;
    private currencySubscription: Subscription;
    private dialogSubscription: Subscription;
    private ambassadorSubscription: Subscription;
    private translateValidationSubscription: Subscription;
    private calculationSubscription: Subscription;
    private currencyChanged$ = new Subject<string>();

    public stripeCoupon = '';
    public loading = false;
    public detailedOrderCalculation: DetailedOrderCalculationModel;
    public itemsCalculations: Map<string, ItemCalculation> = new Map<string, ItemCalculation>();
    public currency: string;
    public currentLanguage: string;
    public totalPromotion = 0;
    public selectedReviewLanguage: SelectedReviewLanguage;
    public supportedCurrencyList: string[];
    public nbBooksInOrder: number;
    public isGift = false;
    public isExpressDelivery = false;
    public greetings: string;
    public giftPrice: number;
    public showCustomTaxMessage = false;

    private static isShippingWithCustoms(country: string) {
        if (!country) {
            return true;
        }
        return country.toLowerCase() !== 'gb' && country.toLowerCase() !== 'au' && country.toLowerCase() !== 'us';
    }

    get shippingCountry(): Country {
        return this._shippingCountry;
    }

    @Input()
    set shippingCountry(country: Country) {
        this._shippingCountry = country;
        this.sessionDataService.setShippingCountry(country);
        this.showCustomTaxMessage = OrderDetailsComponent.isShippingWithCustoms(country?.code);
        if (this.currency) {
            this.calculatePrice();
        }
    }

    @Input()
    items: ItemOrder[];

    @Output()
    calculationDone: EventEmitter<DetailedOrderCalculationModel> = new EventEmitter<DetailedOrderCalculationModel>();

    @Output()
    shippingMethod: EventEmitter<ShippingMethod> = new EventEmitter<ShippingMethod>();

    @Output()
    greetingsSet: EventEmitter<string> = new EventEmitter<string>();

    constructor(private sessionDataService: SessionDataServiceDirective,
                private orderService: OrderService,
                private translate: TranslateService,
                private router: Router,
                private snackBar: MatSnackBar,
                private dialogService: DialogService,
                private gaService: GoogleAnalyticsService,) {
    }

    ngOnInit() {
        this.currency = this.sessionDataService.refCurrency;
        this.isGift = this.sessionDataService.currentOder.content.isGift;
        this.isExpressDelivery = this.sessionDataService.currentOder.shipping.isExpressDelivery;
        this.greetings = this.sessionDataService.currentOder.content.greetings;
        this.stripeCoupon = this.sessionDataService.currentOder.content.coupon;
        this.currentLanguage = this.translate.currentLang;
        this.showCustomTaxMessage = OrderDetailsComponent.isShippingWithCustoms(this.shippingCountry?.code);

        this.settingsSubscription = this.sessionDataService.settingsSubject$
            .subscribe(() => {
                this.giftPrice = this.sessionDataService.settings.giftPrice;
                this.supportedCurrencyList = this.sessionDataService.settings?.supportedCurrencyList;
                this.currency = this.sessionDataService.refCurrency;
                this.calculatePrice();
            });

        this.currencySubscription = this.currencyChanged$.pipe(
            switchMap(currency => this.sessionDataService.loadSettings(currency)),
        ).subscribe(() => {
            this.currency = this.sessionDataService.refCurrency;
        });

        this.languageSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLanguage = this.translate.currentLang;
        });

        this.couponSubscription = this.sessionDataService.useCouponSubject$.subscribe(coupon => {
            this.stripeCoupon = coupon;
            this.calculatePrice();
        });
    }

    setCurrency(curr: string) {
        this.currencyChanged$.next(curr);
    }

    calculatePrice(appliedCoupon?: string) {
        if (!this.shippingCountry) {
            return;
        }

        this.calculationSubscription?.unsubscribe();
        this.calculationSubscription = this.orderService.calculatePrice(this.currency, this.isExpressDelivery)
            .pipe(
                first(),
                tap(() => this.loading = true)
            )
            .subscribe((data) => {
                // Fill in the itemsCalculations which is used to easily access prices from the html
                data.itemsCalculationPriceOrderList.forEach(i => {
                    this.itemsCalculations.set(i.id, new ItemCalculation(i.id, i.theme, i.totalItemsPrice, i.discountedItemsPrice));
                });

                // Set the detailedOrderCalculation used to display prices in the html
                this.detailedOrderCalculation = data;
                this.totalPromotion = data.totalOrderPrice.coupons.reduce((sum, coupon) => {
                    return (coupon.applied ? coupon.percentOff : 0) + sum;
                }, 0);

                this.nbBooksInOrder = ItemsHelper.getNbDuplicates(this.items);
                this.checkCoupons(data);

                // Manage the shipping
                this.loading = false;
                this.calculationDone.emit(data);
            }, (err) => {
                this.loading = false;
            });
    }

    private checkCoupons(data: DetailedOrderCalculationModel) {
        const stripeCoupon = data.totalOrderPrice.coupons.find(c => c.name.trim() === this.stripeCoupon.trim());

        if (this.stripeCoupon && !stripeCoupon?.applied) {

            this.stripeCoupon = '';
            this.snackBar.open(this.translate.instant('order.errors.coupon-not-found'),
                this.translate.instant('common.close'), {
                    duration: 5000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
        } else {
            this.sessionDataService.currentOder.content.coupon = this.stripeCoupon;
        }
    }

    getLanguages(locales: string[]): string {
        return locales.map(locale => this.translate.instant('languages.' + locale)).join(', ');
    }

    editBook(index: number) {
        this.sessionDataService.editingItem = this.sessionDataService.currentOder.content.items[index];
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'back_to_create');
        this.router.navigate(['/create']);
    }

    setCoverType(item: ItemOrder) {
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT,
            'set_' + item.coverPaperCategory.valueOf().toLowerCase());

        this.calculatePrice();
        this.sessionDataService.itemsChanged$.next();
    }

    addDuplicate(item: ItemOrder) {
        item.duplicates = item.duplicates + 1;
        this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, 'add_duplicate', item.duplicates);
        this.sessionDataService.itemsChanged$.next();
        this.calculatePrice();
    }

    removeDuplicate(item: ItemOrder) {
        item.duplicates = item.duplicates - 1;
        this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, 'remove_duplicate', item.duplicates);
        this.calculatePrice();

        if (item.duplicates === 0) {
            this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'remove_book');
            const indexToRemove = this.sessionDataService.currentOder.content.items.findIndex(i => i.id === item.id);
            this.sessionDataService.currentOder.content.items.splice(indexToRemove, 1);
        }

        this.sessionDataService.itemsChanged$.next();
        this.calculatePrice();
    }

    getBookName(item: ItemOrder) {
        return item?.theme?.translations && this.currentLanguage ? item.theme.translations[this.currentLanguage] : '';
    }

    getExpectedDate(leadTime: number): string {
        const today = moment();
        const deliveryDate = today.add(leadTime, 'days');
        return deliveryDate.format('Do MMMM, YYYY');
    }

    backToEdition() {
        this.sessionDataService.editingItem = null;
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'back_to_create');
        this.router.navigate(['/create']);
    }

    ngOnDestroy(): void {
        this.languageSubscription?.unsubscribe();
        this.settingsSubscription?.unsubscribe();
        this.dialogSubscription?.unsubscribe();
        this.couponSubscription?.unsubscribe();
        this.ambassadorSubscription?.unsubscribe();
        this.calculationSubscription?.unsubscribe();
        this.currencySubscription?.unsubscribe();
        this.translateValidationSubscription?.unsubscribe();
    }

    applyCoupon() {
        this.gaService.event(GASettings.ACTIONS.CHECKOUT_PROGRESS, GASettings.CATEGORIES.ECOMMERCE, 'apply_coupon');
        this.sessionDataService.currentOder.content.coupon = this.stripeCoupon;
        this.calculatePrice();
    }

    setGift() {
        this.sessionDataService.currentOder.content.isGift = this.isGift;
        this.calculatePrice();
        this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, this.isGift ? 'setting_gift' : 'removing_gift');
    }

    setExpressDelivery() {
        this.sessionDataService.currentOder.shipping.isExpressDelivery = this.isExpressDelivery;
        this.calculatePrice();
        this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, this.isExpressDelivery ? 'setting_express_delivery' : 'removing_express_delivery');
    }

    openGiftPreview() {
        this.dialogService.openWrapPreview(ItemsHelper.getAllLanguages(this.items))
            .afterClosed()
            .pipe(first())
            .subscribe(() => {
                this.calculatePrice();
            });
    }

    editGreetings() {
        this.dialogService.openGreetingsEditor(this.greetings)
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                this.greetings = result;
                this.sessionDataService.currentOder.content.greetings = result;
                if (result?.length) {
                    this.gaService.event(GASettings.ACTIONS.SET_CHECKOUT_OPTION, GASettings.CATEGORIES.ECOMMERCE, 'setting_greetings');
                }
            });
    }
}
