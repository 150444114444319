import {NgModule} from '@angular/core';
import {BaseModule} from '../../base.module';
import {FooterComponent} from './footer.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        BaseModule,
        RouterModule.forChild([]),
    ],
    exports: [
        FooterComponent
    ],
    providers: []
})

export class FooterModule {
}
