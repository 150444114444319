import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FilterPipe} from '../../directives/filter.pipe';
import {MainModule} from '../main/main.module';
import {BaseModule} from '../../base.module';
import {CreateComponent} from './create.component';
import {LanguageSelectorComponent} from './language-selector/language-selector.component';
import {ShippingSelectorComponent} from './shipping-selector/shipping-selector.component';
import {LegalModule} from '../legal/legal.module';
import {SuperheroNotificationComponent} from './superhero-notification/superhero-notification.component';
import {BasketDialogComponent} from './basket-dialog/basket-dialog.component';
import {CreatePreviewComponent} from './create-preview/create-preview.component';
import {AutoFocusDirective} from '../../directives/auto-focus.directive';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    declarations: [
        CreateComponent,
        LanguageSelectorComponent,
        ShippingSelectorComponent,
        FilterPipe,
        AutoFocusDirective,
        SuperheroNotificationComponent,
        BasketDialogComponent,
        CreatePreviewComponent,
    ],
    imports: [
        LegalModule,
        BaseModule,
        RouterModule.forChild([]),
        MainModule,
        SharedModule,
    ],
    exports: [
        CreateComponent, ShippingSelectorComponent, SuperheroNotificationComponent, BasketDialogComponent
    ],
    providers: []
})


export class CreateModule {
}
