import {Injectable} from '@angular/core';
import {Order} from '../models/order.model';
import {ItemOrder} from '../models/item-order.model';
import {map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {DetailedOrderCalculationModel, DetailedOrderCalculationModelAdapter} from '../models/detailed-order-calculation.model';
import {SessionDataServiceDirective} from './session-data-service.directive';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    order: Order;
    private orderPriceUri = `${environment.apiUrl}/order/price`;
    private orderUri = `${environment.apiUrl}/v1/order`;

    constructor(private http: HttpClient,
                private sessionDataService: SessionDataServiceDirective,
                private detailedOrderCalculationModelAdapter: DetailedOrderCalculationModelAdapter) {
    }

    getOrder() {
        return this.order;
    }

    calculatePrice(currency: string, isExpressDelivery?: boolean): Observable<DetailedOrderCalculationModel> {
        const itemOrders = this.sessionDataService.currentOder.content.items;
        const coupon = this.sessionDataService.currentOder.content.coupon;
        const isGift = this.sessionDataService.currentOder.content.isGift;
        const localDate = this.sessionDataService.settings.localDate;
        const country = this.sessionDataService.getShippingCountry()?.code?.toLocaleLowerCase();

        return this.http.post(`${this.orderPriceUri}`, {
            itemOrderList: itemOrders.map(item => {
                return {
                    id: item.id,
                    duplicates: item.duplicates,
                    theme: (item.theme as any).id,
                    locales: item.locales,
                    coverPaperCategory: item.coverPaperCategory
                };
            }),
            timestamp: localDate,
            currencyId: currency,
            countryCode: country,
            coupon,
            isGift,
            isExpressDelivery
        }).pipe(
            map((data: any) => {
                return this.detailedOrderCalculationModelAdapter.adapt(data.value);
            }),
            tap(data => {
                this.sessionDataService.priceChanged$.next(data);
            })
        );
    }


    createIntent(order: Order): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        return this.http.post(`${this.orderUri}`, order, {headers});
    }
}
