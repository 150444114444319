import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Settings} from '../../models/settings.model';
import {SessionDataServiceDirective} from '../../services/session-data-service.directive';
import {OrderService} from '../../services/order.service';
import {Order} from '../../models/order.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, interval, NEVER, of, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ItemOrder} from '../../models/item-order.model';
import {DialogService} from '../../services/dialog.service';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {TranslateService} from '@ngx-translate/core';
import {OrderHelper} from './order.helper';
import {DetailedOrderCalculationModel} from '../../models/detailed-order-calculation.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmCardPaymentData, CreatePaymentMethodCardData, StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {Country} from '../../models/country.model';
import * as i18nIsoCountries from 'i18n-iso-countries';
import {sortBy} from 'lodash';
import {filter, first, startWith, switchMap} from 'rxjs/operators';
import {GASettings} from '../../models/ga-settings.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {animate, style, transition, trigger} from '@angular/animations';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {PixelService} from 'ngx-pixel';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    animations: [
        trigger('appear', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
            ])
        ]),
    ]
})
export class OrderComponent implements OnInit, OnDestroy {

    private settingsSubscription: Subscription;
    private validatorsSubscription: Subscription;
    private paymentSubscription: Subscription;
    private loadingSubscription: Subscription;
    private settings: Settings;
    private order: Order;
    private calculation: DetailedOrderCalculationModel;
    private loadingCounterMessages = 0;
    private loadingPlaying = new BehaviorSubject(false);
    private loadingMessages = [
        'order.loading.initial',
        'order.loading.submitting-request',
        'order.loading.contacting-payment',
        'order.loading.requesting-payment',
        'order.loading.sending-confirmation-email'
    ];

    public currentLanguage: string;
    public orderForm: FormGroup;
    public formSubmitted = false;
    public items: ItemOrder[] = [];
    public shippingCountry: Country;
    public contactCountry: Country;
    public allCountries: Country[];
    public foundStripe = true;
    public loadingMessage = '';
    public elementsOptions: StripeElementsOptions;
    public cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: '#03A9F4',
                color: 'black',
                lineHeight: '50px',
                fontFamily: '"Roboto, Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#9E9E9E'
                }
            }
        }
    };

    @ViewChild(StripeCardComponent)
    card: StripeCardComponent;

    constructor(private sessionDataService: SessionDataServiceDirective,
                private orderService: OrderService,
                private router: Router,
                private dialogService: DialogService,
                private stripeService: StripeService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private snackBar: MatSnackBar,
                private recaptchaV3Service: ReCaptchaV3Service,
                private gaService: GoogleAnalyticsService,
                private pixel: PixelService) {
        this.currentLanguage = this.translate.currentLang;
        this.elementsOptions = {
            locale: this.currentLanguage
        } as StripeElementsOptions;

        this.orderForm = new FormGroup({
            contactFirstName: new FormControl(''),
            contactLastName: new FormControl(''),
            contactCompanyName: new FormControl(''),
            contactEmail: new FormControl('',
                [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
            contactAddress: new FormControl(''),
            contactCity: new FormControl(''),
            contactPostalCode: new FormControl(''),
            contactRegion: new FormControl(''),
            contactComplimentaryAddress: new FormControl(''),
            shippingAddress: new FormControl('', Validators.required),
            shippingPhone: new FormControl('', Validators.required),
            shippingLastName: new FormControl('', Validators.required),
            shippingFirstName: new FormControl('', Validators.required),
            shippingCompanyName: new FormControl(''),
            shippingCity: new FormControl('', Validators.required),
            shippingPostalCode: new FormControl('', Validators.required),
            shippingRegion: new FormControl(''),
            shippingComplimentaryAddress: new FormControl(''),
            coupon: new FormControl(this.sessionDataService.currentOder.content.coupon ?? null),
            paymentFullName: new FormControl('', Validators.required),
            sameAsShipping: new FormControl(true),
            agreeToTerms: new FormControl(false),
        });

        this.setAddressValidators();
    }

    ngOnInit(): void {
        this.pixel.track('InitiateCheckout');
        this.gaService.event(GASettings.ACTIONS.BEGIN_CHECKOUT, GASettings.CATEGORIES.ECOMMERCE, 'order_from_create');

        this.loadingSubscription = this.loadingPlaying.pipe(
            switchMap(e => !!e ? interval(2500).pipe(startWith('start')) : NEVER)
        ).subscribe(e => this.runLoadingMessages());

        this.allCountries = sortBy(Object.entries(i18nIsoCountries.getNames(this.translate.currentLang))
            .map(([key, value]) => {
                const countryName = (typeof value === 'string') ? value : value[0];
                return {code: key, name: countryName};
            }), 'name');

        if (this.sessionDataService.getShippingCountry()) {
            this.shippingCountry = this.allCountries.find(c => c.code === this.sessionDataService.getShippingCountry().code);
        }

        this.order = this.orderService.getOrder();
        this.items = this.sessionDataService.getSavedItems();

        // Return to create component if no order is found
        if (!this.items.length || !this.shippingCountry) {
            this.router.navigate(['/create']);
            return;
        }

        this.settingsSubscription = this.sessionDataService.settingsSubject$
            .pipe(
                filter(settings => !!settings)
            ).subscribe(settings => {
                this.settings = settings;
            });

        const element = this;
        setTimeout(() => {
            if (element.card === undefined) {
                element.foundStripe = false;
            }
        }, 5000);
    }

    openTos() {
        this.dialogService.openTos().afterClosed().subscribe(result => {
            this.gaService.event(GASettings.ACTIONS.CHECKOUT_PROGRESS, GASettings.CATEGORIES.ECOMMERCE, 'agree_tos');
            this.orderForm.get('agreeToTerms').patchValue(result);
        });
    }

    isFormValid(sameAsShipping: boolean, agreeToTerms) {
        return OrderHelper.areCountriesValid(sameAsShipping, this.shippingCountry, this.contactCountry) &&
            agreeToTerms &&
            this.orderForm.valid &&
            (this.orderForm.touched || this.orderForm.dirty);
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.items?.length) {
            this.snackBar.open(this.translate.instant('order.errors.no-book-selected'),
                this.translate.instant('common.close'), {
                    duration: 10000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
            return;
        }

        if (!this.shippingCountry) {
            this.snackBar.open(this.translate.instant('order.errors.missing-country-shipping'),
                this.translate.instant('common.close'), {
                    duration: 10000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
            return;
        }

        const sameAsShipping = this.orderForm.get('sameAsShipping').value;
        if (!sameAsShipping && !this.contactCountry) {
            this.snackBar.open(this.translate.instant('order.errors.missing-country-contact'),
                this.translate.instant('common.close'), {
                    duration: 10000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
            return;
        }

        if (!this.isFormValid(sameAsShipping, this.orderForm.get('agreeToTerms').value)) {
            this.handleError('order.errors.form-error');
            return;
        }

        this.proceedToOrder();
    }

    setAddressValidators() {
        const contactFirstName = this.orderForm.get('contactFirstName');
        const contactLastName = this.orderForm.get('contactLastName');
        const contactAddress = this.orderForm.get('contactAddress');
        const contactCity = this.orderForm.get('contactCity');
        const contactPostalCode = this.orderForm.get('contactPostalCode');
        const contactPhone = this.orderForm.get('contactPhone');

        this.validatorsSubscription = this.orderForm.get('sameAsShipping').valueChanges
            .subscribe(sameAsShipping => {
                if (sameAsShipping) {
                    contactFirstName.setValidators(null);
                    contactLastName.setValidators(null);
                    contactAddress.setValidators(null);
                    contactCity.setValidators(null);
                    contactPostalCode.setValidators(null);
                } else {
                    contactFirstName.setValidators([Validators.required]);
                    contactLastName.setValidators([Validators.required]);
                    contactAddress.setValidators([Validators.required]);
                    contactCity.setValidators([Validators.required]);
                    contactPostalCode.setValidators([Validators.required]);
                    this.initContactAddress();
                }
                contactFirstName.updateValueAndValidity();
                contactLastName.updateValueAndValidity();
                contactAddress.updateValueAndValidity();
                contactCity.updateValueAndValidity();
                contactPostalCode.updateValueAndValidity();
            });
    }

    initContactAddress() {
        this.orderForm.patchValue({
            contactLastName: '',
            contactFirstName: '',
            contactCompanyName: '',
            contactAddress: '',
            contactComplimentaryAddress: '',
            contactCity: '',
            contactRegion: '',
            contactPostalCode: '',
            contactPhone: ''
        });
        this.contactCountry = null;
    }

    setCalculation(detailedOrderCalculationModel: DetailedOrderCalculationModel) {
        this.calculation = detailedOrderCalculationModel;
    }

    private proceedToOrder() {
        this.formSubmitted = true;
        this.loadingPlaying.next(true);

        const order: Order = OrderHelper.buildOrder(this.orderForm.getRawValue(), this.items, this.calculation,
            this.settings.refCurrency, this.settings.localDate, this.shippingCountry, this.contactCountry,
            this.translate.currentLang, this.sessionDataService.currentOder.content.isGift,
            this.sessionDataService.currentOder.shipping.isExpressDelivery,
            this.sessionDataService.currentOder.content.greetings);

        this.gaService.event(GASettings.ACTIONS.CHECKOUT_PROGRESS, GASettings.CATEGORIES.ECOMMERCE, 'proceeding_to_order');

        this.paymentSubscription?.unsubscribe();
        this.paymentSubscription = this.orderService.createIntent(order)
            .pipe(
                first(),
            ).subscribe((result: any) => {
                if (result && result.id && result.intent) {
                    this.stripeService.confirmCardPayment(result.intent as string,
                        {
                            payment_method: {
                                card: this.card.element,
                                billing_details: {
                                    name: this.orderForm.get('paymentFullName').value
                                }
                            } as CreatePaymentMethodCardData
                        } as ConfirmCardPaymentData).subscribe((stripeResult) => {
                        if (stripeResult.paymentIntent && stripeResult.paymentIntent.status === 'succeeded') {
                            this.sessionDataService.orderConfirmation = {
                                ref: result.id,
                                order
                            };
                            this.logTimeSpentOnPage();
                            this.loadingPlaying.next(false);
                            this.router.navigate(['confirmation']);
                            this.formSubmitted = false;
                        } else {
                            this.gaService.event(GASettings.ACTIONS.CHECKOUT_PROGRESS, GASettings.CATEGORIES.ECOMMERCE, 'order_error_payment_not_succeeded');
                            this.handleError('order.errors.general-error', stripeResult.error.message);
                        }
                    });
                } else if (result && result.status === 'ERROR') {
                    this.gaService.event(GASettings.ACTIONS.CHECKOUT_PROGRESS, GASettings.CATEGORIES.ECOMMERCE, 'order_error_intent_not_created_' + result.message);
                    this.handleError('order.errors.invalid-order', result.message);
                } else {
                    this.gaService.event(GASettings.ACTIONS.CHECKOUT_PROGRESS, GASettings.CATEGORIES.ECOMMERCE, 'order_error_no_result_found');
                    this.loadingPlaying.next(false);
                }
            });
    }

    logTimeSpentOnPage() {
        this.gaService.event(GASettings.ACTIONS.PURCHASE, GASettings.CATEGORIES.ECOMMERCE, 'order_success');
        this.pixel.track('Purchase', {currency: this.settings.refCurrency as any, value: this.calculation.totalOrderPrice.total / 100});
    }

    runLoadingMessages() {
        if (this.loadingCounterMessages < this.loadingMessages.length) {
            this.loadingMessage = this.loadingMessages[this.loadingCounterMessages];
            this.loadingCounterMessages++;
        } else {
            this.loadingPlaying.next(false);
        }
        return of(null);
    }

    handleError(msgKey: string, more?: string) {
        this.loadingCounterMessages = 0;
        this.loadingPlaying.next(false);
        this.formSubmitted = false;
        const moreMsg = more ? ': ' + more : '';
        this.snackBar.open(this.translate.instant(msgKey) + moreMsg, this.translate.instant('common.close'),
            {
                duration: 10000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
            });
    }

    ngOnDestroy(): void {
        this.settingsSubscription?.unsubscribe();
        this.validatorsSubscription?.unsubscribe();
        this.paymentSubscription?.unsubscribe();
        this.loadingSubscription?.unsubscribe();
    }

    setFocusIn(value: string) {
        this.gaService.event(GASettings.ACTIONS.CHECKOUT_PROGRESS, GASettings.CATEGORIES.ECOMMERCE, 'order-entering-' + value);
    }
}
