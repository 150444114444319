import {TosDialogComponent} from './tos-dialog/tos-dialog.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {NgModule} from '@angular/core';
import {OrderComponent} from './order.component';
import {BaseModule} from '../../base.module';
import {OrderRoutingModule} from './order-routing.module';
import {RouterModule} from '@angular/router';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../../../environments/environment';
import {ShippingConfirmationComponent} from './shipping-confirmation/shipping-confirmation.component';
import {LegalModule} from '../legal/legal.module';
import {SharedModule} from '../shared/shared.module';
import {AmbassadorDialogComponent} from './ambassador-dialog/ambassador-dialog.component';
import { GreetingsComponent } from './greetings/greetings.component';
import { WrapPreviewComponent } from './wrap-preview/wrap-preview.component';

@NgModule({
    declarations: [
        OrderComponent,
        TosDialogComponent,
        OrderDetailsComponent,
        ConfirmationComponent,
        ShippingConfirmationComponent,
        AmbassadorDialogComponent,
        GreetingsComponent,
        WrapPreviewComponent,
    ],
    imports: [
        OrderRoutingModule,
        BaseModule,
        LegalModule,
        SharedModule,
        RouterModule.forChild([]),
        NgxStripeModule.forRoot(environment.stripe),
    ],
    exports: [
        OrderComponent,
    ],
    providers: []
})


export class OrderModule {
}
