export class GASettings {

    public static ACTIONS = {
        VIEW_ITEM: 'view_item',
        VIEW_PAGE: 'view_page',
        CUSTOMIZE_ITEM: 'customize_item',
        ADD_TO_CARD: 'add_to_cart',
        BEGIN_CHECKOUT: 'begin_checkout',
        SET_CHECKOUT_OPTION: 'set_checkout_option',
        CHECKOUT_PROGRESS: 'checkout_progress',
        PURCHASE: 'purchase',
        SHARE: 'share',
        STAY: 'stay',
        VALIDATE_TRANSLATION: 'validate_translation',
        VIEW_FAQ: 'view_faq',
        FEEDBACK: 'feedback',
        REMINDER: 'reminder',
        REMINDER_AFTER_MIGRATION: 'reminder_after_migration'
    };

    public static CATEGORIES = {
        ENGAGEMENT: 'engagement',
        ECOMMERCE: 'ecommerce',
        FEEDBACK: 'feedback'
    };
}
