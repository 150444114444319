import {Component, Inject, OnInit} from '@angular/core';
import {SelectedReviewLanguage} from '../../../models/review-language.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import * as cloneDeep from 'lodash/cloneDeep';

export class AmbassadorDialogParams {
    selectedReviewLanguage: SelectedReviewLanguage;
}

@Component({
    selector: 'app-ambassador-dialog',
    templateUrl: './ambassador-dialog.component.html',
    styleUrls: ['./ambassador-dialog.component.scss']
})
export class AmbassadorDialogComponent implements OnInit {

    public originalTranslations: Map<string, string> = new Map<string, string>();
    public selectedReviewLanguage: SelectedReviewLanguage;
    public currentLanguage: string;

    constructor(@Inject(MAT_DIALOG_DATA) public params: AmbassadorDialogParams,
                public dialogRef: MatDialogRef<AmbassadorDialogComponent>,
                private translate: TranslateService,) {
    }

    ngOnInit(): void {
        this.currentLanguage = this.translate.currentLang;
        this.selectedReviewLanguage = cloneDeep(this.params.selectedReviewLanguage);
        if (this.selectedReviewLanguage?.translations) {
            this.originalTranslations = new Map(cloneDeep(this.selectedReviewLanguage.translations).map(i => [i.source, i.target]));
        }
    }

    close() {
        this.dialogRef.close(null);
    }

    applyPromotion() {
        this.selectedReviewLanguage.translations = this.selectedReviewLanguage.translations?.filter(translation => {
            return this.originalTranslations.get(translation.source) !== translation.target;
        });;
        this.dialogRef.close(this.selectedReviewLanguage);
    }

    getCorrections() {
        return this.selectedReviewLanguage.translations?.filter(translation => {
            return this.originalTranslations.get(translation.source) !== translation.target;
        })?.length;
    }

}
