import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {FaqComponent} from './faq/faq.component';
import {RefundPolicyComponent} from './refund-policy/refund-policy.component';

const routes: Routes =
    [
        { path: 'privacy-policy', component: PrivacyPolicyComponent},
        { path: 'refund-policy', component: RefundPolicyComponent},
        { path: 'terms-and-conditions', component: TermsAndConditionsComponent},
        { path: 'faq', component: FaqComponent},
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LegalRoutingModule {
}
