<div class="order-details-actions">
    <button class="back-edit-logo" mat-raised-button aria-label="Create" color="primary"
            (click)="backToEdition()"
            [attr.aria-label]="'order.back-to-edit' | translate"
            [matTooltip]="'order.back-to-edit' | translate">
        {{'order.edit-books' | translate}}
    </button>
</div>
<div class="order-details">
    <div class="order-details-title">
        <mat-icon>shopping_basket</mat-icon>
        <div *ngIf="detailedOrderCalculation else loader">{{'order.order-details.title' | translate}}</div>
        <ng-template #loader>
            <div>{{'common.loading' | translate}}</div>
        </ng-template>
    </div>

    <div class="item-details-wrapper" *ngFor="let item of items; let i = index;">
        <div class="item-details">
            <div class="item-details-description">
                <div class="theme-and-cover">
                    <div class="theme-img"><img [src]="'../../../assets/illustrations/' + item.pages[0].illustrationBottom.id + '.png'"></div>
                    <mat-form-field>
                        <mat-select [(ngModel)]="item.coverPaperCategory" (ngModelChange)="setCoverType(item)">
                            <mat-option [value]="'SOFT_COVER'">{{'common.SOFT_COVER' | translate}}</mat-option>
                            <mat-option [value]="'HARD_COVER'">{{'common.HARD_COVER' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="languages">
                    {{getLanguages(item.locales)}}
                    <span *ngIf="!item.kidsName">({{'order.order-details.no-dedication' | translate}})</span>
                    <span *ngIf="item.kidsName">({{'order.order-details.with-dedication' | translate}})</span>
                </div>
                <div class="change-duplicates">
                    <button mat-icon-button (click)="addDuplicate(item)"
                            [attr.aria-label]="'order.order-details.add-one' | translate"
                            [disabled]="item.duplicates >= 10"
                            [matTooltip]="'order.order-details.add-one' | translate">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                    <button mat-icon-button (click)="removeDuplicate(item)"
                            [matTooltip]="'order.order-details.remove-one' | translate"
                            [attr.aria-label]="'order.order-details.remove-one' | translate">
                        <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                    <span class="change-duplicates-quantity">{{'common.quantity' | translate}}: {{item.duplicates}}</span>
                </div>
                <button mat-button color="accent" (click)="editBook(i)"
                        [attr.aria-label]="'create.change-this-book' | translate">
                    <mat-icon>edit</mat-icon>
                    <span>{{'create.change-this-book' | translate}}</span>
                </button>
            </div>
            <div class="price" *ngIf="itemsCalculations?.get(item.id); else loader">
                <div class="total">
                    <span
                        [ngClass]="{'is-striked' : itemsCalculations.get(item.id)?.totalItemsPrice !== itemsCalculations.get(item.id)?.discountedItemsPrice}">{{itemsCalculations.get(item.id)?.totalItemsPrice / 100 | currency:currency}}</span><br>
                    <span
                        *ngIf="itemsCalculations.get(item.id)?.totalItemsPrice !== itemsCalculations.get(item.id)?.discountedItemsPrice">{{itemsCalculations.get(item.id)?.discountedItemsPrice / 100 | currency:currency}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="total-items-price">
        <div class="title">
            <span>{{'order.order-details.total-books' | translate}}</span>
            <span class="title-hint"
                  *ngIf="nbBooksInOrder !== 0 && detailedOrderCalculation?.totalItemsPrice != detailedOrderCalculation?.discountedItemsPrice">
                <mat-icon>local_offer</mat-icon>
                {{'order.order-details.offer-more-one-book' | translate : {value: nbBooksInOrder} }}
            </span>
        </div>
        <div class="discount" *ngIf="detailedOrderCalculation">
      <span
          [ngClass]="{'is-striked' : detailedOrderCalculation?.totalItemsPrice != detailedOrderCalculation?.discountedItemsPrice}">{{detailedOrderCalculation?.totalItemsPrice / 100 | currency:currency}}</span><br>
            <span
                *ngIf="detailedOrderCalculation?.totalItemsPrice != detailedOrderCalculation?.discountedItemsPrice">{{detailedOrderCalculation?.discountedItemsPrice / 100 | currency:currency}}</span>
        </div>
    </div>

    <div class="shipping-price" *ngIf="detailedOrderCalculation?.totalOrderPrice">
        <div class="title">
            <span class="shipping-details">
                <mat-icon [matTooltip]="'order.order-details.shipping-info' | translate">info</mat-icon>
                {{'common.shipping' | translate}}{{'basket.shipping-to' | translate : {country: shippingCountry?.name} }}
            </span>
            <span class="title-hint"><mat-icon>home</mat-icon>
                {{'confirmation.expected-delivery-date' | translate: {value: getExpectedDate(detailedOrderCalculation?.totalOrderPrice?.leadTime)} }}</span>
        </div>
        <div class="total">
            <div *ngIf="detailedOrderCalculation?.totalOrderPrice?.shippingPrice !== 0 && !detailedOrderCalculation?.totalOrderPrice?.shippingOffered">
                {{detailedOrderCalculation.totalOrderPrice.shippingPrice / 100 | currency: currency}}
            </div>
            <div style="color: red;"
                 *ngIf="detailedOrderCalculation?.totalOrderPrice?.shippingPrice === 0 && !detailedOrderCalculation?.totalOrderPrice?.shippingOffered">
                ?
            </div>
            <div *ngIf="detailedOrderCalculation?.totalOrderPrice?.shippingOffered">{{'common.free' | translate}}</div>
        </div>
    </div>

    <div class="gift-price">
        <div class="edit">
            <mat-checkbox class="edit-checkbox" [(ngModel)]="isGift" (change)="setGift()">{{'order.order-details.this-is-a-gift' | translate}}
                (+{{giftPrice / 100 | currency: currency}})
            </mat-checkbox>
            <!--            <button mat-button color="accent" (click)="openGiftPreview()" [attr.aria-label]="'order.order-details.edit-greetings' | translate">-->
            <!--                <mat-icon>edit</mat-icon>-->
            <!--                Preview-->
            <!--            </button>-->
            <button *ngIf="isGift" mat-button color="accent" (click)="editGreetings()" [attr.aria-label]="'order.order-details.edit-greetings' | translate">
                <mat-icon>edit</mat-icon>
                {{'order.order-details.edit-greetings' | translate}}
            </button>
        </div>
        <div class="total" *ngIf="isGift">
            <span>{{giftPrice / 100 | currency: currency}}</span>
        </div>
    </div>

<!--    <div class="gift-price">-->
<!--        <div class="edit">-->
<!--            <mat-checkbox class="edit-checkbox" [(ngModel)]="isExpressDelivery" (change)="setExpressDelivery()">Set express delivery option</mat-checkbox>-->
<!--        </div>-->
<!--    </div>-->

    <div class="final-price">
        <div class="final-price-title">
            <span>{{'order.order-details.total-order' | translate}}</span>
            <button class="select-currency-title" mat-button color="accent" [matMenuTriggerFor]="currencyMenu"
                    [attr.aria-label]="currency">
                <mat-icon>edit</mat-icon>
                <span>{{currency}}</span>
            </button>
            <mat-menu #currencyMenu="matMenu">
                <button mat-menu-item *ngFor="let curr of supportedCurrencyList"
                        [attr.aria-label]="curr" (click)="setCurrency(curr)">{{curr}}
                </button>
            </mat-menu>
        </div>

        <div class="total" *ngIf="!loading && detailedOrderCalculation?.totalOrderPrice">
            <span
                [ngClass]="{'is-discounted': detailedOrderCalculation.totalOrderPrice.totalWithoutPromotion !== detailedOrderCalculation.totalOrderPrice.total}">{{detailedOrderCalculation.totalOrderPrice.totalWithoutPromotion / 100 | currency: currency}}</span>
            <span
                *ngIf="detailedOrderCalculation.totalOrderPrice.totalWithoutPromotion !== detailedOrderCalculation.totalOrderPrice.total"><br/>{{detailedOrderCalculation.totalOrderPrice.total / 100 | currency: currency}}</span>
        </div>
        <div class="total" *ngIf="loading">
            <mat-progress-spinner diameter="30" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>

    <div class="coupon">
        <mat-form-field>
            <input matInput type="text" [(ngModel)]="stripeCoupon"
                   [placeholder]="'order.order-details.enter-coupon' | translate"/>
        </mat-form-field>
        <button *ngIf="stripeCoupon" mat-button color="accent" (click)="applyCoupon()" [attr.aria-label]="'order.order-details.apply-coupon' | translate">
            {{'order.order-details.apply-coupon' | translate}}
        </button>
    </div>

    <div class="disclaimer-price" *ngIf="totalPromotion !== 0">
        <mat-icon>done</mat-icon>
        <span>{{'order.order-details.coupon-applied' | translate: {value: totalPromotion} }}</span>
    </div>

    <div class="custom-tax" *ngIf="showCustomTaxMessage">
        {{'order.order-details.custom-tax-warning' | translate}}
    </div>
</div>
