import {NgModule} from '@angular/core';
import {BaseModule} from '../../base.module';
import {RouterModule} from '@angular/router';
import {CoverSelectorComponent} from './cover-selector/cover-selector.component';
import { ProcessOverviewComponent } from './process-overview/process-overview.component';
import { DiscountNotificationComponent } from './discount-notification/discount-notification.component';
import { ReminderComponent } from './reminder/reminder.component';

@NgModule({
    declarations: [
        CoverSelectorComponent,
        ProcessOverviewComponent,
        DiscountNotificationComponent,
        ReminderComponent,
    ],
    imports: [
        BaseModule,
        RouterModule.forChild([]),
    ],
    exports: [
        CoverSelectorComponent, ReminderComponent,
    ],
    providers: []
})


export class SharedModule {
}
