<div class="superhero-notification">

    <div class="title">
        <mat-icon>emoji_events</mat-icon>
        <div>{{'create.superhero-notification.title' | translate}}</div>
    </div>

    <div class="hint" [innerHtml]="'create.superhero-notification.hint' | translate: {value: languages}"></div>

    <div class="actions">
        <button mat-raised-button color="accent" (click)="close()" [attr.aria-label]="'create.superhero-notification.action' | translate">
            <span>{{'create.superhero-notification.action' | translate }}</span>
        </button>
    </div>
</div>



