<div class="contact-form">

    <form [formGroup]="contactForm" (ngSubmit)="onSubmit($event)" #myForm="ngForm">
        <mat-form-field>
            <mat-label>{{'common.name' | translate}}*</mat-label>
            <input matInput [placeholder]="'common.name' | translate" type="text" formControlName="name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'common.email' | translate}}*</mat-label>
            <input matInput [placeholder]="'common.email' | translate" type="email" formControlName="email">
        </mat-form-field>
        <mat-form-field class="md-block">
            <mat-label>{{'common.message' | translate}}*</mat-label>
            <textarea [placeholder]="'common.message' | translate" matInput
                      formControlName="message"></textarea>
        </mat-form-field>
        <div class="actions">
            <button *ngIf="!formSubmitted" mat-raised-button color="accent" type="submit" [attr.aria-label]="'common.send' | translate">
                <span>{{'common.send' | translate}}</span>
            </button>
            <button mat-button (click)="close($event)" [attr.aria-label]="'common.close' | translate">
                <span>{{'common.close' | translate}}</span>
            </button>
        </div>
        <mat-spinner *ngIf="formSubmitted"></mat-spinner>
    </form>
    <div class="info" *ngIf="messageSent">{{'main.contact.thank-you' | translate}}</div>

</div>
