export class CustomMessageHelper {

    static maxTextLineLength = 40;

    private static isNotEmpty(str: string) {
        return !!str && str.trim().length > 0;
    }

    public static validateLines(message: string, limit: number) {
        if (!message) {
            return true;
        }
        const parts = message?.split('\n');
        if (parts?.length <= limit) {
           return parts.every(part => part.length <= 50);
        } else {
            return false;
        }
    }

    public static buildCustomMessage(message: string): string {
        if (message) {
            message = message.trim();
            const parts = message?.split('\n');

            if (parts?.length === 1) {

                // If only one line, return the line
                return message.trim();

            } else if (parts?.length === 2) {

                // If two lines, check if one of these is blank
                const line1 = parts[0].trim();
                const line2 = parts[1].trim();

                if (!this.isNotEmpty(line1) && this.isNotEmpty(line2)) {
                    // line 1 is empty, return line 2
                    return line2;
                } else if (this.isNotEmpty(line1) && !this.isNotEmpty(line2)) {
                    // line 2 is empty, return line 1
                    return line1;
                } else if (this.isNotEmpty(line1) && this.isNotEmpty(line2)) {
                    // None of the lines are empty, return the orginal message
                    return message.trim();
                }
            }
        }
    }

    static cutText(text: string, maxLines) {
        if (text.length > 0) {
            const lines = text.split('\n');
            if (lines[0]?.length > this.maxTextLineLength) {
                return lines[0].slice(0, -1) + (lines.length > 1 ? '\n' + lines[1] : '');
            }
            if (lines[1]?.length > this.maxTextLineLength) {
                return lines[0] + '\n' + lines[1].slice(0, -1);
            }

            const lineCount = 1 + text.replace(/[^\n]/g, '').length;
            if (lineCount > maxLines) {
                const textArray = text.split('\n');
                return textArray.reduce((result, line, lineNum, array) => {
                    if (lineNum < maxLines) {
                        return result.concat('\n').concat(line);
                    }
                    return result.concat(line);
                });
            } else {
                return undefined;
            }
        }
        return undefined;
    }
}
