import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Meta} from '@angular/platform-browser';
import {MetaGeneratorService} from './services/meta-generator.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {SessionDataServiceDirective} from './services/session-data-service.directive';
import {CookieService} from 'ngx-cookie-service';
import {NgcCookieConsentService, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import * as confetti from 'canvas-confetti';
import {delay, first, tap} from 'rxjs/operators';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {DialogService} from './services/dialog.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('300ms ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('300ms ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        )
    ]
})
export class AppComponent implements OnInit, OnDestroy {

    private canvas: any;
    private langSubscription: Subscription;
    private confettiSubscription: Subscription;
    private cookieStatusChangeSubscription: Subscription;

    constructor(private translate: TranslateService,
                private meta: Meta,
                private router: Router,
                private sessionService: SessionDataServiceDirective,
                private gaService: GoogleAnalyticsService,
                private cookieService: CookieService,
                private ccService: NgcCookieConsentService,
                private gtmService: GoogleTagManagerService,
                private renderer2: Renderer2,
                private elementRef: ElementRef) {
        MetaGeneratorService.getTags().forEach(tag => {
            this.meta.addTag(tag);
        });
    }

    ngOnInit(): void {
        this.sessionService.initialize();

        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url
                };
                this.gtmService.pushTag(gtmTag);
            }
        });

        const hasDefaultLangCookie = this.cookieService.check('default-targetLang');
        if (hasDefaultLangCookie) {
            const defaultLang = this.cookieService.get('default-targetLang');
            this.translate.setDefaultLang(defaultLang);
            this.translate.use(defaultLang);
            this.ccService.close(false);
        } else {
            const browserLang = this.translate.getBrowserLang();
            const lang = (browserLang.match(/en|fr/) ? browserLang : 'en');
            this.translate.setDefaultLang(lang);
            this.translate.use(lang);
        }

        this.langSubscription = this.translate
            .get(['legal.cookie.message', 'common.ok', 'legal.cookie.link', 'legal.cookie.refuse',
            'legal.cookie.allow', 'legal.cookie.policy'])
            .subscribe(data => {
                this.ccService.getConfig().content = this.ccService.getConfig().content || {};
                this.ccService.getConfig().content.message = data['legal.cookie.message'];
                this.ccService.getConfig().content.dismiss = data['common.ok'];
                this.ccService.getConfig().content.link = data['legal.cookie.link'];
                this.ccService.getConfig().content.href = 'https://cookiesandyou.com';
                this.ccService.getConfig().content.deny = data['legal.cookie.refuse'];
                this.ccService.getConfig().content.policy = data['legal.cookie.policy'];
                this.ccService.getConfig().content.allow = data['legal.cookie.allow'];
                this.ccService.destroy(); // remove previous cookie bar (with default messages
                this.ccService.init(this.ccService.getConfig()); // update config with translated messages
            });

        this.cookieStatusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                this.ccService.close(false);
                if (event.status === 'allow') {
                    this.ccService.close(false);
                    this.cookieService.set('default-targetLang', this.translate.currentLang);
                } else {
                    this.cookieService.delete('default-targetLang');
                }
            });

        this.confettiSubscription = this.sessionService.confettiCreated$
            .pipe(
                first(),
                tap(() => {
                    // TODO add custom colors
                    this.canvas = this.renderer2.createElement('canvas');
                    this.renderer2.appendChild(this.elementRef.nativeElement, this.canvas);
                    const myConfetti = confetti.create(this.canvas, {
                        resize: true,
                        particleCount: 1000,
                        shapes: ['square'],
                        spread: 300,
                        gravity: 1,
                        origin: {
                            x: 0.5,
                            y: 0.6
                        }
                    });
                    myConfetti();
                }),
                delay(1500)
            )
            .subscribe(() => {
                this.renderer2.setStyle(this.canvas, 'display', 'none');
            });
    }

    ngOnDestroy(): void {
        this.langSubscription?.unsubscribe();
        this.confettiSubscription?.unsubscribe();
        this.cookieStatusChangeSubscription?.unsubscribe();
    }
}
