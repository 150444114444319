import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GASettings} from '../../../models/ga-settings.model';
import {first, switchMap} from 'rxjs/operators';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ContactService} from '../../../services/contact.service';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-discount-notification',
    templateUrl: './discount-notification.component.html',
    styleUrls: ['./discount-notification.component.scss']
})
export class DiscountNotificationComponent implements OnInit , OnDestroy {

    private subscription: Subscription;

    public contactForm: FormGroup;
    public messageSent = false;

    constructor(
        private gaService: GoogleAnalyticsService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private contactService: ContactService,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        @Optional() public dialogRef: MatDialogRef<DiscountNotificationComponent>,
    ) {
        this.contactForm = new FormGroup({
            email: new FormControl('',
                [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        });
    }

    ngOnInit(): void {
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.isFormValid()) {
            return;
        }

        this.gaService.event(GASettings.ACTIONS.REMINDER_AFTER_MIGRATION, GASettings.CATEGORIES.ENGAGEMENT, 'set_reminder_after_migration');
        this.subscription?.unsubscribe();
        this.subscription = this.recaptchaV3Service.execute('support').pipe(
            first(),
            switchMap(token => this.contactService.sendMessage('User to remind',
                this.contactForm.get('email').value,
                'Remind me',
                token)
            )
        ).subscribe((result: any) => {
            if (result.status === 'OK') {
                this.snackBar.open(this.translate.instant('main.contact.reminder-sent'),
                    this.translate.instant('common.close'), {
                        duration: 10000, horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });
                this.dialogRef?.close();
            } else {
                this.snackBar.open(this.translate.instant('main.contact.unable-send-message'),
                    this.translate.instant('common.close'), {
                        duration: 10000, horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });
            }
        });
    }

    private isFormValid() {
        return this.contactForm.valid && (this.contactForm.touched || this.contactForm.dirty);
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    close(event) {
        event.preventDefault();
        this.dialogRef?.close();
    }

}
