import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-process-overview',
    templateUrl: './process-overview.component.html',
    styleUrls: ['./process-overview.component.scss']
})
export class ProcessOverviewComponent implements OnInit {

    image: string;

    constructor(public dialogRef: MatDialogRef<ProcessOverviewComponent>,
                public translate: TranslateService) {
    }

    ngOnInit(): void {
        const currentLang = this.translate.currentLang;
        this.image = `../../../assets/images/process/process-${currentLang}.png`;
    }

    close() {
        this.dialogRef.close();
    }
}
