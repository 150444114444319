import {NgModule} from '@angular/core';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TosSharedComponent} from './tos-shared/tos-shared.component';
import {BaseModule} from '../../base.module';
import {RouterModule} from '@angular/router';
import {LegalRoutingModule} from './legal-routing.module';
import {FaqComponent} from './faq/faq.component';
import {RefundPolicyComponent} from './refund-policy/refund-policy.component';

@NgModule({
    declarations: [
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        TosSharedComponent,
        FaqComponent,
        RefundPolicyComponent,
    ],
    imports: [
        LegalRoutingModule,
        BaseModule,
        RouterModule.forChild([]),
    ],
    exports: [
        TosSharedComponent,
        FaqComponent
    ],
    providers: []
})


export class LegalModule {
}
