import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-tos-shared',
    templateUrl: './tos-shared.component.html',
    styleUrls: ['./tos-shared.component.scss']
})
export class TosSharedComponent implements OnInit, OnDestroy {

    searchTerm: string;
    languageSubscription: Subscription;
    currentLang: string;
    supportedLanguages = ['en', 'fr'];

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;

        this.languageSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLang = this.translate.currentLang;
        });
    }

    ngOnDestroy(): void {
        this.languageSubscription?.unsubscribe();
    }

}
