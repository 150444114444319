<div class="translate">

    <div class="translate-wrapper" [@appear]>
        <div class="title" *ngIf="targetLang">
            <div>{{'translate.title' | translate: {value: ('languages.' + targetLang | translate)} }}</div>
        </div>
        <div class="subtitle">
            <span>{{'translate.subtitle' | translate}}</span><br>
        </div>

        <div class="instructions">
            <mat-accordion multi>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{'translate.instructions' | translate}}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="instruction">
                        <div class="num">1)</div>
                        <div class="text">{{'translate.hint-1' | translate}}</div>
                    </div>
                    <div class="instruction">
                        <div class="num">2)</div>
                        <div class="text">{{'translate.hint-2' | translate}}</div>
                    </div>
                    <div class="instruction">
                        <div class="num">3)</div>
                        <div class="text">{{'translate.hint-3' | translate}}</div>
                    </div>
                    <div class="instruction">
                        <div class="num">4)</div>
                        <div class="text">{{'translate.hint-4' | translate}}</div>
                    </div>
                    <div class="instruction">
                        <div class="num">5)</div>
                        <div class="text">{{'translate.hint-5' | translate}}</div>
                    </div>
                    <div class="instruction">
                        <div class="num">6)</div>
                        <div class="text">{{'translate.hint-6' | translate}}</div>
                    </div>
                    <div class="instruction">
                        <div class="num">7)</div>
                        <div class="text">{{'translate.hint-7' | translate}}</div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="translate-block" *ngIf="!languagesFound">
            <div class="text">{{'translate.no-validation-found' | translate}}</div>
        </div>
        <div class="translate-block" *ngIf="languagesFound">
            <div class="loading" *ngIf="loadingImages">
                <mat-spinner></mat-spinner>
            </div>
            <app-review-theme-translation *ngIf="reviewTranslationsValidation" [review]="reviewTranslationsValidation"></app-review-theme-translation>
            <form [formGroup]="validationForm" (ngSubmit)="onSubmit($event)" #myForm="ngForm">
                <mat-form-field class="md-block">
                    <mat-label>{{'translate.name' | translate}} (required)</mat-label>
                    <input matInput type="text" formControlName="name" autocomplete="off">
                </mat-form-field>
                <mat-form-field class="md-block">
                    <mat-label>{{'translate.how-do-you-say' | translate: {value: ('languages.' + targetLang | translate)} }} (required)</mat-label>
                    <input matInput type="text" formControlName="nativeName" autocomplete="off">
                    <mat-hint>{{'translate.how-do-you-say-hint' | translate}}</mat-hint>
                </mat-form-field>
                <mat-form-field class="md-block">
                    <mat-label>{{'translate.thank-you' | translate: {value: ('languages.' + targetLang | translate)} }}</mat-label>
                    <input matInput type="text" formControlName="thankYou" autocomplete="off">
                </mat-form-field>
                <mat-form-field class="md-block">
                    <mat-label>{{'translate.anecdote' | translate}}</mat-label>
                    <input matInput type="text" formControlName="anecdote" autocomplete="off">
                </mat-form-field>
                <mat-form-field class="md-block">
                    <mat-label>{{'translate.message' | translate}}</mat-label>
                    <textarea matInput formControlName="message"></textarea>
                </mat-form-field>
                <div class="action" *ngIf="!validationSent">
                    <button mat-button mat-raised-button color="accent" type="submit"
                            [attr.aria-label]="'translate.submit' | translate"
                            (click)="onSubmit($event)">
                        <span>{{'translate.submit' | translate }}</span>
                    </button>
                </div>
            </form>

            <div class="loading">
                <mat-spinner *ngIf="loadingForm"></mat-spinner>
                <div *ngIf="validationSent">{{'translate.submitted' | translate}}</div>
            </div>

            <div class="fixed-corrections" *ngIf="myCorrections">
                <div>Send this by email if the form can't be submitted</div>
                <div>{{myCorrections}}</div>
            </div>

        </div>
    </div>

</div>
