import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConfirmationComponent} from './confirmation/confirmation.component';

const routes: Routes =
    [
        {
            path: 'confirmation',  component: ConfirmationComponent
        }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OrderRoutingModule {
}
