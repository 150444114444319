<div class="testimonials">
    <div class="box">
        <div class="stars"><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon></div>
        <div class="testimonial"><q>Kidnovation of the year for multicultural families!</q></div>
        <div class="author">Amine</div>
    </div>
    <div class="box">
        <div class="stars"><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon></div>
        <div class="testimonial"><q>Fantastic way of keeping our kids bilingual while living anywhere in the world!</q></div>
        <div class="author">Cyrille</div>
    </div>
    <div class="box">
        <div class="stars"><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon></div>
        <div class="testimonial"><q>Kids loved the book. It was super easy to customize and order online with very fast delivery!<br>Great attention to detail and fun to read!</q></div>
        <div class="author">Wael</div>
    </div>
    <div class="box">
        <div class="stars"><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon></div>
        <div class="testimonial"><q>Picture books imagined for multilingual children that we can customize.<br>Contains cute images and available at an affordable price.</q></div>
        <div class="author">Maz</div>
    </div>
</div>
