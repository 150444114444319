<div class="language-selector">

    <div class="title">
        <mat-icon>language</mat-icon>
        <div>{{'create.language-selector.title' | translate }}</div>
    </div>

    <mat-chip-list class="selected-chips"
                   cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <mat-chip class="selected-chip" *ngFor="let lang of selectedLanguages;" cdkDrag
                  (removed)="removeLanguage(lang)">
            {{'languages.' + lang | translate}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>

    <mat-form-field class="search" appearance="outline">
        <input autocomplete="off" [(ngModel)]="searchText" matInput appAutofocus
               [placeholder]="'create.language-selector.search-languages' | translate">
        <mat-icon matSuffix>search</mat-icon>
        <mat-hint align="end">{{'create.language-selector.hint' | translate: {value: maxLanguages} }}</mat-hint>
    </mat-form-field>

    <div class="languages">
        <button mat-button class="language-wrapper"
                [attr.aria-label]="lang.name"
                [ngClass]="{'is-selected': selectedLanguages.indexOf(lang.key) !== -1}"
                *ngFor="let lang of availableLanguages | filterBy: ['name', 'nativeName']: searchText"
                (click)="toggleLanguage(lang.key)">
            <div class="language">
                <span class="name">{{lang.name}}</span>
                <span class="native">{{lang.nativeName}}</span>
            </div>
        </button>
        <div class="language-wrapper missing-button" (click)="openContactForm()">
            <span class="name">{{'create.language-selector.language-missing' | translate }}</span>
        </div>
    </div>

    <div class="actions">
        <button mat-raised-button color="accent" (click)="ok()" [disabled]="!selectedLanguages?.length">{{'common.ok' | translate}}</button>
        <button mat-button (click)="close()" [attr.aria-label]="'common.close' | translate">{{'common.close' | translate}}</button>
        <div class="separator"></div>
        <button mat-button (click)="openContactForm()"
                [attr.aria-label]="'create.language-selector.language-missing-action' | translate">{{'create.language-selector.language-missing-action' | translate}}</button>
    </div>

</div>
