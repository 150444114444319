import {Injectable} from '@angular/core';
import {ShippingMethod} from '../components/order/order.helper';

export interface Coupon {
    name: string;
    percentOff: number;
    applied: boolean;
}

export class TotalPrice {
    constructor(
        public shippingMethod: ShippingMethod,
        public shippingPrice: number,
        public total: number,
        public totalWithoutPromotion: number,
        public shippingOffered: boolean,
        public coupons: Coupon[],
        public leadTime: number,
    ) {

    }
}

@Injectable({
    providedIn: 'root',
})
export class TotalPriceAdapter {

    constructor() {
    }

    adapt(item: any): TotalPrice {
        return new TotalPrice(
            item.shippingMethod,
            item.shippingPrice,
            item.total,
            item.totalWithoutPromotion,
            item.shippingOffered,
            item.coupons,
            item.leadTime,
        );
    }
}
