<div class="welcome">

    <div class="welcome-img cloud4">
        <img id="cloud-1" src="../../../../assets/images/cloud4.png"/>
    </div>
    <div class="welcome-img cloud6">
        <img id="cloud-2" src="../../../../assets/images/cloud6.png"/>
    </div>
    <div class="welcome-img cloud5">
        <img id="cloud-4" src="../../../../assets/images/cloud5.png"/>
    </div>

    <app-book-slider class="welcome-book"></app-book-slider>

    <div class="welcome-text">
        <h1 class="title">Kid of the World</h1>
        <h2 class="subtitle">{{'main.welcome.subtitle' | translate}}</h2>
        <div class="text-more">{{'main.welcome.text-more' | translate}}</div>
        <div class="text-more italic">{{'main.welcome.text' | translate}}</div>
        <div class="call-to-action">
            <button mat-button mat-raised-button class="mat-accent" (click)="goToCreate()" [attr.aria-label]="'main.welcome.action' | translate">
                {{'main.welcome.action' | translate}}
            </button>
        </div>
    </div>
</div>
<div class="welcome-city">
    <div class="city">
    </div>
</div>
