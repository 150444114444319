import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Settings} from '../../models/settings.model';
import {TranslateService} from '@ngx-translate/core';
import {CurrencyUtils} from './currency.utils';
import {SessionDataServiceDirective} from '../../services/session-data-service.directive';
import {Subject, Subscription} from 'rxjs';
import {filter, first, switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import {NavigationEnd, Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GASettings} from '../../models/ga-settings.model';
import {CookieService} from 'ngx-cookie-service';
import {DialogService} from '../../services/dialog.service';
import {NotificationDefinitions} from '../../models/coupon-definition.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {animate, style, transition, trigger} from '@angular/animations';
import {ItemsHelper} from '../../helpers/items.helper';
import {CurrencyPipe} from '@angular/common';
import {OrderService} from '../../services/order.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('appear', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateX(100%)'}))
            ])
        ]),
    ]
})
export class HeaderComponent implements OnInit, OnDestroy {

    private routerSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private translateSubscription: Subscription;
    private settingsSubscription: Subscription;
    private initialSettingsSubscription: Subscription;
    private notificationSubscription: Subscription;
    private currencySubscription: Subscription;
    private nbItemsSubscription: Subscription;
    private priceChangeSubscription: Subscription;
    private currencyChanged$ = new Subject<string>();
    private buffer = 0;
    private timeOnHomepage: moment.Moment;
    private scrollOffset = 30;
    private notifications: NotificationDefinitions;
    private timeOnComponent: moment.Moment;

    public websiteSupportedLanguages = {
        en: 'English',
        fr: 'Français',
    };
    public hasCoupon = false;
    public couponMessage: string;
    public couponCode: string;
    public navFixed = false;
    public settings: Settings;
    public refCurrency: string;
    public currentLang: string;
    public allCurrencySymbols: Map<string, string>;
    public lastScrolledHeight = 0;
    public showCreateButton = false;
    public isRouteForCreateButton = false;
    public isRouteForHomeButton = false;
    public isRouteForCoupon = false;
    public isRouteForBasketButton = false;
    public nbSavedItems = 0;
    public totalPrice = {
        price: '',
        cents: ''
    };
    public showShippingMissing: boolean;

    constructor(private sessionDataService: SessionDataServiceDirective,
                private translate: TranslateService,
                private router: Router,
                private snackBar: MatSnackBar,
                private dialogService: DialogService,
                private currencyPipe: CurrencyPipe,
                private gaService: GoogleAnalyticsService,
                private orderService: OrderService,
                private cookieService: CookieService,) {
    }

    ngOnInit() {
        this.timeOnComponent = moment();
        this.refCurrency = 'EUR';

        this.settingsSubscription = this.sessionDataService.settingsSubject$
            .pipe(
                filter(settings => !!settings)
            ).subscribe((settings) => {
                this.settings = settings;
                this.refCurrency = this.sessionDataService.refCurrency;
            });

        this.nbItemsSubscription = this.sessionDataService.itemsChanged$.subscribe(() => {
            this.nbSavedItems = ItemsHelper.getNbDuplicates(this.sessionDataService.currentOder.content.items);
        });

        this.priceChangeSubscription =  this.sessionDataService.priceChanged$.subscribe(price => {
            if (price?.totalOrderPrice.total === 0) {
                this.totalPrice.price = '';
                this.totalPrice.cents = '';
            } else {
                const totalPrice = price?.totalOrderPrice.total + '';
                this.totalPrice.price = totalPrice.substr(0, 2);
                this.totalPrice.cents = totalPrice.substr(2, 3);
            }
            this.showShippingMissing = !price.totalOrderPrice.shippingOffered && price.totalOrderPrice.shippingPrice === 0;
        });

        this.notificationSubscription = this.sessionDataService.loadNotifications().subscribe((notifications) => {
            this.notifications = notifications;

            const coupons = this.notifications?.notifications?.filter(n => n.type === 'coupon');
            this.hasCoupon = coupons?.length > 0 && coupons[0].messages?.length > 0;
            if (this.hasCoupon) {
                const coupon = coupons[0];
                this.couponMessage = coupon.messages?.find(m => m.locale === this.translate.currentLang)?.message;
                this.couponCode = coupon.code;
            }
            const allMaintenanceMessages = this.notifications?.notifications?.filter(n => n.type === 'maintenance');
            if (allMaintenanceMessages?.length > 0 && allMaintenanceMessages[0].messages?.length > 0) {
                const message = allMaintenanceMessages[0].messages.filter(m => m.locale === this.translate.currentLang)[0].message;
                this.snackBar.open(message,
                    this.translate.instant('common.close'), {
                        duration: 10000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });
            }
        });

        this.currencySubscription = this.currencyChanged$.pipe(
            switchMap(currency => this.sessionDataService.loadSettings(currency)),
        ).subscribe(settings => {
            this.settings = settings;
            this.refCurrency = this.sessionDataService.refCurrency;
            this.calculatePrice(this.refCurrency);
        });

        this.allCurrencySymbols = CurrencyUtils.getSymbols();

        if (!this.translate.currentLang) {
            this.changeLang('en');
            moment.locale('en');
        } else {
            this.currentLang = this.translate.currentLang;
            moment.locale(this.translate.currentLang);
        }

        this.routerSubscription = this.router.events.subscribe(
            (event: any) => {
                if (event instanceof NavigationEnd) {
                    window.scrollTo(0, 0);
                    this.timeOnHomepage = moment();
                    this.isRouteForCreateButton = this.router.url !== null
                        && this.router.url !== '/create'
                        && this.router.url !== '/order'
                        && !this.router.url.startsWith('/translate');
                    this.isRouteForHomeButton = this.router.url !== '/';
                    this.isRouteForBasketButton = this.router.url === '/create'
                        || this.router.url === '/'
                        || this.router.url === '/privacy-policy'
                        || this.router.url === '/refund-policy'
                        || this.router.url === '/terms-and-conditions';
                    this.isRouteForCoupon = !this.router.url?.startsWith('/translate') && !this.router.url.startsWith('/confirmation');
                }
            }
        );
    }

    calculatePrice(currency: string) {
        if (this.sessionDataService.currentOder.content.items?.length) {
            this.orderService.calculatePrice(currency)
                .pipe(
                    first(),
                ).subscribe();
        }
    }

    setCurrency(curr: string) {
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'change_currency_from_menu', 1);
        this.currencyChanged$.next(curr);
    }

    changeLang(lang: string) {
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'change_lang_from_menu', 1);
        this.translate.use(lang);

        const hasDefaultLangCookie = this.cookieService.check('default-targetLang');
        if (hasDefaultLangCookie) {
            this.cookieService.set('default-targetLang', lang);
        }

        this.currentLang = lang;
        moment.locale(lang);
    }

    public goToCreate() {
        const period = moment().diff(this.timeOnComponent, 'seconds');
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'create_from_menu', period);
        this.router.navigate(['/create']);
    }

    ngOnDestroy() {
        this.routerSubscription?.unsubscribe();
        this.statusChangeSubscription?.unsubscribe();
        this.translateSubscription?.unsubscribe();
        this.settingsSubscription?.unsubscribe();
        this.currencySubscription?.unsubscribe();
        this.notificationSubscription?.unsubscribe();
        this.initialSettingsSubscription?.unsubscribe();
        this.nbItemsSubscription?.unsubscribe();
        this.priceChangeSubscription?.unsubscribe();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        const window = event.path[1];
        const currentScrollHeight = window.scrollY;
        this.navFixed = currentScrollHeight > this.scrollOffset;

        if (this.ignoredByBuffer()) {
            return;
        }

        this.showCreateButton = currentScrollHeight > 500;
        this.lastScrolledHeight = currentScrollHeight;
    }

    private ignoredByBuffer(): boolean {
        if (this.buffer < 10) {
            this.buffer += 1;
            return true;
        }
        this.buffer = 0;
        return false;
    }

    openContactForm() {
        this.dialogService.openContactFormDialog().afterClosed().pipe(first()).subscribe();
    }

    showBasket() {
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'show_basket');
        this.sessionDataService.openBasket$.next();
    }
}
