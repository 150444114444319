<div class="selected-book" *ngIf="selectedBook">

    <div class="preview-container">
        <div class="preview-wrapper">
            <div class="book" [ngClass]="{'book-opened': (bookOpened && currentPageId === 0)}">
                <!-- First page hint -->
                <div class="first-page">
                    <div class="first-page-hint" [innerHTML]="'create.preview.open-book' | translate"></div>
                    <mat-icon>subdirectory_arrow_right</mat-icon>
                </div>
                <div id="pages" class="pages notranslate">
                    <!-- Cover page -->
                    <div class="page" (click)="flipPage(selectedBook.pages[0], selectedBook, true);"
                         [ngClass]="selectedBook.pages[0]?.className"
                         [ngStyle]="{'z-index': selectedBook.pages[0]?.zIndex}">
                        <div class="page-content cover">
                            <div class="click-me" *ngIf="!bookOpened">
                                <div class="bubble bubble-bottom-left">
                                    <span>{{'common.click' | translate}}</span>
                                </div>
                            </div>
                            <div class="process-image">
                                <img src="../../../assets/images/process-kotw.png"/>
                            </div>
                            <div class="half-page top">
                                <img src="../../../assets/images/logo-kid-of-the-world-220px.png"/>
                            </div>
                            <div class="half-page bottom">
                                <div class="translations">
                                    <div class="translation"
                                         *ngFor="let selectedLanguage of selectedBook.locales">
                                        {{selectedBook.pages[0]?.illustrationBottom.translations[selectedLanguage]}}
                                    </div>
                                    <div class="translation light" *ngIf="selectedBook.locales?.length <= 1">
                                        {{'create.add-language' | translate}}
                                    </div>
                                </div>
                                <div class="image">
                                    <div class="trick"></div>
                                    <img *ngIf="selectedBook.pages[0]?.illustrationBottom.id" mat-card-image
                                         [src]="'../../../assets/illustrations/' + selectedBook.pages[0].illustrationBottom.id + '.png'"
                                         [alt]="selectedBook.pages[0].illustrationBottom.id">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- White page -->
                    <div class="first-page page" (click)="flipPage(selectedBook.pages[1], selectedBook, true)"
                         [ngClass]="selectedBook.pages[1]?.className"
                         [ngStyle]="{'z-index': selectedBook.pages[1]?.zIndex}">
                        <div class="first-page-hint" [innerHTML]="'create.preview.disclaimer-hint' | translate"></div>
                        <mat-icon>subdirectory_arrow_right</mat-icon>
                    </div>
                    <!-- Dedication -->
                    <div class="page" (click)="flipPage(selectedBook.pages[2], selectedBook, true)"
                         [ngClass]="selectedBook.pages[2]?.className"
                         [ngStyle]="{'z-index': selectedBook.pages[2]?.zIndex}">
                        <div class="page-content disclaimer">
                            <pre class="kids-name" *ngIf="!selectedBook?.kidsName">{{'create.add-custom-message' | translate}}</pre>
                            <pre class="kids-name" *ngIf="selectedBook?.kidsName">{{selectedBook?.kidsName}}</pre>
                            <div class="translations" style="z-index: 100">
                                <div class="translation lang" *ngFor="let language of selectedBook?.locales">
                                    {{getNativeName(language)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Translations -->
                    <div class="page" *ngFor="let page of selectedBook.pages?.slice(3)"
                         (click)="flipPage(page, selectedBook, false)" [ngClass]="page.className"
                         [ngStyle]="{'z-index':page.zIndex}">
                        <div class="page-content"
                             [ngClass]="{'is-even': page.id % 2 === 0, 'is-odd' : page.id % 2 !== 0}">
                            <div class="half-page top">
                                <div class="translations">
                                    <div class="translation"
                                         *ngFor="let selectedLanguage of selectedBook.locales">
                                        {{ page.illustrationTop.translations[selectedLanguage]}}
                                    </div>
                                </div>
                                <div class="image">
                                    <div class="trick"></div>
                                    <img *ngIf="page.illustrationTop.id " mat-card-image
                                         [src]="'../../../assets/illustrations/' + page.illustrationTop.id + '.png'"
                                         [alt]="page.illustrationTop.id ">
                                </div>
                            </div>
                            <div class="half-page bottom">
                                <div class="translations">
                                    <div class="translation"
                                         *ngFor="let selectedLanguage of selectedBook.locales">
                                        {{page.illustrationBottom.translations[selectedLanguage]}}
                                    </div>
                                </div>
                                <div class="image">
                                    <div class="trick"></div>
                                    <img *ngIf="page.illustrationBottom.id " mat-card-image
                                         [src]="'../../../assets/illustrations/' + page.illustrationBottom.id + '.png'"
                                         [alt]="page.illustrationBottom.id ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="last-page">
                    <div class="last-page-hint" [innerHTML]="'create.preview.end-preview' | translate"></div>
                </div>
            </div>
        </div>
    </div>

</div>
