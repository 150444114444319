import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ItemOrder, Page} from '../../../models/item-order.model';
import {GASettings} from '../../../models/ga-settings.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {animate, style, transition, trigger} from '@angular/animations';
import {LanguageHelper} from '../../../helpers/language.helper';

@Component({
    selector: 'app-create-preview',
    templateUrl: './create-preview.component.html',
    styleUrls: ['./create-preview.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('.5s ease-out', style({opacity: '1'})),
            ]),
            transition(':leave', [
                style({opacity: '1'}),
                animate('.5s ease-out', style({opacity: '0'})),
            ])
        ]),
    ]
})
export class CreatePreviewComponent implements OnInit, OnChanges {

    public bookOpened = false;
    public currentPageId = 0;

    @Input()
    selectedBook: ItemOrder;

    @Input()
    supportedLanguageList: Map<string, { englishName: string, nativeName: string }>;

    @Input()
    currentLanguage: string;

    @Input()
    showDedication: boolean;

    @Output()
    addLanguageChange: EventEmitter<void> = new EventEmitter<void>();

    constructor(private gaService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.showDedication?.currentValue) {
            // If current page id % 2 is 0, it means that the previous step was a forward step
            // Otherwise, it was a backward step
            let current = this.currentPageId % 2 === 0 ? this.currentPageId + 1 : this.currentPageId - 2;
            if (current > 1) {
                this.flipPage(this.selectedBook.pages[current], this.selectedBook);
                while (this.currentPageId > 3) {
                    current = this.currentPageId % 2 === 0 ? this.currentPageId + 1 : this.currentPageId - 2;
                    this.flipPage(this.selectedBook.pages[current], this.selectedBook);
                }
            } else {
                this.flipPage(this.selectedBook.pages[0], this.selectedBook);
            }
        }
    }

    flipPage(page: Page, item: ItemOrder, track?: boolean) {
        if (!item.locales?.length) {
            return;
        }

        if (track) {
            this.gaService.event(GASettings.ACTIONS.VIEW_PAGE, GASettings.CATEGORIES.ENGAGEMENT, 'flip_page');
        }

        this.bookOpened = true;
        page.pageNum = page.id + 1;
        if (page.pageNum % 2 === 0) {
            page.className = '';
            if (page.id !== 0) {
                item.pages[page.id - 1].className = '';
            }
        } else {
            page.className = 'flipped';
            if (page.id !== item.pages.length) {
                item.pages[page.id + 1].className = 'flipped';
            }
        }

        this.currentPageId = page.id;
    }

    getNativeName(locale: string) {
        return LanguageHelper.getNativeName(locale, this.selectedBook.locales);
    }

    capitalizeFirstLetter(str) {
        if (str.length > 1) {
            return str[0].toUpperCase() + str.slice(1);
        } else {
            return str[0].toUpperCase();
        }
    }
}
