<div class="tos-dialog">

    <div class="title">{{'common.terms-and-conditions' | translate}}</div>
    <div class="content">
        <app-tos-shared></app-tos-shared>
    </div>
    <div class="actions">
        <button mat-raised-button color="accent" (click)="agree()" [attr.aria-label]="'common.i-agree' | translate">{{'common.i-agree' | translate}}</button>
        <button mat-button (click)="close()" [attr.aria-label]="'common.close' | translate">{{'common.close' | translate}}</button>
    </div>
</div>
