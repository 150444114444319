import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomMessageHelper} from '../../create/custom-message-helper';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

export class GreetingsDialogParams {
    greetings: string;
}

@Component({
    selector: 'app-greetings',
    templateUrl: './greetings.component.html',
    styleUrls: ['./greetings.component.scss']
})
export class GreetingsComponent implements OnInit {

    greetings: string;
    fontSize: '16px';

    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    constructor(@Inject(MAT_DIALOG_DATA) public params: GreetingsDialogParams,
                public dialogRef: MatDialogRef<GreetingsComponent>,
                private translate: TranslateService,
                private snackBar: MatSnackBar,
                private _ngZone: NgZone) {
    }

    ngOnInit(): void {
        this.greetings = this.params.greetings;
    }

    ok() {
        if (CustomMessageHelper.validateLines(this.greetings, 10)) {
            this.dialogRef.close(this.greetings);
        } else {
            this.snackBar.open(this.translate.instant('order.order-details.greetings-not-valid'),
                this.translate.instant('common.close'), {
                    duration: 10000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
        }
    }

    clean() {
        this.greetings = null;
        this.dialogRef.close(this.greetings);
    }

    limitLines(event: InputEvent) {
        const text = (event.target as HTMLTextAreaElement).value;
        const newText = CustomMessageHelper.cutText(text, 10);
        if (newText) {
            (event.target as HTMLTextAreaElement).value = newText;
        }
    }

}
