<div class="shipping-selector">

    <div class="title">
        <mat-icon>home</mat-icon>
        <div>{{'create.shipping-selector.title' | translate}}</div>
    </div>

    <mat-form-field>
        <mat-label>{{'create.shipping-selector.select-country' | translate}}</mat-label>
        <mat-select [formControl]="countryCtrl" #singleSelect>
            <mat-option>
                <ngx-mat-select-search i18n-placeholderLabel i18n-noEntriesFoundLabel
                                       [placeholderLabel]="'create.shipping-selector.search-country' | translate"
                                       [noEntriesFoundLabel]="'create.shipping-selector.not-found' | translate" [formControl]="countryFilterCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">{{country.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="actions">
        <button mat-raised-button color="accent" (click)="save()" [attr.aria-label]="'common.ok' | translate">{{'common.ok' | translate}}</button>
        <button mat-button (click)="cancel()" [attr.aria-label]="'common.cancel' | translate">{{'common.cancel' | translate}}</button>
    </div>

</div>




