import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {GASettings} from '../../models/ga-settings.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Router} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    animations: [
        trigger('appear', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateX(100%)'}))
            ])
        ]),
    ]
})
export class AboutUsComponent implements OnInit {
    
    public timeOnComponent: moment.Moment;
    public panelOpenState = false;
    
    constructor(private gaService: GoogleAnalyticsService, private router: Router,) {
    }
    
    ngOnInit(): void {
        this.timeOnComponent = moment();
    }
    
    public goToCreate() {
        const period = moment().diff(this.timeOnComponent, 'seconds');
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'create_from_preface', period);
        this.router.navigate(['/create']);
    }
}
