<div class="faq">
    <div class="faq-wrapper">
        <div class="title">{{'legal.faq.title' | translate}}</div>
<!--        <div class="subtitle">{{'legal.faq.subtitle' | translate}}</div>-->
        <mat-form-field class="search">
            <input autocomplete="off" [(ngModel)]="searchTerm" matInput
                   [placeholder]="'legal.faq.search' | translate">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="content">
            <div class="faq-item" *ngFor="let item of faqList | filterBy: ['response', 'question'] : searchTerm">
                <div class="question" [innerHtml]="item.question"></div>
                <div class="answer" [innerHTML]="item.response"></div>
            </div>
        </div>
        <div class="actions" *ngIf="!createMode">
            <button mat-button color="primary" [routerLink]="['/']" [attr.aria-label]="'common.home' | translate">{{'common.home' | translate}}</button>
            <button mat-button color="accent" [routerLink]="['/create']" [attr.aria-label]="'common.create-book' | translate">
                {{'common.create-book' | translate}}
            </button>
        </div>
    </div>
</div>
