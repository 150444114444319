import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    
    private contactUri = `${environment.apiUrl}/contact`;
    private newsletterSubscribeUri = `${environment.apiUrl}/newsletter/subscribe`;
    private newsletterUnsubscribeUri = `${environment.apiUrl}/newsletter/unsubscribe`;
    
    constructor(private http: HttpClient) {
    }
    
    sendMessage(name: string, email: string, message: string, token: string): Observable<any> {
        return this.http.post(`${this.contactUri}`, {name, email, message, token});
    }
    
    subscribeNewsletter(email: string, locale: string, token: string): Observable<any> {
        return this.http.post(`${this.newsletterSubscribeUri}`, {email, locale, token});
    }
    
    unSubscribeNewsletter(email: string, comment: string, token: string): Observable<any> {
        return this.http.post(`${this.newsletterUnsubscribeUri}`, {email, comment, token});
    }
}
