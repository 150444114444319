import {AfterViewInit, Directive, ElementRef, Input, OnInit} from '@angular/core'

@Directive({
    selector: '[appAutofocus]'
})
export class AutoFocusDirective implements AfterViewInit {


    constructor(
        private el: ElementRef
    ) {
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            this.el.nativeElement.focus();
        },500 );
    }
}
