<div class="warning" *ngIf="!supportedLanguages.includes(currentLang)">
    {{'legal.terms-and-conditions-english-only' | translate}}
</div>
<div class="tos" *ngIf="currentLang !== 'fr'">
    <div class="heading">OVERVIEW</div>
    <p>
        This website is operated by Othman Tajmouati, owner and founder of Kid of the World. Throughout the site, the terms “we”, “us” and “our” refer to Kid of
        the World and to its owner. Kid of the World offers this website, including all information, tools and services available from this site to you, the user,
        conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
    </p>
    <p>
        By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions
        (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of
        Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
    </p>
    <p>
        Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by
        these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.
        If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
    </p>
    <p>
        Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of
        the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates
        and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following
        the posting of any changes constitutes acceptance of those changes.
    </p>
    <p>
        Our store is hosted on Google Cloud.
    </p>
    <div class="heading">SECTION 1 - ONLINE STORE TERMS</div>
    <p>
        By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are
        the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
    </p>
    <p>
        You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction
        (including but not limited to copyright laws).
    </p>
    <p>
        You must not transmit any worms or viruses or any code of a destructive nature.
    </p>
    <p>
        A breach or violation of any of the Terms will result in an immediate termination of your Services.
    </p>
    <div class="heading">SECTION 2 - GENERAL CONDITIONS</div>
    <p>
        We reserve the right to refuse service to anyone for any reason at any time.
    </p>
    <p>
        You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various
        networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted
        during transfer over networks.
    </p>
    <p>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any
        contact on the website through which the service is provided, without express written permission by us.
    </p>
    <p>
        The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
    </p>
    <div class="heading">SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</div>
    <p>
        We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for
        general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete
        or more timely sources of information. Any reliance on the material on this site is at your own risk.
    </p>
    <p>
        This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We
        reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is
        your responsibility to monitor changes to our site.
    </p>
    <div class="heading">SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</div>
    <p>
        Prices for our products are subject to change without notice.
    </p>
    <p>
        We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
    </p>
    <p>
        We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
    </p>
    <div class="heading">SECTION 5 - PRODUCTS OR SERVICES</div>
    <p>
        Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are
        subject to return or exchange only according to our Return Policy.
    </p>
    <p>
        We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that
        your computer monitor's display of any color will be accurate.
    </p>
    <p>
        We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may
        exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of
        products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any
        product at any time. Any offer for any product or service made on this site is void where prohibited.
    </p>
    <p>
        We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations,
        or that any errors in the Service will be corrected.
    </p>
    <div class="heading">SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</div>
    <p>
        We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per
        household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same
        billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or
        billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment,
        appear to be placed by dealers, resellers or distributors.
    </p>
    <p>
        You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update
        your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and
        contact you as needed.
    </p>
    <p>
        For more detail, please review our Returns Policy.
    </p>
    <div class="heading">SECTION 7 - OPTIONAL TOOLS</div>
    <p>
        We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
    </p>
    <p>
        You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any
        kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
    </p>
    <p>
        Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with
        and approve of the terms on which tools are provided by the relevant third-party provider(s).
    </p>
    <p>
        We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new
        features and/or services shall also be subject to these Terms of Service.
    </p>
    <div class="heading">SECTION 8 - THIRD-PARTY LINKS</div>
    <p>
        Certain content, products and services available via our Service may include materials from third-parties.
    </p>
    <p>
        Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or
        evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any
        other materials, products, or services of third-parties.
    </p>
    <p>
        We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in
        connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you
        engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
    </p>
    <div class="heading">SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</div>
    <p>
        If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas,
        suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any
        time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be
        under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
    </p>
    <p>
        We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening,
        libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
    </p>
    <p>
        You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or
        proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any
        computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail
        address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any
        comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
    </p>
    <div class="heading">SECTION 10 - PERSONAL INFORMATION</div>
    <p>
        Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.
    </p>
    <div class="heading">SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</div>
    <p>
        Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to
        product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors,
        inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is
        inaccurate at any time without prior notice (including after you have submitted your order).
    </p>
    <p>
        We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing
        information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to
        indicate that all information in the Service or on any related website has been modified or updated.
    </p>
    <div class="heading">SECTION 12 - PROHIBITED USES</div>
    <p>
        In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful
        purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules,
        laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to
        harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age,
        national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or
        may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to
        collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or
        (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to
        terminate your use of the Service or any related website for violating any of the prohibited uses.
    </p>
    <div class="heading">SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</div>
    <p>
        We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
    </p>
    <p>
        We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
    </p>
    <p>
        You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
    </p>
    <p>
        You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you
        through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or
        conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for
        a particular purpose, durability, title, and non-infringement.
    </p>
    <p>
        In no case shall Kid of the World, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or
        licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without
        limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including
        negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other
        claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or
        damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service,
        even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or
        incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
    </p>
    <div class="heading">SECTION 14 - INDEMNIFICATION</div>
    <p>
        You agree to indemnify, defend and hold harmless Kid of the World and our parent, subsidiaries, affiliates, partners, officers, directors, agents,
        contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable
        attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference,
        or your violation of any law or the rights of a third-party.
    </p>
    <div class="heading">SECTION 15 - SEVERABILITY</div>
    <p>
        In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be
        enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service,
        such determination shall not affect the validity and enforceability of any other remaining provisions.
    </p>
    <div class="heading">SECTION 16 - TERMINATION</div>
    <p>
        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
    </p>
    <p>
        These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying
        us that you no longer wish to use our Services, or when you cease using our site.
    </p>
    <p>
        If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may
        terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly
        may deny you access to our Services (or any part thereof).
    </p>
    <div class="heading">SECTION 17 - ENTIRE AGREEMENT</div>
    <p>
        The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
    </p>
    <p>
        These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and
        understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals,
        whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
    <p>
        Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
    </p>
    <div class="heading">SECTION 18 - GOVERNING LAW</div>
    <p>
        These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of
        Geneva, Switzerland.
    </p>
    <div class="heading">SECTION 19 - CHANGES TO TERMS OF SERVICE</div>
    <p>
        You can review the most current version of the Terms of Service at any time at this page.
    </p>
    <p>
        We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our
        website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the
        posting of any changes to these Terms of Service constitutes acceptance of those changes.
    </p>
    <div class="heading">SECTION 20 - ORDERS</div>
    <p>
        Orders must be submitted through the website.
    </p>
    <p>
        We support several translations on our website, books or products your order, and while we do our best to validate the translations, there still may be
        some errors in the translations or wording . We cannot undertake to give any assurance that the translations are correct. Please contact us if you notice
        errors so we can improve our translation base.,
    </p>
    <div class="heading">SECTION 21 - CONTACT INFORMATION</div>
    <p>
        Questions about the Terms of Service should be sent to us at hello@kidoftheworld.com.
    </p>
</div>
<div class="tos" *ngIf="currentLang === 'fr'">
    <div class="heading">VUE D’ENSEMBLE</div>
    <p>
        Ce site web est exploité par Othman Tajmouati, propriétaire de Kid of the World. Sur ce site, les termes "nous", "notre" et "nos" font référence à Kid
        of the World ainsi qu'à son propriétaire. Kid of the World propose ce site web, y compris toutes les informations, tous les outils et tous les services qui
        y sont disponibles pour vous, l’utilisateur, sous réserve de votre acceptation de l’ensemble des modalités, conditions, politiques et avis énoncés ici.
    </p>
    <p>
        En visitant ce site et/ou en achetant un de nos produits, vous vous engagez dans notre "Service" et acceptez d’être lié par les modalités suivantes
        ("Conditions Générales de Vente", "Conditions Générales de Vente et d’Utilisation", "Conditions"), y compris les modalités, conditions et politiques
        additionnelles auxquelles il est fait référence ici et/ou accessibles par hyperlien. Ces Conditions Générales de Vente et d’Utilisation s’appliquent à
        tous les utilisateurs de ce site, incluant mais ne se limitant pas, aux utilisateurs qui naviguent sur le site, qui sont des vendeurs, des clients, des
        marchands, et/ou des contributeurs de contenu.
    <p>
        Veuillez lire attentivement ces Conditions Générales de Vente et d’Utilisation avant d’accéder à ou d’utiliser notre site web. En accédant à ou en
        utilisant une quelconque partie de ce site, vous acceptez d’être lié par ces Conditions Générales de Vente et d’Utilisation. Si vous n’acceptez pas toutes les
        modalités et toutes les Conditions de cet accord, alors vous ne devez pas accéder au site web ni utiliser les services qui y sont proposés. Si ces
        Conditions Générales de Vente et d’Utilisation sont considérées comme une offre, l’acceptation se limite expressément à ces Conditions Générales de
        Vente et d’Utilisation.
    <p>
        Toutes les nouvelles fonctionnalités et tous les nouveaux outils qui seront ajoutés ultérieurement à cette boutique seront également assujettis à ces
        Conditions Générales de Vente et d’Utilisation. Vous pouvez consulter la version la plus récente des Conditions Générales de Vente et d’Utilisation à
        tout moment sur cette page. Nous nous réservons le droit de mettre à jour, de changer ou de remplacer toute partie de ces Conditions Générales de Vente et
        d’Utilisation en publiant les mises à jour et/ou les modifications sur notre site web. Il vous incombe de consulter cette page régulièrement pour
        vérifier si des modifications ont été apportées. Votre utilisation continue du site web ou votre accès à celui-ci après la publication de toute modification
        constitue une acception de votre part de ces modifications.
    </p>
    <p>
        Notre boutique est hébergée sur Google Cloud.
    </p>
    <div class="heading">ARTICLE 1 – CONDITIONS D’UTILISATION DE NOTRE BOUTIQUE EN LIGNE</div>
    <p>
        En acceptant ces Conditions Générales de Vente et d’Utilisation, vous déclarez que vous avez atteint l’âge de la majorité dans votre pays, État ou
        province de résidence, et que vous nous avez donné votre consentement pour permettre à toute personne d’âge mineur à votre charge d’utiliser ce site web.
    </p>
    <p>
        L’utilisation de nos produits à toute fin illégale ou non autorisée est interdite, et vous ne devez pas non plus, dans le cadre de l’utilisation du
        Service, violer les lois de votre juridiction (incluant mais ne se limitant pas aux lois relatives aux droits d’auteur).
    </p>
    <p>
        Vous ne devez pas transmettre de vers, de virus ou tout autre code de nature destructive.
    </p>
    <p>
        Toute infraction ou violation des présentes Conditions Générales de Vente et d’Utilisation entraînera la résiliation immédiate de vos Services.
    </p>
    <div class="heading">ARTICLE 2 – CONDITIONS GÉNÉRALES</div>
    <p>
        Nous nous réservons le droit de refuser à tout moment l’accès aux services à toute personne, et ce, pour quelque raison que ce soit.
    </p>
    <p>
        Vous comprenez que votre contenu (à l’exclusion de vos informations de carte de crédit) pourrait être transféré de manière non chiffrée, et cela
        sous-entend (a) des transmissions sur divers réseaux; et (b) des changements pour se conformer et s’adapter aux exigences techniques pour la connexion des réseaux
        ou appareils. Les informations de carte de crédit sont toujours chiffrées pendant la transmission sur les réseaux.
    </p>
    <p>
        Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter une quelconque partie du Service ou utilisation du Service, ou un
        quelconque accès au Service ou contact sur le site web, par le biais duquel le Service est fourni, sans autorisation écrite expresse préalable de notre
        part.
    </p>
    <p>
        Les titres utilisés dans cet accord sont inclus pour votre commodité, et ne vont ni limiter ni affecter ces Conditions.
    </p>
    <div class="heading">ARTICLE 3 – EXACTITUDE, EXHAUSTIVITÉ ET ACTUALITÉ DES INFORMATIONS</div>
    <p>
        Nous ne sommes pas responsables si les informations disponibles sur ce site ne sont pas exactes, complètes ou à jour. Le contenu de ce site est fourni à
        titre indicatif uniquement et ne devrait pas constituer votre seule source d’information pour prendre des décisions, sans consulter au préalable des
        sources d’information plus exactes, plus complètes et actualisées. Si vous décidez de vous fier au contenu présenté sur ce site, vous le faites à votre propre
        risque.
    </p>
    <p>
        Ce site pourrait contenir certaines informations antérieures. Ces informations antérieures, par nature, ne sont pas à jour et sont fournies à titre
        indicatif seulement. Nous nous réservons le droit de modifier le contenu de ce site à tout moment, mais nous n’avons aucune obligation de mettre à jour
        les informations sur notre site. Vous acceptez qu’il vous incombe de surveiller les modifications apportées à notre site.
    </p>
    <div class="heading">ARTICLE 4 – MODIFICATIONS APPORTÉES AU SERVICE ET AUX PRIX</div>
    <p>
        Les prix de nos produits peuvent être modifiés sans préavis.
    </p>
    <p>
        Nous nous réservons le droit à tout moment de modifier ou d’interrompre le Service (ainsi que toute partie ou tout contenu du Service) sans préavis et
        en tout temps.
    </p>
    <p>
        Nous ne serons pas responsables envers vous ou toute autre tierce partie de toute modification de prix, suspension ou interruption du Service.
    </p>
    <div class="heading"> ARTICLE 5 – PRODUITS OU SERVICES (le cas échéant)</div>

    <p>
        Certains produits ou services peuvent être exclusivement disponibles en ligne sur notre site web. Ces produits ou services peuvent être disponibles en
        quantités limitées et peuvent uniquement faire l’objet de retours ou d’échanges conformément à notre Politique de Retour.
    </p>
    <p>
        Nous avons fait de notre mieux pour afficher aussi clairement que possible les couleurs et images de nos produits qui apparaissent sur notre boutique.
        Nous ne pouvons pas garantir que l’affichage des couleurs par l’écran de votre ordinateur sera précis.
    </p>
    <p>
        Nous nous réservons le droit, sans toutefois être obligés de le faire, de limiter les ventes de nos produits ou services à toute personne, et dans toute
        région géographique ou juridiction. Nous pourrions exercer ce droit au cas par cas. Nous nous réservons le droit de limiter les quantités de tout
        produit ou service que nous offrons. Toutes les descriptions de produits et tous les prix des produits peuvent être modifiés en tout temps sans avis préalable, à
        notre seule discrétion. Nous nous réservons le droit d’arrêter d’offrir un produit à tout moment. Toute offre de service ou de produit présentée sur ce site
        est nulle là où la loi l’interdit.
    </p>
    <p>
        Nous ne garantissons pas que la qualité de tous les produits, services, informations, ou toute autre marchandise que vous avez obtenue ou achetée
        répondra à vos attentes, ni que toute erreur dans le Service sera corrigée.
    </p>
    <div class="heading">ARTICLE 6 – EXACTITUDE DE LA FACTURATION ET DES INFORMATIONS DE COMPTE</div>
    <p>
        Nous nous réservons le droit de refuser toute commande que vous passez auprès de nous. Nous pourrions, à notre seule discrétion, réduire ou annuler les
        quantités achetées par personne, par foyer ou par commande. Ces restrictions pourraient inclure des commandes passées par ou depuis le même compte
        client, la même carte de crédit, et/ou des commandes qui utilisent la même adresse de facturation et/ou d’expédition. Dans le cas où nous modifierions une
        commande ou si nous venions à l’annuler, nous pourrions tenter de vous avertir en vous contactant à l’e-mail et/ou à l’adresse de facturation/au numéro de
        téléphone fourni au moment où la commande a été passée. Nous nous réservons le droit de limiter ou d’interdire les commandes qui, à notre seul jugement,
        pourraient sembler provenir de marchands, de revendeurs ou de distributeurs.
    </p>
    <p>
        Vous acceptez de fournir des informations de commande et de compte à jour, complètes et exactes pour toutes les commandes passées sur notre boutique.
        Vous vous engagez à mettre à jour rapidement votre compte et vos autres informations, y compris votre adresse e-mail, vos numéros de cartes de crédit et
        dates d’expiration, pour que nous poussions compléter vos transactions et vous contacter si nécessaire.
    </p>
    <p>
        Pour plus de détails, veuillez consulter notre Politique de Retour.
    </p>
    <div class="heading">ARTICLE 7 – OUTILS FACULTATIFS</div>
    <p>
        Nous pourrions vous fournir l’accès à des outils de tierces parties sur lesquels nous n’exerçons ni suivi, ni contrôle, ni influence.
    </p>
    <p>
        Vous reconnaissez et acceptez le fait que nous fournissons l’accès à de tels outils "tels quels" et "selon la disponibilité", sans aucune garantie,
        représentation ou condition d’aucune sorte et sans aucune approbation. Nous n’aurons aucune responsabilité légale résultant de ou liée à l’utilisation
        de ces outils facultatifs de tiers.
    </p>
    <p>
        Si vous utilisez les outils facultatifs offerts sur le site, vous le faites à votre propre risque et à votre propre discrétion, et vous devriez
        consulter les conditions auxquelles ces outils sont offerts par le ou les fournisseurs tiers concerné(s).
    </p>
    <p>
        Nous pourrions aussi, à l’avenir, offrir de nouveaux services et/ou de nouvelles fonctionnalités sur notre site (incluant de nouveaux outils et de
        nouvelles ressources). Ces nouvelles fonctionnalités et ces nouveaux services seront également assujettis à ces Conditions Générales de Vente et d’Utilisation.
    </p>
    <div class="heading">ARTICLE 8 – LIENS DE TIERS</div>

    <p>
        Certains contenus, produits et services disponibles par le biais de notre Service pourraient inclure des éléments provenant de tierces parties.
    </p>
    <p>
        Les liens provenant de tierces parties sur ce site pourraient vous rediriger vers des sites web de tiers qui ne sont pas affiliés à nous. Nous ne sommes
        pas tenus d’examiner ou d’évaluer le contenu ou l’exactitude de ces sites, et nous ne garantissons pas et n’assumons aucune responsabilité quant à tout
        contenu, site web, produit, service ou autre élément accessible sur ou depuis ces sites tiers.
    </p>
    <p>
        Nous ne sommes pas responsables des préjudices ou dommages liés à l’achat ou à l’utilisation de biens, de services, de ressources, de contenu, ou de
        toute autre transaction effectuée en rapport avec ces sites web de tiers. Veuillez lire attentivement les politiques et pratiques des tierces parties et
        assurez-vous de bien les comprendre avant de vous engager dans toute transaction. Les plaintes, réclamations, préoccupations, ou questions concernant
        les produits de ces tiers doivent être soumises à ces mêmes tiers.
    </p>
    <div class="heading">ARTICLE 9 – COMMENTAIRES, SUGGESTIONS ET AUTRES PROPOSITIONS D’UTILISATEURS</div>

    <p>
        Si, à notre demande, vous soumettez des contenus spécifiques (par exemple, pour participer à des concours), ou si sans demande de notre part, vous
        envoyez des idées créatives, des suggestions, des propositions, des plans ou d’autres éléments, que ce soit en ligne, par e-mail, par courrier, ou autrement
        (collectivement, "commentaires"), vous nous accordez le droit, en tout temps, et sans restriction, d’éditer, de copier, de publier, de distribuer, de
        traduire et d’utiliser autrement et dans tout média tout commentaire que vous nous envoyez. Nous ne sommes pas et ne devrons pas être tenus (1) de
        maintenir la confidentialité des commentaires; (2) de payer une compensation à quiconque pour tout commentaire fourni; (3) de répondre aux commentaires.
    </p>
    <p>
        Nous pourrions, mais n’avons aucune obligation de le faire, surveiller, modifier ou supprimer le contenu que nous estimons, à notre seule discrétion,
        être illégal, offensant, menaçant, injurieux, diffamatoire, pornographique, obscène ou autrement répréhensible, ou qui enfreint toute propriété
        intellectuelle ou ces Conditions Générales de Vente et d’Utilisation.
    </p>
    <p>
        Vous vous engagez à écrire des commentaires qui ne violent pas les droits de tierces parties, y compris les droits d’auteur, les marques déposées, la
        confidentialité, la personnalité, ou d’autres droits personnels ou de propriété. Vous convenez également que vos commentaires ne contiendront pas de
        contenu illégal, diffamatoire, offensif ou obscène, et qu’ils ne contiendront non plus pas de virus informatique ou d’autres logiciels malveillants qui
        pourraient affecter de quelque manière que ce soit le fonctionnement du Service ou tout autre site web associé. Vous ne pouvez pas utiliser de fausse adresse
        e-mail, prétendre être quelqu’un que vous n’êtes pas, ou essayer de nous induire nous et/ou les tierces parties en erreur quant à l’origine de vos commentaires.
        Vous êtes entièrement responsable de tous les commentaires que vous publiez ainsi que de leur exactitude. Nous n’assumons aucune responsabilité et
        déclinons tout engagement quant à tout commentaire que vous publiez ou que toute autre tierce partie publie.
    </p>
    <div class="heading">ARTICLE 10 – RENSEIGNEMENTS PERSONNELS</div>
    <p>
        La soumission de vos renseignements personnels sur notre boutique est régie par notre Politique de Confidentialité.
    </p>
    <div class="heading">ARTICLE 11 – ERREURS, INEXACTITUDES ET OMISSIONS</div>
    <p>
        Il se pourrait qu’il y ait parfois des informations sur notre site ou dans le Service qui pourraient contenir des erreurs typographiques, des
        inexactitudes ou des omissions qui pourraient être relatives aux descriptions de produits, aux prix, aux promotions, aux offres, aux frais d’expédition des produits,
        aux délais de livraison et à la disponibilité. Nous nous réservons le droit de corriger toute erreur, inexactitude, omission, et de changer ou de mettre à
        jour des informations ou d’annuler des commandes, si une quelconque information dans le Service ou sur tout autre site web associé est inexacte, et ce, en
        tout temps et sans préavis (y compris après que vous ayez passé votre commande).
    </p>
    <p>
        Nous ne sommes pas tenus de mettre à jour, de modifier ou de clarifier les informations dans le Service ou sur tout autre site web associé, incluant
        mais ne se limitant pas aux informations sur les prix, sauf si requis par la loi. Aucune date définie de mise à jour ou d’actualisation dans le Service ou sur
        tout autre site web associé ne devrait être prise en compte pour conclure que les informations dans le Service ou sur tout autre site web associé ont été
        modifiées ou mises à jour.
    </p>
    <div class="heading">ARTICLE 12 – UTILISATIONS INTERDITES</div>
    <p>
        En plus des interdictions énoncées dans les Conditions Générales de Vente et d’Utilisation, il vous est interdit d’utiliser le site ou son contenu: (a)
        à des fins illégales; (b) pour inciter des tiers à réaliser des actes illégaux ou à y prendre part; (c) pour enfreindre toute ordonnance régionale ou
        toute loi, règle ou régulation internationale, fédérale, provinciale ou étatique; (d) pour porter atteinte à ou violer nos droits de propriété intellectuelle ou
        ceux de tierces parties; (e) pour harceler, maltraiter, insulter, blesser, diffamer, calomnier, dénigrer, intimider ou discriminer quiconque en fonction
        du sexe, de l’orientation sexuelle, de la religion, de l’origine ethnique, de la race, de l’âge, de l’origine nationale, ou d’un handicap; (f) pour
        soumettre des renseignements faux ou trompeurs; (g) pour téléverser ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourrait être
        utilisé de manière à compromettre la fonctionnalité ou le fonctionnement du Service ou de tout autre site web associé, indépendant, ou d’Internet; (h) pour
        recueillir ou suivre les renseignements personnels d’autrui; (i) pour polluposter, hameçonner, détourner un domaine, extorquer des informations,
        parcourir, explorer ou balayer le web (ou toute autre ressource); (j) à des fins obscènes ou immorales; ou (k) pour porter atteinte ou contourner les mesures de
        sécurité de notre Service, de tout autre site web, ou d’Internet. Nous nous réservons le droit de résilier votre utilisation du Service ou de tout site
        web connexe pour avoir enfreint les utilisations interdites.
    </p>
    <div class="heading">ARTICLE 13 – EXCLUSION DE GARANTIES ET LIMITATION DE RESPONSABILITÉ</div>

    <p>
        Nous ne garantissons ni ne prétendons en aucun cas que votre utilisation de notre Service sera ininterrompue, rapide, sécurisée ou sans erreur.
    </p>
    <p>
        Nous ne garantissons pas que les résultats qui pourraient être obtenus par le biais de l’utilisation du Service seront exacts ou fiables.
    </p>
    <p>
        Vous acceptez que de temps à autre, nous puissions supprimer le Service pour des périodes de temps indéfinies ou annuler le Service à tout moment, sans
        vous avertir au préalable.
    </p>
    <p>
        Vous convenez expressément que votre utilisation du Service, ou votre incapacité à utiliser celui-ci, est à votre seul risque. Le Service ainsi que tous
        les produits et services qui vous sont fournis par le biais du Service sont (sauf mention expresse du contraire de notre part) fournis "tels quels" et
        "selon la disponibilité" pour votre utilisation, et ce sans représentation, sans garanties et sans conditions d'aucune sorte, expresses ou implicites, y compris
        toutes les garanties implicites de commercialisation ou de qualité marchande, d’adaptation à un usage particulier, de durabilité, de titre et d’absence
        de contrefaçon.
    </p>
    <p>
        Kid of the World, nos directeurs, responsables, employés, sociétés affiliées, agents, contractants, stagiaires, fournisseurs, prestataires de services
        et concédants ne peuvent en aucun cas être tenus responsables de toute blessure, perte, réclamation, ou de dommages directs, indirects, accessoires,
        punitifs, spéciaux, ou dommages consécutifs de quelque nature qu’ils soient, incluant mais ne se limitant pas à la perte de profits, de revenus, d’économies, de
        données, aux coûts de remplacement ou tous dommages similaires, qu’ils soient contractuels, délictuels (même en cas de négligence), de responsabilité
        stricte ou autre, résultant de votre utilisation de tout service ou produit provenant de ce Service, ou quant à toute autre réclamation liée de quelque
        manière que ce soit à votre utilisation du Service ou de tout produit, incluant mais ne se limitant à toute erreur ou omission dans tout contenu, ou à
        toute perte ou tout dommage de toute sorte découlant de l’utilisation du Service ou de tout contenu (ou produit) publié, transmis, ou autrement rendu
        disponible par le biais du Service, même si vous avez été avertis de la possibilité qu’ils surviennent. Parce que certains États ou certaines juridictions ne
        permettent pas d’exclure ou de limiter la responsabilité quant aux dommages consécutifs ou accessoires, notre responsabilité sera limitée dans la mesure
        maximale permise par la loi.
    </p>
    <div class="heading">ARTICLE 14 – INDEMNISATION</div>
    <p>
        Vous acceptez d’indemniser, de défendre et de protéger Kid of the World, notre société-mère, nos filiales, sociétés affiliées, partenaires,
        responsables, directeurs, agents, contractants, concédants, prestataires de services, sous-traitants, fournisseurs, stagiaires et employés, quant à toute réclamation
        ou demande, incluant les honoraires raisonnables d’avocat, faite par toute tierce partie à cause de ou découlant de votre violation de ces Conditions
        Générales de Vente et d’Utilisation ou des documents auxquels ils font référence, ou de votre violation de toute loi ou des droits d’un tiers.
    </p>
    <div class="heading"> ARTICLE 15 – DISSOCIABILITÉ</div>
    <p>
        Dans le cas où une disposition des présentes Conditions Générales de Vente et d’Utilisation serait jugée comme étant illégale, nulle ou inapplicable,
        cette disposition pourra néanmoins être appliquée dans la pleine mesure permise par la loi, et la partie non applicable devra être considérée comme étant
        dissociée de ces Conditions Générales de Vente et d’Utilisation, cette dissociation ne devra pas affecter la validité et l’applicabilité de toutes les
        autres dispositions restantes.
    </p>
    <div class="heading">ARTICLE 16 – RÉSILIATION</div>
    <p>
        Les obligations et responsabilités engagées par les parties avant la date de résiliation resteront en vigueur après la résiliation de cet accord et ce à
        toutes les fins.
    </p>
    <p>
        Ces Conditions Générales de Vente et d’Utilisation sont effectives à moins et jusqu’à ce qu’elles soient résiliées par ou bien vous ou non. Vous pouvez
        résilier ces Conditions Générales de Vente et d’Utilisation à tout moment en nous avisant que vous ne souhaitez plus utiliser nos Services, ou lorsque
        vous cessez d’utiliser notre site.
    </p>
    <p>
        Si nous jugeons, à notre seule discrétion, que vous échouez, ou si nous soupçonnons que vous avez été incapable de vous conformer aux modalités de ces
        Conditions Générales de Vente et d’Utilisation, nous pourrions aussi résilier cet accord à tout moment sans vous prévenir à l’avance et vous resterez
        responsable de toutes les sommes redevables jusqu’à la date de résiliation (celle-ci étant incluse), et/ou nous pourrions vous refuser l’accès à nos
        Services (ou à toute partie de ceux-ci).
    </p>
    <div class="heading">ARTICLE 17 – INTÉGRALITÉ DE L’ACCORD</div>
    <p>
        Tout manquement de notre part à l’exercice ou à l’application de tout droit ou de toute disposition des présentes Conditions Générales de Vente et
        d’Utilisation ne devrait pas constituer une renonciation à ce droit ou à cette disposition.
    </p>
    <p>
        Ces Conditions Générales de Vente et d’Utilisation ou toute autre politique ou règle d’exploitation que nous publions sur ce site ou relativement au
        Service constituent l’intégralité de l’entente et de l’accord entre vous et nous et régissent votre utilisation du Service, et remplacent toutes les
        communications, propositions et tous les accords, antérieurs et contemporains, oraux ou écrits, entre vous et nous (incluant, mais ne se limitant pas à toute version
        antérieure des Conditions Générales de Vente et d’Utilisation).
    </p>
    <p>
        Toute ambiguïté quant à l’interprétation de ces Conditions Générales de Vente et d’Utilisation ne doit pas être interprétée en défaveur de la partie
        rédactrice.
    </p>
    <div class="heading">ARTICLE 18 – LOI APPLICABLE</div>
    <p>
        Ces Conditions Générales de Vente et d’Utilisation, ainsi que tout autre accord séparé par le biais duquel nous vous fournissons des Services seront
        régis et interprétés en vertu des lois en vigueur à Chemin du Moulin-de-Vert, 10, Aire-la-Ville, GE, 1288, Switzerland.
    </p>
    <div class="heading">ARTICLE 19 – MODIFICATIONS APPORTÉES AUX CONDITIONS GÉNÉRALES DE VENTE ET D’UTILISATION</div>
    <p>
        Vous pouvez consulter la version la plus récente des Conditions Générales de Vente et d’Utilisation à tout moment sur cette page.
    </p>
    <p>
        Nous nous réservons le droit, à notre seule discrétion, de mettre à jour, de modifier ou de remplacer toute partie de ces Conditions Générales de Vente
        et d’Utilisation en publiant les mises à jour et les changements sur notre site. Il vous incombe de visiter notre site régulièrement pour vérifier si des
        changements ont été apportés. Votre utilisation continue de ou votre accès à notre site après la publication de toute modification apportée à ces
        Conditions
        Générales de Vente et d’Utilisation constitue une acceptation de ces modifications.
    </p>
    <div class="heading">ARTICLE 20 - COMMANDES</div>
    <p>
        Les commandes sont soumises uniquement à travers notre site internet.
    </p>
    <p>
        Il se peut que des erreurs de traductions se glissent dans les livres. Nous ne garantissons pas l'exactitude des traductions. Merci de nous contacter si
        vous constatez des erreurs de traductions afin que nous puissions les améliorer pour les prochains enfants.",
    </p>
    <div class="heading">ARTICLE 21 – COORDONNÉES</div>
    <p>
        Les questions concernant les Conditions Générales de Vente et d’Utilisation devraient nous être envoyées à hello@kidoftheworld.com.
    </p>
</div>
