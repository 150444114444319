import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionDataServiceDirective} from '../../services/session-data-service.directive';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {OrderService} from '../../services/order.service';
import {Subscription} from 'rxjs';
import {DialogService} from '../../services/dialog.service';
import {DialogResult} from '../../services/dialog.result';
import {MatSnackBar} from '@angular/material/snack-bar';
import {animate, style, transition, trigger} from '@angular/animations';
import * as cloneDeep from 'lodash/cloneDeep';
import {difference} from 'lodash';
import * as uuid from 'uuid';
import {Settings} from '../../models/settings.model';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GASettings} from '../../models/ga-settings.model';
import {filter, first, switchMap, tap} from 'rxjs/operators';
import {CreateService} from '../../services/create.service';
import {TranslationValidationService} from '../../services/translation-validation.service';
import {ItemOrder} from '../../models/item-order.model';
import {ItemsHelper} from '../../helpers/items.helper';
import {Images} from 'angular-responsive-carousel';
import {PixelService} from 'ngx-pixel';
import {CurrencyPipe} from '@angular/common';
import {CustomMessageHelper} from './custom-message-helper';
import {PaperCategory} from '../order/order.helper';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Theme} from '../../models/theme.model';

export interface PriceDef {
    soft: PriceValue,
    hard: PriceValue,
}

export interface PriceValue {
    value: string,
    price: string,
    cents: string,
    discount: string,
}

@Component({
    selector: 'app-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('.5s ease-out', style({opacity: '1'})),
            ]),
            transition(':leave', [
                style({opacity: '1'}),
                animate('.5s ease-out', style({opacity: '0'})),
            ])
        ]),
        trigger('appear', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateX(100%)'}))
            ])
        ]),
    ]
})
export class CreateComponent implements OnInit, OnDestroy {

    private languageSubscription: Subscription;
    private availableBooksSubscription: Subscription;
    private settingsSubscription: Subscription;
    private translationValidationSubscription: Subscription;
    private openBasketSubscription: Subscription;
    private nbItemsSubscription: Subscription;
    private languagesDialogSubscription: Subscription;
    private basketDialogSubscription: Subscription;
    private dialogShippingSubscription: Subscription;
    private colors = ['#ceeaf4', '#f4ddc9', '#f4e9ba', '#edf3cf', '#f4e1e3'];

    public showDedication: boolean;
    public prices: PriceDef;
    public imageBooks: Images;
    public lastImageBooks: Images;
    public processImages: Images;
    public availableLanguages: string[];
    public supportedLanguageList: Map<string, { englishName: string, nativeName: string }>;
    public maxLanguages = 4;
    public currentLanguage: string;
    public settings: Settings;
    public availableBooks: ItemOrder[] = [];
    public loadingAvailableBooks = false;
    public nbSavedItems = 0;
    public orderCards: any;
    public currentBook: ItemOrder;
    public dedicationError: boolean;
    public currentBookHasNewLang: boolean;
    public selectedCoverType = 'soft';
    public allThemes: Theme[];
    public isEditingBookFromOrder = false;
    public multiBookOfferActive = false;

    constructor(private sessionDataService: SessionDataServiceDirective,
                private translate: TranslateService,
                private router: Router,
                private currencyPipe: CurrencyPipe,
                private orderService: OrderService,
                private dialogService: DialogService,
                private snackBar: MatSnackBar,
                private translationValidationService: TranslationValidationService,
                private createService: CreateService,
                private gaService: GoogleAnalyticsService,
                private pixel: PixelService) {
    }

    private static buildNewBook(book: ItemOrder) {
        return {
            id: uuid.v4(),
            duplicates: book.duplicates,
            theme: book.theme,
            coverPaperCategory: book.coverPaperCategory,
            locales: book.locales,
            kidsName: book.kidsName,
            pages: [],
            illustrations: [],
            loading: true,
        } as ItemOrder;
    }

    private isBookInBasket(item: ItemOrder) {
        return this.sessionDataService.currentOder.content.items.find(i => i.id === item.id);
    }

    ngOnInit() {
        this.loadingAvailableBooks = true;
        this.currentLanguage = this.translate.currentLang;
        this.pixel.track('ViewContent');

        this.createPreviewBookImages();
        this.createProcessImages();
        this.createLastOrderCards();

        // Subscribe to nb saved items (nb shown on the checkout buttons)
        this.nbSavedItems = this.sessionDataService.currentOder.content.items?.length;
        this.nbItemsSubscription = this.sessionDataService.itemsChanged$.subscribe(() => {
            this.nbSavedItems = ItemsHelper.getNbDuplicates(this.sessionDataService.currentOder.content.items);
        });

        // Subscribe to open and close actions
        this.openBasketSubscription = this.sessionDataService.openBasket$.subscribe((id) => {
            this.showBasket();
        });

        if (this.sessionDataService.editingItem) {
            this.showDedication = false;
            // Load book (either edit existing book or create a new one)
            this.availableBooksSubscription = this.sessionDataService.availableBooks$
                .pipe(
                    filter((items: ItemOrder[]) => items?.length > 0),
                    tap((items) => {
                        this.loadingAvailableBooks = false;
                        this.availableBooks = items;
                        this.allThemes = this.availableBooks.map(b => b.theme);
                        this.editExitingBookFromOrder(this.sessionDataService.editingItem);
                    })
                )
                .subscribe();
        } else {
            // Load book (either edit existing book or create a new one)
            this.availableBooksSubscription = this.sessionDataService.availableBooks$
                .pipe(
                    filter((items: ItemOrder[]) => items.length > 0),
                    tap((items) => {
                        this.loadingAvailableBooks = false;
                        this.availableBooks = items;
                        this.allThemes = this.availableBooks.map(b => b.theme);
                        this.addNewBook();
                    }),
                )
                .subscribe();
        }

        this.languageSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLanguage = this.translate.currentLang;
        });

        this.settingsSubscription = this.sessionDataService.settingsSubject$
            .pipe(filter(settings => !!settings))
            .subscribe(settings => {
                this.settings = settings;
                this.availableLanguages = Array.from(settings.supportedLanguageList?.keys());
                this.supportedLanguageList = settings.supportedLanguageList;
                this.maxLanguages = settings.maxLanguages;
                this.multiBookOfferActive = settings.multiBookOfferActive;
                this.calculatePrices();
            });
    }

    private createPreviewBookImages() {
        const imageBooks = [];
        const lastImageBooks = [];

        for (let i = 1; i <= 4; i++) {
            imageBooks.push({
                path: '../../../assets/images/notjustamom/landscape/notjustamom-' + i + '.jpg',
                width: 500,
                height: 333
            });
        }
        for (let i = 1; i <= 6; i++) {
            imageBooks.push({
                path: '../../../assets/images/kotw-illustration-' + i + '.png',
                width: 500,
                height: 333
            });
        }
        for (let i = 1; i <= 6; i++) {
            lastImageBooks.push({
                path: '../../../assets/images/notjustamom/portrait/notjustamom-' + i + '.jpg',
                width: 300,
                height: 400
            });
        }

        this.imageBooks = imageBooks;
        this.lastImageBooks = lastImageBooks;
    }

    private createProcessImages() {
        const processImages = [];
        for (let i = 1; i <= 5; i++) {
            processImages.push({
                path: '../../../assets/images/process/en/' + i + '.png',
                width: 380,
                height: 300
            });
        }
        this.processImages = processImages;
    }

    private createLastOrderCards() {
        this.orderCards = this.sessionDataService.lastOrders.map((langArray) => {
            return {
                languages: langArray,
                color: this.colors[Math.floor(Math.random() * 5)] // 5 = colors.length
            };
        });
    }

    loadInitialBook() {
        if (this.sessionDataService.currentOder.content.items?.length) {
            this.addNewBook();
        } else {
            const initialTheme = this.sessionDataService.initialTheme ?? 'animals';
            const initialItem = this.availableBooks.find(book => book.theme.id === initialTheme);
            initialItem.locales = [this.sessionDataService.getCurrentLangForBooks()];
            initialItem.loading = true;
            this.currentBook = cloneDeep(initialItem);
        }
    }

    public addNewBook() {
        this.isEditingBookFromOrder = false;
        const currentThemes = this.sessionDataService.currentOder.content.items?.map(i => i.theme.id);
        const newThemeIds = difference(this.availableBooks.map(i => i.theme.id), currentThemes);
        const item = newThemeIds?.length ? this.availableBooks.find(i => i.theme.id === newThemeIds[0])
            : (this.sessionDataService.initialTheme ?
                this.availableBooks.find(book => book.theme.id === this.sessionDataService.initialTheme) : this.availableBooks[0]);

        const savedItems = this.sessionDataService.currentOder.content.items;
        item.locales = savedItems?.length ? savedItems[savedItems.length - 1].locales : ['en_GB'];
        item.kidsName = savedItems?.length ? savedItems[savedItems.length - 1].kidsName : null;
        item.coverPaperCategory = savedItems?.length ? savedItems[savedItems.length - 1].coverPaperCategory : PaperCategory.HARD_COVER;

        this.translationValidationSubscription?.unsubscribe();
        this.translationValidationSubscription = this.translationValidationService.translate(item)
            .pipe(first()).subscribe(illustrations => {
                const bookToAdd = CreateComponent.buildNewBook(cloneDeep(item));
                bookToAdd.illustrations = illustrations;
                bookToAdd.pages = SessionDataServiceDirective.buildPagesForBook(illustrations);
                bookToAdd.loading = false;
                this.currentBook = bookToAdd;
            });
    }

    private price(amount: number, multiply: number) {
        return this.currencyPipe.transform(amount * multiply / 100, this.settings.refCurrency, 'symbol', '1.2-2', 'en');
    }

    private calculatePrices() {
        const softCoverPrice = this.settings.unitPrice;
        const softCoverPriceDiscount = this.settings.unitPriceTwoBooks;
        const hardCoverPrice = this.settings.unitPrice + this.settings.hardCoverPrice;
        const hardCoverPriceDiscount = this.settings.unitPriceTwoBooks + this.settings.hardCoverPrice;
        this.prices = {
            soft: {
                value: this.price(softCoverPrice, 1),
                price: (softCoverPrice + '').substr(0, 2),
                cents: (softCoverPrice + '').substr(2, 3),
                discount: this.price(softCoverPriceDiscount, 1),
            },
            hard: {
                value: this.price(hardCoverPrice, 1),
                price: (hardCoverPrice + '').substr(0, 2),
                cents: (hardCoverPrice + '').substr(2, 3),
                discount: this.price(hardCoverPriceDiscount, 1),
            },
        } as PriceDef;
    }

    changeTheme(theme: Theme) {
        this.currentBook.theme = theme;
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'change_theme');
        this.pixel.track('CustomizeProduct');

        this.currentBook.loading = true;
        this.translationValidationSubscription?.unsubscribe();
        this.translationValidationSubscription = this.translationValidationService.translate(this.currentBook)
            .pipe(first()).subscribe(illustrations => {
                if (this.isBookInBasket(this.currentBook)) {
                    this.currentBook.pages = SessionDataServiceDirective.buildPagesForBook(illustrations);
                    this.currentBook.illustrations = illustrations;
                    const savedItem = this.sessionDataService.getSavedItems().find(i => i.id === this.currentBook.id);
                    savedItem.theme = this.currentBook.theme;
                } else {
                    this.currentBook.pages = SessionDataServiceDirective.buildPagesForBook(illustrations);
                    this.currentBook.illustrations = illustrations;
                }
                this.currentBook.loading = false;
            });
    }

    editLanguages() {
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'edit_languages');
        this.pixel.track('CustomizeProduct');

        this.languagesDialogSubscription?.unsubscribe();
        this.languagesDialogSubscription = this.dialogService
            .openEditLanguagesDialog(this.currentBook, this.availableLanguages, this.maxLanguages)
            .afterClosed()
            .pipe(
                first(),
                tap((languages) => {
                    this.currentBook.loading = true;
                    this.currentBook.pages = [];
                    this.currentBook.locales = languages;
                }),
                switchMap(() => {
                    return this.translationValidationService.translate(this.currentBook);
                }),
                tap((illustrations) => {
                    this.currentBook.pages = SessionDataServiceDirective.buildPagesForBook(illustrations);
                    this.currentBook.illustrations = illustrations;
                    this.currentBook.loading = false;
                }),
                switchMap(() => this.createService.getNewLanguages(this.currentBook)))
            .subscribe((reviewLanguage) => {
                // We only notify the user once...
                // if (reviewLanguage?.languages?.length
                //     && this.currentBook.locales?.length >= 3
                //     && !this.sessionDataService.superHeroNotified) {
                //     this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT,
                //         'superhero_notification');
                //     this.sessionDataService.superHeroNotified = true;
                //     this.dialogService.openSuperHeroNotification(this.currentBook, this.currentBook.locales).afterClosed()
                //         .pipe(first()).subscribe();
                // }
                this.currentBookHasNewLang = !!reviewLanguage?.languages?.length;
            });
    }

    removeLanguage(index: number) {
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'remove_language');
        this.currentBook.locales.splice(index, 1);
    }

    openProcessOverview() {
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'process_overview');
        this.dialogService.openProcessOverview();
    }

    private showBasket() {
        this.basketDialogSubscription?.unsubscribe();
        this.basketDialogSubscription = this.dialogService.openBasketDialog().afterClosed()
            .pipe(first())
            .subscribe(result => {
                if (!result.close) {
                    if (result.checkout) {
                        this.isEditingBookFromOrder = false;
                        this.order();
                    } else {
                        if (result.item) {
                            // We want to edit the item
                            this.editExitingBookFromOrder(result.item);
                        } else {
                            // We want to create another book
                            this.addNewBook();
                        }
                        this.calculatePrice();
                    }
                }
            });
    }

    private editExitingBookFromOrder(item: ItemOrder) {
        this.isEditingBookFromOrder = true;
        this.currentBook = item;
        this.currentBook.pages = SessionDataServiceDirective.buildPagesForBook(this.currentBook.illustrations);
        this.sessionDataService.editingItem = item;
    }

    addToCart() {
        this.pixel.track('AddToCart');
        this.gaService.event(GASettings.ACTIONS.ADD_TO_CARD, GASettings.CATEGORIES.ECOMMERCE, 'add_to_cart');

        this.currentBook.duplicates = 1;
        if (!this.sessionDataService.currentOder.content.items.find(i => i.id === this.currentBook.id)) {
            this.sessionDataService.currentOder.content.items.push(this.currentBook);
            this.sessionDataService.itemsChanged$.next();
        } else {
            this.sessionDataService.removeSavedItem(this.currentBook);
            this.sessionDataService.addSavedItem(this.currentBook);
            this.sessionDataService.itemsChanged$.next();
        }
        this.snackBar.open(this.translate.instant('create.book-added-to-cart'),
            this.translate.instant('common.ok'), {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
            });

        this.addNewBook();
        this.calculatePrice();
    }

    calculatePrice() {
        this.orderService.calculatePrice(this.sessionDataService.refCurrency)
            .pipe(
                first(),
            ).subscribe();
    }

    order() {
        if (!this.sessionDataService.currentOder.content.items?.length) {
            this.snackBar.open(this.translate.instant('create.order-empty'),
                this.translate.instant('common.close'), {
                    duration: 10000, horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
            return;
        }

        if (this.sessionDataService.getShippingCountry()) {
            this.saveBooksForOrder();
        } else {
            this.dialogShippingSubscription = this.dialogService.openShippingSelector().afterClosed().subscribe((result) => {
                if (result === DialogResult.YES) {
                    this.saveBooksForOrder();
                }
            });
        }
    }

    saveBooksForOrder() {
        this.router.navigate(['/order']);
    }

    ngOnDestroy(): void {
        this.languageSubscription?.unsubscribe();
        this.settingsSubscription?.unsubscribe();
        this.basketDialogSubscription?.unsubscribe();
        this.languagesDialogSubscription?.unsubscribe();
        this.dialogShippingSubscription?.unsubscribe();
        this.availableBooksSubscription?.unsubscribe();
        this.nbItemsSubscription?.unsubscribe();
        this.translationValidationSubscription?.unsubscribe();
        this.openBasketSubscription?.unsubscribe();
    }

    compareWithTheme(value1, value2) {
        return value1?.id === value2?.id;
    }

    enterDedication() {
        this.showDedication = true;
    }

    leaveDedication() {
        this.showDedication = false;
    }

    limitLines(event: Event) {
        const text = (event.target as HTMLTextAreaElement).value;
        const newText = CustomMessageHelper.cutText(text, 2);
        if (newText) {
            this.dedicationError = true;
            (event.target as HTMLTextAreaElement).value = newText;
            this.currentBook.kidsName = CustomMessageHelper.buildCustomMessage(newText);
        } else {
            this.dedicationError = false;
            this.currentBook.kidsName = text;
        }
    }

    watchVideo() {
        this.gaService.event(GASettings.ACTIONS.VIEW_ITEM, GASettings.CATEGORIES.ENGAGEMENT, 'watch_video');
    }

    setCoverType(type: string) {
        this.selectedCoverType = type;
        this.currentBook.coverPaperCategory = type === 'soft' ? PaperCategory.SOFT_COVER : PaperCategory.HARD_COVER;
        this.gaService.event(GASettings.ACTIONS.CUSTOMIZE_ITEM, GASettings.CATEGORIES.ENGAGEMENT,
            'set_' + this.currentBook.coverPaperCategory.valueOf().toLowerCase());

        if (this.isBookInBasket(this.currentBook)) {
            const savedItem = this.sessionDataService.getSavedItems().find(i => i.id === this.currentBook.id);
            savedItem.coverPaperCategory = this.currentBook.coverPaperCategory;
            this.calculatePrice();
            this.sessionDataService.itemsChanged$.next();
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.currentBook.locales, event.previousIndex, event.currentIndex);
    }
}
