import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BaseModule} from './base.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {OrderModule} from './components/order/order.module';
import {CreateModule} from './components/create/create.module';
import {HeaderModule} from './components/header/header.module';
import {FooterModule} from './components/footer/footer.module';
import {MainModule} from './components/main/main.module';
import {CurrencyPipe} from '@angular/common';
import {SessionDataServiceDirective} from './services/session-data-service.directive';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {CookieService} from 'ngx-cookie-service';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {environment} from '../environments/environment';
import {LegalModule} from './components/legal/legal.module';
import {AboutUsModule} from './components/about-us/about-us.module';
import {TranslateValidationModule} from './components/translate-validation/translate-validation.module';
import {NewsletterModule} from './components/newsletter/newsletter.module';
import {SharedModule} from './components/shared/shared.module';
import {PixelModule} from 'ngx-pixel';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.domain // it is mandatory to set a domain, for cookies to work properly
    },
    position: 'bottom',
    palette: {
        popup: {
            background: '#0188b7'
        },
        button: {
            background: '#f3cb14'
        }
    },
    theme: 'classic',
    type: 'opt-out'
};


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        BaseModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        MainModule,
        HeaderModule,
        OrderModule,
        CreateModule,
        FooterModule,
        LegalModule,
        AboutUsModule,
        NewsletterModule,
        SharedModule,
        TranslateValidationModule,
        NgxGoogleAnalyticsModule.forRoot('G-LZYX9Q1MWQ'),
        NgxGoogleAnalyticsRouterModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        PixelModule.forRoot({ enabled: true, pixelId: '537081297408704' })
    ],
    providers: [
        SessionDataServiceDirective,
        CurrencyPipe,
        CookieService,
        { provide: 'googleTagManagerId', useValue: 'GTM-TFRSXGF' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
