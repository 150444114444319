<div class="about-us">

    <div class="about-us-wrapper" [@appear]>

        <h2 class="title">{{'common.preface' | translate}}</h2>

        <div class="content">
            <div class="line" *ngFor="let numPreface of [1,2,3,4,5]">
                {{'about-us.content-' + numPreface | translate}}
            </div>
            <div class="actions">
                <button mat-button color="accent" (click)="goToCreate()" [attr.aria-label]="'common.create-book' | translate">
                    {{'common.create-book' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
