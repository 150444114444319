import {ItemCalculation, ItemCalculationAdapter} from './item-calculation.model';
import {Injectable} from '@angular/core';
import {TotalPrice, TotalPriceAdapter} from './total-price.model';

export class DetailedOrderCalculationModel {
    constructor(
        public totalOrderPrice: TotalPrice,
        public discountedItemsPrice: number,
        public totalItemsPrice: number,
        public itemsCalculationPriceOrderList: ItemCalculation[],
    ) {
    
    }
}

@Injectable({
    providedIn: 'root',
})
export class DetailedOrderCalculationModelAdapter {
    
    constructor(private itemCalculationAdapter: ItemCalculationAdapter, private totalPriceAdapter: TotalPriceAdapter) {
    }
    
    adapt(item: any): DetailedOrderCalculationModel {
        return new DetailedOrderCalculationModel(
            this.totalPriceAdapter.adapt(item.totalOrderPrice),
            item.discountedItemsPrice,
            item.totalItemsPrice,
            item.itemsCalculationPriceOrderList.map(i => this.itemCalculationAdapter.adapt(i)),
        );
    }
    
}

