import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ItemOrder} from '../../../models/item-order.model';
import {TranslateService} from '@ngx-translate/core';
import {SessionDataServiceDirective} from '../../../services/session-data-service.directive';

export class SuperheroDialogParams {
    item: ItemOrder;
    newLanguages: string[];
}

@Component({
    selector: 'app-superhero-notification',
    templateUrl: './superhero-notification.component.html',
    styleUrls: ['./superhero-notification.component.scss']
})
export class SuperheroNotificationComponent implements OnInit {

    languages: string;
    book: string;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public params: SuperheroDialogParams,
                public dialogRef: MatDialogRef<SuperheroDialogParams>,
                private translate: TranslateService,
                private sessionDataService: SessionDataServiceDirective) {
    }

    ngOnInit(): void {
        this.languages = this.formatLanguages(this.params.newLanguages);
        this.sessionDataService.confettiCreated$.next();
    }

    close() {
        this.dialogRef.close();
    }

    private formatLanguages(languages: string[]): string {
        const newLanguages = languages.map(lang => {
            return this.translate.instant('languages.'  + lang)
        });

        if (newLanguages.length > 1) {
            const last = newLanguages.pop();
            return newLanguages.map(lang => lang.startsWith('en') ? 'en' : lang)
                .join(', ') + ' ' + this.translate.instant('common.and') + ' ' + last;
        } else {
            return newLanguages[0];
        }
    }

}
