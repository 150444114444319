import {NgModule} from '@angular/core';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {BookSliderComponent} from './book-slider/book-slider.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {MainComponent} from './main.component';
import {QuoteComponent} from './quote/quote.component';
import {BaseModule} from '../../base.module';
import {RouterModule} from '@angular/router';
import {TestimonialsComponent} from './testimonials/testimonials.component';
import {NewsletterFormComponent} from './newsletter-form/newsletter-form.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    declarations: [
        ContactFormComponent,
        BookSliderComponent,
        WelcomeComponent,
        MainComponent,
        QuoteComponent,
        TestimonialsComponent,
        NewsletterFormComponent,
    ],
    imports: [
        BaseModule,
        SharedModule,
        RouterModule.forChild([]),
    ],
    exports: [
        MainComponent, NewsletterFormComponent
    ],
    providers: []
})


export class MainModule {
}
