import {Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ContactService} from '../../../services/contact.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {first, switchMap} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {

    private contactServiceSubscription: Subscription;

    @ViewChild('myForm') myForm: NgForm;

    public contactForm: FormGroup;
    public formSubmitted = false;
    public messageSent = false;

    constructor(private contactService: ContactService,
                @Optional() public dialogRef: MatDialogRef<ContactFormComponent>,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private recaptchaV3Service: ReCaptchaV3Service) {
        this.contactForm = new FormGroup({
            name: new FormControl('name', Validators.required),
            email: new FormControl('email',
                [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
            message: new FormControl('message', Validators.required)
        });

        this.contactForm.reset();
    }

    ngOnInit() {
    }

    onSubmit(event) {
        event.preventDefault();
        this.formSubmitted = true;
        this.messageSent = false;

        if (this.contactForm.valid && (this.contactForm.touched || this.contactForm.dirty)) {
            this.contactServiceSubscription?.unsubscribe();
            this.contactServiceSubscription = this.recaptchaV3Service.execute('support').pipe(
                first(),
                switchMap(token => this.contactService.sendMessage(this.contactForm.get('name').value,
                    this.contactForm.get('email').value,
                    this.contactForm.get('message').value,
                    token)
                )
            ).subscribe((result: any) => {
                this.formSubmitted = false;
                if (result.status === 'OK') {
                    this.initForm();
                    this.snackBar.open(this.translate.instant('main.contact.message-received'),
                        this.translate.instant('common.close'), {
                            duration: 10000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });
                    this.messageSent = true;
                } else {
                    this.snackBar.open(this.translate.instant('main.contact.unable-send-message'),
                        this.translate.instant('common.close'), {
                            duration: 20000, horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });
                    this.messageSent = false;
                }
            });
        } else {
            this.formSubmitted = false;
            this.messageSent = false;
            this.snackBar.open(this.translate.instant('main.contact.form-invalid'),
                this.translate.instant('common.close'), {duration: 10000});
        }
    }

    initForm() {
        this.contactForm.reset();
        this.myForm.resetForm();
    }

    ngOnDestroy() {
        this.contactServiceSubscription?.unsubscribe();
    }

    close(event) {
        event.preventDefault();
        this.dialogRef?.close();
    }
}
