import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgArrayPipesModule} from 'ngx-pipes';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {environment} from '../environments/environment';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    NgArrayPipesModule,
    ShareButtonsModule,
    ShareIconsModule,
    RecaptchaV3Module,
    IvyCarouselModule,
];

@NgModule({
    imports: modules,
    exports: modules,
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha }
    ]
})
export class BaseModule {
}
