<div class="main">
    <app-welcome></app-welcome>

    <div class="advantages-wrapper">
        <div class="advantages">
            <div class="advantage-list">
                <div class="advantage last-mobile">
                    <mat-icon [inline]="true">public</mat-icon>
                    <p [innerHtml]="'main.advantages.four-languages' | translate"></p>
                </div>
                <div class="advantage">
                    <mat-icon [inline]="true">auto_stories</mat-icon>
                    <p [innerHTML]="'main.advantages.all-languages' | translate"></p>
                </div>
                <div class="advantage">
                    <mat-icon [inline]="true">favorite</mat-icon>
                    <p [innerHtml]="'main.advantages.easy' | translate"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="gallery">
        <app-quote [text]="'main.quote.limit-languages' | translate"
                   [author]="'main.quote.limit-languages-author' | translate"></app-quote>
        <carousel [margin]="24" [borderRadius]="5" [height]="300" [cellWidth]="380">
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-illustration-1.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-illustration-1.png" type="image/png">
                    <img src="../../../../assets/images/kotw-illustration-1.png" alt="Children reading">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-book-1.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-book-1.png" type="image/png">
                    <img src="../../../../assets/images/kotw-book-1.png" alt="Picture book">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-illustration-2.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-illustration-2.png" type="image/png">
                    <img src="../../../../assets/images/kotw-illustration-2.png" alt="Children reading">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-book-2.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-book-2.png" type="image/png">
                    <img src="../../../../assets/images/kotw-book-2.png" alt="Picture book">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-illustration-3.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-illustration-3.png" type="image/png">
                    <img src="../../../../assets/images/kotw-illustration-3.png" alt="Children reading">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-book-3.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-book-3.png" type="image/png">
                    <img src="../../../../assets/images/kotw-book-3.png" alt="Picture book">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-illustration-4.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-illustration-4.png" type="image/png">
                    <img src="../../../../assets/images/kotw-illustration-4.png" alt="Children reading">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-book-4.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-book-4.png" type="image/png">
                    <img src="../../../../assets/images/kotw-book-4.png" alt="Picture book">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-illustration-5.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-illustration-5.png" type="image/png">
                    <img src="../../../../assets/images/kotw-illustration-5.png" alt="Children reading">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-book-5.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-book-5.png" type="image/png">
                    <img src="../../../../assets/images/kotw-book-5.png" alt="Picture book">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-illustration-6.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-illustration-6.png" type="image/png">
                    <img src="../../../../assets/images/kotw-illustration-6.png" alt="Children reading">
                </picture>
            </div>
            <div class="carousel-cell">
                <picture>
                    <source srcset="../../../../assets/images/kotw-book-6.webp" type="image/webp">
                    <source srcset="../../../../assets/images/kotw-book-6.png" type="image/png">
                    <img src="../../../../assets/images/kotw-book-6.png" alt="Picture book">
                </picture>
            </div>
        </carousel>
    </div>

    <div class="last-orders-wrapper">
        <div class="last-orders">
            <h2>{{'main.last-orders.title' | translate }}</h2>
            <carousel [margin]="24" [height]="210" [cellWidth]="148" [borderRadius]="5"
                      [cellsToScroll]="2" *ngIf="orderCards">
                <div class="carousel-cell last-order" *ngFor="let orderCard of orderCards"
                     [ngStyle]="{'background': orderCard.color}">
                    <div class="language" *ngFor="let lang of orderCard.languages">
                        {{'languages.' + lang | translate}}
                    </div>
                </div>
            </carousel>
        </div>
    </div>

    <div class="presentation-wrapper">
        <div class="presentation">
            <h2 class="blue">{{'main.presentation.benefits.title' | translate}}</h2>
            <h3>{{'main.presentation.benefits.subtitle' | translate}}</h3>
            <div class="benefits col-2">
                <div class="benefit">
                    <picture>
                        <source srcset="../../../../assets/images/bear-icon-family.webp" type="image/webp">
                        <source srcset="../../../../assets/images/bear-icon-family.png" type="image/png">
                        <img loading="lazy" src="../../../../assets/images/bear-icon-family.png" alt="Family benefits">
                    </picture>
                    <span>{{'main.presentation.benefits.benefit-1' | translate}}</span>
                </div>
                <div class="benefit">
                    <picture>
                        <source srcset="../../../../assets/images/bear-icon-heart.webp" type="image/webp">
                        <source srcset="../../../../assets/images/bear-icon-heart.png" type="image/png">
                        <img loading="lazy" src="../../../../assets/images/bear-icon-heart.png" alt="Self benefits">
                    </picture>
                    <span>{{'main.presentation.benefits.benefit-2' | translate}}</span>
                </div>
                <div class="benefit">
                    <picture>
                        <source srcset="../../../../assets/images/bear-icon-languages.webp" type="image/webp">
                        <source srcset="../../../../assets/images/bear-icon-languages.png" type="image/png">
                        <img loading="lazy" src="../../../../assets/images/bear-icon-languages.png" alt="Self benefits">
                    </picture>
                    <span>{{'main.presentation.benefits.benefit-3' | translate}}</span>
                </div>
                <div class="benefit">
                    <picture>
                        <source srcset="../../../../assets/images/bear-icon-graduation.webp" type="image/webp">
                        <source srcset="../../../../assets/images/bear-icon-graduation.png" type="image/png">
                        <img loading="lazy" src="../../../../assets/images/bear-icon-graduation.png" alt="Self benefits">
                    </picture>
                    <span>{{'main.presentation.benefits.benefit-4' | translate}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="second-call-to-action-wrapper">
        <div class="call-to-action-img cloud-call-1">
            <img src="../../../../assets/images/cloud4.png"/>
        </div>
        <div class="call-to-action-img cloud-call-2">
            <img src="../../../../assets/images/cloud6.png"/>
        </div>
        <div class="call-to-action-img cloud-call-3">
            <img src="../../../../assets/images/cloud5.png"/>
        </div>
        <div class="reminder">
            <div class="title">{{'create.reminder.title' | translate}}</div>
            <div class="subtitle">{{'create.reminder.subtitle' | translate}}</div>
            <div class="reminder-form">
                <app-reminder></app-reminder>
            </div>
        </div>
    </div>

    <div class="presentation-wrapper gray">
        <div class="presentation">
            <h2 class="pink">{{'main.presentation.who-is-it-for.title' | translate}}</h2>
            <h3>{{'main.presentation.who-is-it-for.subtitle' | translate}}</h3>
            <div class="benefits col-3">
                <div class="benefit">
                    <mat-icon [inline]="true">escalator_warning</mat-icon>
                    {{'main.presentation.who-is-it-for.b1' | translate}}
                </div>
                <div class="benefit">
                    <mat-icon [inline]="true">school</mat-icon>
                    {{'main.presentation.who-is-it-for.b2' | translate}}
                </div>
                <div class="benefit">
                    <mat-icon [inline]="true">card_giftcard</mat-icon>
                    {{'main.presentation.who-is-it-for.b3' | translate}}
                </div>
            </div>
            <div class="preface">
                <button mat-raised-button color="accent" [routerLink]="['/about-us']" [attr.aria-label]="'main.welcome.our-story' | translate">
                    {{'main.welcome.our-story' | translate}}
                </button>
            </div>
            <div class="testimonials">
                <app-testimonials></app-testimonials>
            </div>
        </div>
    </div>

    <div class="questions-wrapper">
        <div class="questions">
            <h2 class="blue">{{'main.questions.title' | translate}}</h2>
            <h3 [routerLink]="['faq']">{{'main.questions.more-questions' | translate}}</h3>

            <h4>{{'main.questions.question-0' | translate}}</h4>
            <p class="link" [routerLink]="['about-us']" [innerHtml]="'main.questions.response-0' | translate"></p>
            <h4>{{'main.questions.question-1' | translate}}</h4>
            <p [innerHtml]="'main.questions.response-1' | translate"></p>
            <h4>{{'main.questions.question-2' | translate}}</h4>
            <p [innerHtml]="'main.questions.response-2' | translate"></p>
            <h4>{{'main.questions.question-3' | translate}}</h4>
            <p>{{'main.questions.response-3' | translate}}</p>
            <div class="questions-actions" >
                <button mat-raised-button color="accent" [routerLink]="['/faq']" [attr.aria-label]="'main.questions.read-faq' | translate">
                    {{'main.questions.read-faq' | translate}}
                </button>
            </div>
        </div>
    </div>

    <app-newsletter-form
        [title]="'main.newsletter.title' | translate"
        [subtitle]="'main.newsletter.subtitle' | translate"
        [showCaptchaMessage]="true"
    ></app-newsletter-form>

    <div class="contact">
        <div class="cloud4">
            <img loading="lazy" id="cloud-1" src="../../../../assets/images/cloud4.png"/>
        </div>
        <div class="cloud6">
            <img loading="lazy" id="cloud-2" src="../../../../assets/images/cloud6.png"/>
        </div>
        <div class="cloud5">
            <img loading="lazy" id="cloud-4" src="../../../../assets/images/cloud5.png"/>
        </div>

        <div class="contact-form">
            <div class="form">
                <div class="title">{{'main.contact.title' | translate}}</div>
                <app-contact-form></app-contact-form>
            </div>
        </div>
        <div class="social">
            <div class="title">{{'main.contact.share' | translate}}</div>
            <div class="buttons">
                <p>Spread the worlds for Kid of the World</p>
                <share-buttons [theme]="'circles-dark'"
                               [include]="['facebook','messenger','whatsapp','pinterest', 'telegram', 'sms','email']"
                               [show]="4"
                               [url]="'https://www.kidoftheworld.com/'"
                               [title]="'commont.title' | translate"
                               [description]="'main.welcome.subtitle' | translate"
                               [autoSetMeta]="false"
                               (click)="trackShare()"
                ></share-buttons>
            </div>
        </div>
    </div>

</div>
