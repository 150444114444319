import {ItemOrder} from '../../models/item-order.model';
import {Order} from '../../models/order.model';
import {ContentOrder} from '../../models/content-order.model';
import {Contact} from '../../models/contact.model';
import {Shipping} from '../../models/shipping.model';
import {Payment} from '../../models/payment.model';
import {DetailedOrderCalculationModel} from '../../models/detailed-order-calculation.model';
import {Country} from '../../models/country.model';
import {SelectedReviewLanguage} from '../../models/review-language.model';

export enum ShippingMethod {
    UNTRACKED = 'UNTRACKED',
    TRACKED = 'TRACKED'
}

export enum PaperCategory {
    SOFT_COVER = 'SOFT_COVER',
    HARD_COVER = 'HARD_COVER'
}

export class OrderHelper {

    static buildOrder(rawValue: any, items: ItemOrder[], calculation: DetailedOrderCalculationModel,
                      currency: string, localDate: string, shippingCountry: Country, contactCountry: Country, currentLang: string,
                      isGift: boolean, isExpressDelivery: boolean, greetings: string): Order {

        return {
            content: OrderHelper.buildContent(items, calculation, currency, localDate, isGift, greetings),
            contact: OrderHelper.buildContact(rawValue, currentLang, shippingCountry, contactCountry),
            shipping: OrderHelper.buildShipping(rawValue, shippingCountry, calculation.totalOrderPrice.shippingMethod, isExpressDelivery),
            payment: OrderHelper.buildPayment(rawValue),
            token: '',
        };
    }

    private static buildPayment(rawValue: any): Payment {
        return {
            fullName: rawValue.paymentFullName
        };
    }

    public static buildShipping(rawValue: any, country: Country, shipping: ShippingMethod, isExpressDelivery: boolean): Shipping {
        return {
            shippingMethod: shipping,
            firstName: rawValue.shippingFirstName,
            lastName: rawValue.shippingLastName,
            companyName: rawValue.shippingCompanyName,
            isExpressDelivery,
            contactAddress: {
                address: rawValue.shippingAddress,
                city: rawValue.shippingCity,
                postalCode: rawValue.shippingPostalCode,
                region: rawValue.shippingRegion,
                country: {
                    name: country.name,
                    code: country.code
                } as Country,
                complimentary: rawValue.shippingComplimentaryAddress,
                phone: rawValue.shippingPhone
            }
        };
    }

    private static buildContact(rawValue: any, currentLang: string, shippingCountry: Country, contactCountry: Country): Contact {
        if (!rawValue.sameAsShipping) {
            return {
                language: currentLang,
                firstName: rawValue.contactFirstName,
                lastName: rawValue.contactLastName,
                companyName: rawValue.contactCompanyName,
                email: rawValue.contactEmail,
                contactAddress: {
                    address: rawValue.contactAddress,
                    city: rawValue.contactCity,
                    postalCode: rawValue.contactPostalCode,
                    region: rawValue.contactRegion,
                    country: {
                        name: contactCountry.name,
                        code: contactCountry.code
                    } as Country,
                    complimentary: rawValue.contactComplimentaryAddress,
                    phone: rawValue.contactPhone
                }
            };
        } else {
            return {
                language: currentLang,
                firstName: rawValue.shippingFirstName,
                lastName: rawValue.shippingLastName,
                companyName: rawValue.shippingCompanyName,
                email: rawValue.contactEmail,
                contactAddress: {
                    address: rawValue.shippingAddress,
                    city: rawValue.shippingCity,
                    postalCode: rawValue.shippingPostalCode,
                    region: rawValue.shippingRegion,
                    country: {
                        name: shippingCountry.name,
                        code: shippingCountry.code
                    } as Country,
                    complimentary: rawValue.shippingComplimentaryAddress,
                    phone: rawValue.shippingPhone
                }
            };
        }
    }

    private static buildContent(itemOrders: ItemOrder[], calculation: DetailedOrderCalculationModel,
                                curr: string, timestamp: string, isGift: boolean, greetings: string): ContentOrder {
        const totalPrice = calculation.totalOrderPrice;

        return {
            isGift,
            greetings: isGift ? greetings : '',
            items: itemOrders.map(item => {
                return {
                    id: item.id,
                    duplicates: item.duplicates,
                    theme: (item.theme as any).id,
                    locales: item.locales,
                    coverPaperCategory: item.coverPaperCategory,
                    kidsName: item.kidsName
                };
            }),
            discountedItemsPrice: calculation.discountedItemsPrice,
            totalItemsPrice: calculation.totalItemsPrice,
            totalOrderPrice: totalPrice.total,
            shippingPrice: totalPrice.shippingPrice,
            currency: curr,
            localDate: timestamp,
            coupon: calculation.totalOrderPrice.coupons[0]?.name
        } as ContentOrder;
    }

    public static areCountriesValid(sameAsShipping: boolean, shippingCountry: Country, contactCountry: Country): boolean {
        if (sameAsShipping) {
            return OrderHelper.isCountryValid(shippingCountry);
        } else {
            return OrderHelper.isCountryValid(shippingCountry) && OrderHelper.isCountryValid(contactCountry);
        }
    }

    public static isCountryValid(country: Country): boolean {
        return country !== null && country !== undefined && !!country.name && !!country.code;
    }
}
