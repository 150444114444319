<div class="ambassador-dialog">
    <div class="title">
        <mat-icon>language</mat-icon>
        <div>{{ 'order.order-details.ambassador.dialog.title' | translate: {value: ('languages.' + selectedReviewLanguage.language | translate)} }}</div>
    </div>
    <div class="subtitle" [innerHtml]="'order.order-details.ambassador.dialog.text-1' | translate"></div>

    <div class="translations">
        <div class="translation" *ngFor="let translation of selectedReviewLanguage.translations" [ngClass]="{'is-modified': translation.target !== originalTranslations.get(translation.source)}">
            <img class="left image" [src]="'data:image/png;base64,' + translation.image">
            <div class="right">
                <span class="source">{{translation.source}}</span>
                <mat-form-field class="target">
                    <input matInput maxlength="50" [(ngModel)]="translation.target"/>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="review-message">
        <mat-form-field>
            <textarea matInput
                      [(ngModel)]="selectedReviewLanguage.comment"
                      maxlength="500"
                      [placeholder]="'order.order-details.ambassador.dialog.comment' | translate"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="2"></textarea>
        </mat-form-field>
    </div>

    <div class="review-actions">
        <button mat-raised-button color="accent" (click)="applyPromotion()"
                [attr.aria-label]="'order.order-details.ambassador.dialog.i-confirm-one' | translate">
            <span *ngIf="getCorrections() === 1">{{'order.order-details.ambassador.dialog.i-confirm-one' | translate }}</span>
            <span *ngIf="getCorrections() > 1">{{'order.order-details.ambassador.dialog.i-confirm-many' | translate: {value: getCorrections()} }}</span>
            <span *ngIf="getCorrections() === 0">{{'order.order-details.ambassador.dialog.all-good' | translate}}</span>
        </button>
        <button mat-button (click)="close()"
                [attr.aria-label]="'common.skip' | translate">{{'common.skip' | translate}}</button>
    </div>
</div>
